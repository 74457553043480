import React, { useState, useEffect } from "react";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Link, useNavigate } from "react-router-dom";
import url from "../../../Development.json";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import OtpInput from "react-otp-input";

import {
  errorResponse,
  successResponse,
  isError,
} from "../../Helpers/response.js";
import Http from "../../security/Http";
import { ToastContainer } from "react-toastify";
import OneSignal from "react-onesignal";
import "../../../styles/authenticationStyle/otpverification.css";
const OtpVarification = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [deviceId, setDeviceId] = useState("");
  const [otpValue, setOtpValue] = useState("");
  const [otp, setOtp] = useState("");
  const [sendingCode, setSendingCode] = useState(false);

  const navigate = useNavigate();
  const { state } = useLocation();
  const { mobile } = state;

  const updateSendingCode = () => {
    setSendingCode(true);
  };

  useEffect(() => {
    setTimeout(() => {
      updateSendingCode();
    }, 500);
  }, []);


  useEffect(() => {
    isError(errors);

    if (sendingCode) {
      setSendingCode(false);
      onSubmit();
    }
  }, [sendingCode]);

  let digitValidate = function (ele) {
    setOtpValue(ele);
  };

  //Employee and Manager Login
  const onSubmit = () => {
    console.log("Mobile", mobile);
    var data = {};
    data.mobile = mobile;

    Http.callApi(url.otp_verification, JSON.stringify(data))
      .then((response) => {
        successResponse(response);
        setOtp(response.data.otp);
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  const checkOTP = () => {
    if (otpValue === otp) {
      Http.callApi(url.otp_verified, { mobile: mobile })
        .then((response) => {
          navigate("/login", {
            state: {
              message:
                "Your Account has been successfully created! You're now ready to use Mind Pulse!",
            },
          });
        })
        .catch((error) => {
          if (error.response) {
            errorResponse(error);
          }
        });
    } else {
      alert("The entered One-Time Passcode is incorrect. Please try again");
    }
  };


  return (
    <>
      <ToastContainer />
      <section className="login_wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <div className="login_form_container">
                <h4
                  style={{
                    fontSize: 24,
                    marginBottom: 0,
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                  className="app_heading"
                >
                  Verify your One-Time Passcode
                </h4>
                <div
                  className="login_form_wrapper"
                  style={{ marginBottom: 0, marginTop: 0 }}
                >
                  <p
                    className="app_text_sm mb-3"
                    style={{ textAlign: "center" }}
                  >
                    Enter the 6 digit verification code we've sent you by SMS
                  </p>
                  <form
                    className="otc mx-auto"
                    name="one-time-code"
                    autoComplete="off"
                    onSubmit={handleSubmit(checkOTP)}
                  >
                    <div className="otp_input_group">
                      <OtpInput
                        value={otpValue}
                        onChange={digitValidate}
                        numInputs={6}
                        className="otp-input"
                      />
                    </div>
                    <button className="app_primary_btn w-100 mt-4">
                      Verify One-Time Passcode
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="login_image_container"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OtpVarification;