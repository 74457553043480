import React from 'react'
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import { useNavigate } from "react-router-dom";
import dateFormat from 'dateformat';

const QuestionnaireDraftCard = (props) => {
    let queLastEdited = dateFormat(props.queEditedTime, "dS mmmm, yyyy");
    let queTitle = props.queTitle;
    const navigate = useNavigate();

    const viewButtonClick = (data) => {
        navigate('/manager/que-edit', { state: { data: JSON.stringify(data) } });
    };

    return (
        <div className='col'>
            <div className='manager_que_card'>
                <div className='que_card_left'>
                    <h4 className='que_card_title text-capitalize'>{queTitle}</h4>
                    <div className='d-flex que_card_left_detail_container'>
                        <div className='d-flex align-items-center'>
                            <WatchLaterOutlinedIcon style={{ color: "#9997A6", fontSize: 20, marginRight: '10px' }} />
                            <span className='me-1'>Last Edited:</span>
                            <span className='fw-bold'>{queLastEdited}</span>
                        </div>
                    </div>
                </div>
                <div className='que_draft_card_right'>
                    <button className='app_danger_btn me-3' data-bs-toggle="modal" onClick={()=>props.queItems(props)} data-bs-target="#queRemoveModal">Remove</button>
                    <button onClick={(e) => { viewButtonClick(props) }} className='app_secondary_btn'>Edit</button>
                </div>
            </div>
        </div>
    )
}

export default QuestionnaireDraftCard