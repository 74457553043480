import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import AppLoader from "../sub-components/AppLoader";
import HeaderEmployee from "./HeaderEmployee";
import HeaderManager from "./HeaderManager";

const LayoutEmployee = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const AuthRoute = () => {
    const isLoginEmp = localStorage.getItem("emp_accessToken") || false;
    const isLoginMan = localStorage.getItem("man_accessToken") || false;

    if (localStorage.getItem("usertype") === 3) {
      const render = isLoginMan ? navigate("/manager") : navigate("/login");
      return render;
    } else if (localStorage.getItem("usertype") <= 2) {
      const render = isLoginEmp ? navigate("/employee") : navigate("/login");
      return render;
    }
  };
  useEffect(() => {
    setTimeout(() => setIsLoading(false), 1500);
    AuthRoute();
  }, []);
  return (
    <>
      {localStorage.getItem("man_accessToken") ? <HeaderManager /> : ""}
      {localStorage.getItem("emp_accessToken") ? <HeaderEmployee /> : ""}
      <Outlet />
    </>
  );
};

export default LayoutEmployee;
