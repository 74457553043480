import React from "react";

const HeaderManagerQue = (props) => {
  return (
    <header className="header_manager_secondary">
      <div className="container">
        <h4 className="app_heading" style={{ marginBottom: 32 }}>
          Questionnaires
        </h4>
        <div>
          <ul className="header_manager_secondary_linkList">
            <li>
              <input
                type="radio"
                checked={props.activeModule === "active"}
                value="active"
                onChange={(ev) => props.moduleChangeFunc(ev)}
                name="managerQueModuleChangeOption"
                className="manager_que_header_radio_input"
                id="managerQueHeaderLinkInput1"
              />
              <label
                className="header_manager_secondary_link"
                htmlFor="managerQueHeaderLinkInput1"
              >
                Active
              </label>
            </li>
            <li>
              <input
                type="radio"
                checked={props.activeModule === "prev"}
                value="prev"
                onChange={(ev) => props.moduleChangeFunc(ev)}
                name="managerQueModuleChangeOption"
                className="manager_que_header_radio_input"
                id="managerQueHeaderLinkInput2"
              />
              <label
                className="header_manager_secondary_link"
                htmlFor="managerQueHeaderLinkInput2"
              >
                Previous
              </label>
            </li>
            <li>
              <input
                type="radio"
                checked={props.activeModule === "draft"}
                value="draft"
                onChange={(ev) => props.moduleChangeFunc(ev)}
                name="managerQueModuleChangeOption"
                className="manager_que_header_radio_input"
                id="managerQueHeaderLinkInput3"
              />
              <label
                className="header_manager_secondary_link"
                htmlFor="managerQueHeaderLinkInput3"
              >
                Saved as Drafts
              </label>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default HeaderManagerQue;
