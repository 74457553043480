import React, { useState, useEffect, useRef } from "react";
import userPlaceholder from "../../../assets/images/users/user-vector.png";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import MaleOutlinedIcon from "@mui/icons-material/MaleOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { UserOutlined } from "@ant-design/icons";

const EmployeeCard = (props) => {
  const [showEmpCardSubMenu, setShowEmpCardSubMenu] = useState(false);
  const managerEmpCardSubMenuRef = useRef(null);
  const managerEmpCardSubmenuBtn = useRef(null);

  const handleManagerQueCardSubMenuOnClick = () => {
    setShowEmpCardSubMenu(!showEmpCardSubMenu);
  };

  useEffect(() => {
    showEmpCardSubMenu
      ? managerEmpCardSubMenuRef.current?.classList.add("show")
      : managerEmpCardSubMenuRef.current?.classList.remove("show");
  }, [showEmpCardSubMenu]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        managerEmpCardSubMenuRef.current &&
        !managerEmpCardSubMenuRef.current.contains(event.target) &&
        !managerEmpCardSubmenuBtn.current.contains(event.target)
      ) {
        setShowEmpCardSubMenu(false);
      }
    }
    if (showEmpCardSubMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    }
  }, [managerEmpCardSubMenuRef, showEmpCardSubMenu, managerEmpCardSubmenuBtn]);

  return (
    <>
      <div className="col">
        <div className="app_card flex-row">
          <div className="employee_card_left">
            <div className="user_card_img_container">
              <img
                className="header_user_img"
                src={props.empImage ? `${props.empImage}` : userPlaceholder}
                alt="employee"
              />
            </div>
            <div className="emp_card_left_detail_container">
              <h4 className="app_text_bold mb-2 mt-3 mx-auto mx-md-0 mt-md-0">
                {props.empFirstname} {props.empLastname}
              </h4>
              <div className="d-flex mt-3 mt-md-0 emp_details_labels">
                <div className="me-4 d-flex align-items-center">
                  <LocalPhoneOutlinedIcon
                    style={{ color: "#9997A6", fontSize: 20 }}
                  />
                  <span className="app_text ms-1 d-none d-md-flex">Phone:</span>
                  <span className="app_text ms-1">{props.empMobile}</span>
                </div>
                <div className="me-4 d-flex align-items-center">
                  <EmailOutlinedIcon
                    style={{ color: "#9997A6", fontSize: 20 }}
                  />
                  <span className="app_text ms-1 d-none d-md-flex">Email:</span>
                  <span className="app_text ms-1">{props.empEmail}</span>
                </div>
                <div className="me-4 d-flex align-items-center">
                  <UserOutlined style={{ color: "#9997A6", fontSize: 20 }} />
                  <span className="app_text ms-1 d-none d-md-flex">
                    User Type:
                  </span>
                  <span className="app_text ms-1">
                    {props.item.usertype === 1
                      ? "User"
                      : props.item.usertype === 2
                      ? "Support Person"
                      : "Super Admin"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="position-relative ms-auto d-flex align-items-start align-items-md-center">
            <button
              ref={managerEmpCardSubmenuBtn}
              onClick={() => handleManagerQueCardSubMenuOnClick()}
              className="app_blank_btn"
            >
              <MoreVertOutlinedIcon style={{ fontSize: 32 }} />
            </button>
            <div
              ref={managerEmpCardSubMenuRef}
              className="manager_que_card_submenu_container"
            >
              <ul className="manager_que_card_submenu">
                <li>
                  <button
                    className="manager_que_card_submenu_btn"
                    data-bs-toggle="modal"
                    onClick={() => props.empItem(props)}
                    data-bs-target="#empRemoveModal"
                  >
                    <DeleteOutlinedIcon style={{ marginRight: 15 }} />
                    Remove Employee
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeCard;
