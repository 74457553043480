import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import exitIcon from "../../assets/icons/logoutIcon.svg";
import userIcon from "../../assets/icons/userIcon.svg";
import passwordIcon from "../../assets/images/key.svg";
import peopleIcon from "../../assets/images/people.svg";
import Http from "../security/Http";
import url from "../../Development.json";
import { errorResponse } from "../Helpers/response";
import userPlaceholder from "../../assets/images/users/user-vector.png";
import notificationIcon from "../../assets/images/ic_notification.png";
import appLogo from "../../assets/icons/appicon.png";
import OneSignal from "react-onesignal";
import Notifications from "react-notifications-menu";
import NotificationItem from "../NotificationItem";

const HeaderEmployee = () => {
  const navigate = useNavigate();
  const [toggleDisplayEmployeeAccMenu, setToggleDisplayEmployeeAccMenu] =
    useState(false);
  const employeeAccMenuRef = useRef(null);
  const employeeAccMenuBtn = useRef(null);

  const handleEmployeeAccountMenuOnClick = () => {
    const ddArrow = document.getElementsByClassName("header_dropdownArrow")[0];
    if (employeeAccMenuRef.current.classList.contains("show")) {
      employeeAccMenuRef.current.classList.remove("show");
      ddArrow.classList.remove("open");
    } else {
      employeeAccMenuRef.current.classList.add("show");
      ddArrow.classList.add("open");
    }
    setToggleDisplayEmployeeAccMenu(!toggleDisplayEmployeeAccMenu);
  };

  //Employee logout
  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    const ddArrow = document.getElementsByClassName("header_dropdownArrow")[0];
    if (toggleDisplayEmployeeAccMenu) {
      document.addEventListener("mousedown", (event) => {
        if (
          employeeAccMenuRef.current &&
          !employeeAccMenuRef.current.contains(event.target) &&
          !employeeAccMenuBtn.current.contains(event.target)
        ) {
          employeeAccMenuRef.current.classList.remove("show");
          ddArrow.classList.remove("open");
          setToggleDisplayEmployeeAccMenu(false);
        }
      });
    }
  }, [toggleDisplayEmployeeAccMenu]);

  const [empProfileData, setEmpProfileData] = useState([]);

  const profileData = () => {
    Http.callApi(url.user_profile)
      .then((response) => {
        setEmpProfileData(response.data);
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };




  useEffect(() => {
    profileData();
  }, []);

  return (
    <header className="app_header_wrapper employee_header_wrapper">
      <div className="app_header_logo_wrapper">
        <Link className="app_header_logo_container_link" to="/employee">
          <img src={appLogo} height="80" alt="logo" />
        </Link>
      </div>
      <div className="app_header_menu_wrapper">
        <ul className="app_header_menu">
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "app_header_menu_linkActive" : "app_header_menu_link"
              }
              to="/daily-log"
            >
              <span className="header_menu_link_text">Daily Mood Capture</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "app_header_menu_linkActive" : "app_header_menu_link"
              }
              to="/wellness"
            >
              <span className="header_menu_link_text">Wellness</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "app_header_menu_linkActive" : "app_header_menu_link"
              }
              to="/calendar/"
            >
              <span className="header_menu_link_text">
                Mood Capture Calendar
              </span>
            </NavLink>
          </li>

          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "app_header_menu_linkActive" : "app_header_menu_link"
              }
              to="/calendar/people"
            >
              <span className="header_menu_link_text">People Calendar</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/employee/questionnaires"
              className={({ isActive }) =>
                isActive ? "app_header_menu_linkActive" : "app_header_menu_link"
              }>
              <span className="header_menu_link_text ms-2">Questionnaires</span>
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="app_header_user_wrapper position-relative" style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        <div className="d-flex flex-row" style={{ justifyContent: 'center', alignItems: 'center' }}>
          <Link to={"/employee/notifications"} style={{ justifyContent: 'center', alignItems: 'center' }}>
            <img src={notificationIcon} style={{ width: 24, height: 24, marginRight: 12, }} />
          </Link>
        <button
          ref={employeeAccMenuBtn}
          onClick={() => handleEmployeeAccountMenuOnClick()}
          className="app_header_user_btn"
        >
          <div className="header_user_img_container">
            <img
              src={
                empProfileData.profile_image
                  ? empProfileData.profile_image
                  : userPlaceholder
              }
              className="header_user_img"
              alt="user"
            />
          </div>
          <span className="app_header_user_name_text">
            {empProfileData.firstname} {empProfileData.lastname}
          </span>
          <KeyboardArrowDownOutlinedIcon className="header_dropdownArrow" />
        </button>
        <div className="header_userMenuContainer" ref={employeeAccMenuRef}>
          <ul className="header_userMenu">
            <li>
              <Link
                onClick={() => handleEmployeeAccountMenuOnClick()}
                to="/profile"
                className="userMenuLink"
              >
                <img src={userIcon} style={{height: '24px', width: '24px', marginRight: 24, objectFit: 'cover'}} className="userMenuIcon" alt="" />
                Profile
              </Link>
            </li>
            <li>
              <Link
                to="/employee/support/people"
                className="userMenuLink app_blank_btn"
              >
                <img src={peopleIcon} style={{height: '32px', width: '32px', scale: '1.3', objectFit: 'cover'}} className="userMenuIcon" alt="" />
                Configure your Support People
              </Link>
            </li>
            <li>
              <Link
                to="/employee/change-password"
                className="userMenuLink app_blank_btn"
              >
                <img src={passwordIcon} style={{height: '32px', width: '32px'}} className="userMenuIcon" alt="" />
                Change Password
              </Link>
            </li>
            <li>
              <Link
                to="/login"
                className="userMenu_logoutBtn"
                onClick={() => logout()}
              >
                <img src={exitIcon} style={{height: '24px', width: '24px', marginRight: 24, objectFit: 'cover'}} className="userMenuIcon" alt="" />
                Logout
              </Link>
            </li>
          </ul>
        </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderEmployee;
