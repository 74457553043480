import React, { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const SingleChoiceQuestionModule = (props) => {
  const [value, setValue] = useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
    props.onChangeFunc(event.target.value);
  };

  return (
    <>
      <h4 className="app_text_secondary_gray text-uppercase">Question</h4>
      <h4 className="app_heading_20_bold mt-3 mb-4">{props.question.title}</h4>
      <div className="answer_wrapper">
        <h4 className="app_text_primary mb-4 text-uppercase">Select Answer</h4>
        <RadioGroup
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
          <div className="row row-cols-1 g-3">
            {props.question.options.map((option) => (
              <div className="col">
                <div className="choice_answer_container">
                  <FormControlLabel
                    value={option}
                    control={<Radio />}
                    label={option}
                  />
                </div>
              </div>
            ))}
          </div>
        </RadioGroup>
      </div>
    </>
  );
};

export default SingleChoiceQuestionModule;
