import React, { useEffect, useState } from "react";
import { Result } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { errorResponse } from "../../Helpers/response.js";
import PinInput from "react-pin-input";
import { useLocation, useNavigate } from "react-router";

const LinkSentConfirmation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [enteredOtp, setEnteredOtp] = useState("");

  const verifyOneTimePasscode = () => {
    // Check if the entered OTP is correct
    if (enteredOtp === location.state.otp) {
      // Redirect to the set password page
      navigate("/password/reset", {
        state: { otp: location.state.otp, mobile: location.state.mobile },
      });
    } else {
      // Display an error message
      alert(
        "Your One-Time Passcode didn't match the code you were sent. Please try again."
      );
    }
  };

  return (
    <section className="auth_wrapper">
      <div className="container-fluid p-0">
        <div className="row recovery_box_row">
          <div className="col-lg-6 d-flex">
            <div className="account_recovery_box">
              <h4 className="app_heading">Security Code</h4>
              <p className="app_text mt-2 mb-4">
                Please enter the one-time passcode that was sent to you by SMS
                below
              </p>
              <PinInput
                length={6}
                initialValue=""
                secret
                secretDelay={100}
                onChange={(value, index) => {
                  setEnteredOtp(value);
                }}
                type="numeric"
                inputMode="number"
                style={{ padding: "16px" }}
                inputStyle={{
                  borderColor: "transparent",
                  borderBottomColor: "#e3e3e3",
                  borderBottomWidth: 1,
                }}
                inputFocusStyle={{
                  borderColor: "transparent",
                  borderBottomColor: "#e3e3e3",
                  borderBottomWidth: 1,
                }}
                onComplete={(value, index) => { }}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              />
              <button
                onClick={() => verifyOneTimePasscode()}
                className="app_primary_btn w-100 mt-4"
              >
                Verify One-Time Passcode
              </button>
            </div>
          </div>
          <div className="col-lg-6 d-none d-lg-block">
            <div className="login_image_container"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LinkSentConfirmation;