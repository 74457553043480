import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import MoodSelector from "../../../sub-components/MoodSelector";
import CreateQueBoilerPlateCard from "../../../sub-components/managerQueSub/CreateQueBoilerPlateCard";
import DisplayQuestionCard from "../../../sub-components/managerQueSub/DisplayQuestionCard";
import CreateQueSubmitConfirmModal from "./CreateQueSubmitConfirmModal";
import url from "../../../../Development.json";
import { errorResponse, successResponse } from "../../../Helpers/response";
import Http from "../../../security/Http";
import { ToastContainer } from "react-toastify";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { DatePicker } from "antd";

const ManagerCreateQue = () => {
  const navigate = useNavigate();
  let answerUid = () => `answer${Math.random().toString(36).substring(2, 9)}`; //unique id for each answer in form
  let queBoilerPlateUid = () =>
    `queBoilerPlate${Math.random().toString(36).substring(2, 9)}`; //unique id for each question set in form

  //Final form submit data state without form title and default question
  const [formQueset, setFormQueset] = useState([]);
  const [getMngId, setMngIdData] = useState([]);

  //boiler plate state for new question
  const [getQuestionData, setQuestion] = useState([]);
  const [getTitle, setTitle] = useState("");
  const [setdays, seDays] = useState("");
  const [toggleD, setToggleD] = useState(false); //Toggle display listing sorting dropdown
  const dayTypeDDRef = useRef(null);
  const dayTypeDDBtnRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [action, setAction] = useState("");

  const [queBoilerPlate, setQueBoilerPlate] = useState([
    {
      queBoilerPlateId: queBoilerPlateUid(),
      queBoilerPlateSelectedQueType: "single",
      queBoilerPlateQueText: "",
      queBoilerPlateAnswersSet: [
        {
          answerId: answerUid(),
          answerText: "",
        },
      ],
    },
  ]);

  function handleDateChange(date) {
    setSelectedDate(date);
  }

  useEffect(() => {
    if (toggleD) {
      document.addEventListener("mousedown", (event) => {
        if (
          dayTypeDDRef.current &&
          !dayTypeDDRef.current.contains(event.target) &&
          !dayTypeDDBtnRef.current.contains(event.target)
        ) {
          dayTypeDDRef.current.classList.remove("show");
          setToggleD(false);
        }
      });
    }
  }, [toggleD]);

  //handle module on discard click
  const handleDiscardBtnClick = () => {
    navigate("/manager");
  };

  //Add question to form
  const queBoilerPlateAddBtnClick = () => {
    if (queBoilerPlate.length === 0) {
      setQueBoilerPlate([
        {
          queBoilerPlateId: queBoilerPlateUid(),
          queBoilerPlateSelectedQueType: "single",
          queBoilerPlateQueText: "",
          queBoilerPlateAnswersSet: [
            {
              answerId: answerUid(),
              answerText: "",
            },
          ],
        },
      ]);
    } else if (
      queBoilerPlate.filter((item) => item.queBoilerPlateQueText !== "")
        .length > 0
    ) {
      if (
        queBoilerPlate.filter(
          (item) =>
            item.queBoilerPlateAnswersSet.filter(
              (answer) => answer.answerText !== ""
            ).length > 1
        ).length > 0
      ) {
        setFormQueset((prev) => {
          return [...prev, ...queBoilerPlate];
        });
        setQueBoilerPlate([
          {
            queBoilerPlateId: queBoilerPlateUid(),
            queBoilerPlateSelectedQueType: "single",

            queBoilerPlateQueText: "",
            queBoilerPlateAnswersSet: [
              {
                answerId: answerUid(),
                answerText: "",
              },
            ],
          },
        ]);
      } else if (
        queBoilerPlate.filter(
          (item) => item.queBoilerPlateSelectedQueType === "text"
        ).length > 0
      ) {
        setFormQueset((prev) => {
          return [...prev, ...queBoilerPlate];
        });
        setQueBoilerPlate([
          {
            queBoilerPlateId: queBoilerPlateUid(),
            queBoilerPlateSelectedQueType: "single",

            queBoilerPlateQueText: "",
            queBoilerPlateAnswersSet: [
              {
                answerId: answerUid(),
                answerText: "",
              },
            ],
          },
        ]);
        // setQuestion([
        //     // queBoilerPlate.queBoilerPlateAnswersSet.map(item => {
        //     //     return [
        //     //             item.answerText,
        //     //         ]
        //     // }
        //     {
        //         "title": queBoilerPlate.queBoilerPlateQueText,
        //         "type": queBoilerPlate.queBoilerPlateSelectedQueType,
        //     }
        // ]);
      } else {
        console.log(
          "Please add at least 2 answer options if using a Selectable Question"
        );
        errorResponse(
          "Please add at least 2 answer options if using a Selectable Question"
        );
      }
    } else {
      console.log("Add at least 1 question");
      errorResponse(
        "If you've added a question, please click the Add Question Button before Submitting"
      );
    }
  };

  //Remove boilerplate from form
  const queBoilerPlateRemoveBtnClick = (id) => {
    setQueBoilerPlate((prev) =>
      prev.filter((item) => item.queBoilerPlateId !== id)
    );
  };

  //handle boiler plate question type
  const handleQueTypeOnChange = (type, queBoilerPlateId) => {
    setQueBoilerPlate((prev) =>
      prev.map((item) => {
        if (item.queBoilerPlateId === queBoilerPlateId) {
          item.queBoilerPlateSelectedQueType = type;
          return item;
        } else {
          return item;
        }
      })
    );
  };

  const handleDayOnChange = (day, type) => {
    seDays(type);
    const queTypeDDIcon =
      document.getElementsByClassName("app_ddd_arrowIcon")[0];
    dayTypeDDRef.current.classList.remove("show");
    queTypeDDIcon.classList.remove("open");
  };

  //handle boiler plate question
  const boilerPlateQueOnChange = (ev, queBoilerPlateId) => {
    setQueBoilerPlate((prev) =>
      prev.map((item) => {
        if (item.queBoilerPlateId === queBoilerPlateId) {
          item.queBoilerPlateQueText = ev.target.value;
          return item;
        } else {
          return item;
        }
      })
    );
  };

  // handle boiler plate answers text
  const handleAnswersOnChange = (ev, id) => {
    setQueBoilerPlate((prev) =>
      prev.map((item) => {
        item.queBoilerPlateAnswersSet.map((item) => {
          if (item.answerId === id) {
            item.answerText = ev.target.value;
            return item;
          } else {
            return item;
          }
        });
        return item;
      })
    );
  };

  //add more answer to boilerplate
  const handleAddAnswerOnClick = (boilerPlateId) => {
    setQueBoilerPlate((prev) =>
      prev.map((item) => {
        if (item.queBoilerPlateId === boilerPlateId) {
          item.queBoilerPlateAnswersSet = [
            ...item.queBoilerPlateAnswersSet,
            {
              answerId: answerUid(),
              answerText: "",
            },
          ];
          return item;
        } else {
          return item;
        }
      })
    );
  };

  //remove answer from boilerplate
  const handleAnswerOnRemove = (boilerPlateId, ansId) => {
    setQueBoilerPlate((prev) =>
      prev.map((item) => {
        if (item.queBoilerPlateId === boilerPlateId) {
          item.queBoilerPlateAnswersSet = item.queBoilerPlateAnswersSet.filter(
            (answer) => answer.answerId !== ansId
          );
          return item;
        } else {
          return item;
        }
      })
    );
  };

  const profileData = () => {
    Http.callApi(url.user_profile)
      .then((response) => {
        setMngIdData(response.data);
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };
  useEffect(() => {
    profileData();
  }, []);

  const handleDisplayQueOnRemove = (queId) => {
    // setFormQueset((prev) => {
    //   return prev.filter((item) => item.queBoilerPlateId !== queId);
    // });
  };

  //mood question
  const question = [];

  const setQuestionsData = (formQueset) => {
    var questionArr = [];
    var ansArr = [];

    formQueset.forEach((value) => {
      ansArr = value.queBoilerPlateAnswersSet.map((item) => item.answerText);

      const newQuestion = {
        title: value.queBoilerPlateQueText,
        type:
          value.queBoilerPlateSelectedQueType === "single"
            ? 1
            : value.queBoilerPlateSelectedQueType === "multi"
            ? 2
            : value.queBoilerPlateSelectedQueType === "text"
            ? 3
            : value.queBoilerPlateSelectedQueType,
        options: value.queBoilerPlateSelectedQueType === "text" ? [] : ansArr,
      };

      // Check if the new question has a non-empty title
      if (newQuestion.title !== "") {
        questionArr.push(newQuestion);
      }

      ansArr = [];
    });

    // Log the question array before setting it
    console.log("QUESTIONS ARRAY: ", questionArr);

    // Set the question array
    updateQuestions(questionArr);
  };

  const updateQuestions = (newQuestions) => {
    setQuestion([...newQuestions]);
  };

  useEffect(() => {
    setQuestionsData(formQueset);
  }, [formQueset]);

  const submitDraftInfo = () => {
    queBoilerPlateAddBtnClick();
    setAction("draft");
    setIsSubmitting(true);
    setQuestion(getQuestionData);
  };

  const submitQuestionnaire = () => {
    queBoilerPlateAddBtnClick();
    setAction("submit");
    setIsSubmitting(true);
    setQuestion(getQuestionData);
  };

  useEffect(() => {
    console.log("USEEFFECT QUESTION SET: ", getQuestionData);

    if (isSubmitting && getQuestionData.length > 0) {
      if (action === "draft") {
        console.log("Draft Question");
        onDrafsubmit(getQuestionData);
      } else {
        console.log("Submit Question");
        onSubmit(getQuestionData);
      }
    }
  }, [getQuestionData]);

  //draf Question Create
  const onDrafsubmit = (questions) => {
    console.log("QUESTION SET: ", questions);
    if (getTitle == "") {
      errorResponse("Title is required.");
    } else {
      console.log(selectedDate);
      let arr = [];
      arr["device"] = 3;
      arr["title"] = getTitle;
      arr["pushToken"] = "test";
      arr["is_draft"] = 1;
      arr["end_time"] = selectedDate;
      arr["question"] = getQuestionData;
      questions["manager_id"] = getMngId._id;

      console.log(arr);
      const obj = Object.assign({}, arr);
      Http.callApi(url.create_question, JSON.stringify(obj))
        .then((response) => {
          console.log(response);
          setTimeout(() => {
            navigate("/manager/questionnaires");
          }, 500);
        })
        .catch((error) => {
          console.log(error);
          errorResponse(
            "We couldn't create your Questionnaire at this time. Please try again shortly"
          );
          if (error.response) {
            console.log(error);
            errorResponse(error);
          }
        });
    }
  };

  const onSubmit = (questions) => {
    console.log("QUESTION SET: ", questions);
    if (getTitle == "") {
      errorResponse("Title is required.");
    } else {
      console.log(selectedDate);
      let arr = [];
      arr["device"] = 3;
      arr["title"] = getTitle;
      arr["pushToken"] = "test";
      arr["is_draft"] = 0;
      arr["end_time"] = selectedDate;
      arr["question"] = getQuestionData;
      questions["manager_id"] = getMngId._id;

      console.log(arr);
      const obj = Object.assign({}, arr);
      Http.callApi(url.create_question, JSON.stringify(obj))
        .then((response) => {
          console.log(response);
          setTimeout(() => {
            navigate("/manager/questionnaires");
          }, 500);
        })
        .catch((error) => {
          console.log(error);
          errorResponse(
            "We couldn't create your Questionnaire at this time. Please try again shortly"
          );
          if (error.response) {
            console.log(error);
            errorResponse(error);
          }
        });
    }
  };

  return (
    <>
      <ToastContainer />
      <header
        className="header_manager_secondary"
        style={{ paddingBottom: 32 }}
      >
        <div className="manager_create_que_header">
          <h4 className="app_heading">Create a Questionnaire</h4>
          <div className="d-flex justify-content-lg-end mt-4 mt-lg-0 w-75">
            <button
              className="app_blank_btn me-3"
              onClick={() => handleDiscardBtnClick()}
            >
              Discard
            </button>
            <button
              className="app_secondary_btn ms-3"
              onClick={() => submitDraftInfo()}
            >
              Save as Draft
            </button>
            <button
              className="app_primary_btn ms-3"
              data-bs-toggle="modal"
              data-bs-target="#createQueSubmitConfirmModal"
            >
              Submit
            </button>
          </div>
        </div>
      </header>
      <div className="create_que_form_container container">
        <div className="col col-sm-10 col-lg-8">
          <input
            type="text"
            placeholder="Enter Questionnaire Title"
            onChange={(e) => setTitle(e.target.value)}
            className="craete_que_form_title_input mb-5"
          />
          <h6 className="app_text_secondary_gray"></h6>
          <div className="que_type_dropdown_wrapper mb-4">
            <DatePicker
              className="app_dropDownBtn py-3"
              selected={selectedDate}
              placeholder="Select end date"
              onChange={handleDateChange}
              format="DD MMMM yyyy"
            />
          </div>
          {/* <div className="app_border_card flex-column align-items-center">
            <h6 className="app_text_secondary_gray">Default Question</h6>
            <h4 className="app_heading_20_bold mb-4">
              How are you feeling right now?
            </h4>
            <MoodSelector />
          </div> */}
          {formQueset.map((item) => (
            <DisplayQuestionCard
              queId={item.queBoilerPlateId}
              queType={item.queBoilerPlateSelectedQueType}
              queText={item.queBoilerPlateQueText}
              answersSet={item.queBoilerPlateAnswersSet}
              onQueRemove={handleDisplayQueOnRemove}
            />
          ))}
          {queBoilerPlate.map((item) => {
            return (
              <CreateQueBoilerPlateCard
                key={item.queBoilerPlateId}
                queBoilerPlateId={item.queBoilerPlateId}
                selectedQueType={item.queBoilerPlateSelectedQueType}
                onTypeSelect={handleQueTypeOnChange}
                onDaySelect={handleDayOnChange}
                boilerPlateQueText={item.queBoilerPlateQueText}
                onQueTextChange={boilerPlateQueOnChange}
                onBoilerPlateRemove={queBoilerPlateRemoveBtnClick}
                boilerPlateAnswersSet={item.queBoilerPlateAnswersSet}
                boilerPlateAnswerOnChange={handleAnswersOnChange}
                boilerPlateAnswerOnAdd={handleAddAnswerOnClick}
                boilerPlateAnswerOnRemove={handleAnswerOnRemove}
                boilerPlateAnswersSetLen={item.queBoilerPlateAnswersSet.length}
                question={question}
              />
            );
          })}
          <button
            className="app_primary_btn mt-4"
            onClick={() => queBoilerPlateAddBtnClick()}
          >
            <AddCircleOutlineOutlinedIcon />
            <span className="add_btn_with_icon_text">Add Question</span>
          </button>
        </div>
      </div>
      <CreateQueSubmitConfirmModal
        submitFunction={submitQuestionnaire}
        title={getTitle}
        end_time={selectedDate}
        getQuestion={getQuestionData}
        managerId={getMngId}
      />
    </>
  );
};

export default ManagerCreateQue;
