import React, { useState, useRef, useEffect } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import exitIcon from "../../assets/icons/logoutIcon.svg";
import icHeartbeat from "../../assets/images/ic_heartbeat.svg";
import userIcon from "../../assets/icons/userIcon.svg";
import Http from "../security/Http";
import url from "../../Development.json";
import { errorResponse } from "../Helpers/response";
import userPlaceholder from "../../assets/images/users/user-vector.png";
import appLogo from "../../assets/icons/appicon.png";
import OneSignal from "react-onesignal";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import {
  Business,
  Dashboard,
  QuestionAnswer,
  QuestionMarkOutlined,
} from "@mui/icons-material";

const HeaderManager = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const ManagerHomeLinks = [
    "/manager",
    "/manager/",
    "/manager/que/prev",
    "/manager/que/draft",
  ];

  const checkActive = () => {
    return ManagerHomeLinks.indexOf(location.pathname) > -1;
  };
  const [toggleDisplayManagerAccMenu, setToggleDisplayManagerAccMenu] =
    useState(false);
  const managerAccMenuRef = useRef(null);
  const managerAccMenuBtn = useRef(null);

  const handleManagerAccountMenuOnClick = () => {
    const ddArrow = document.getElementsByClassName("header_dropdownArrow")[0];
    if (managerAccMenuRef.current.classList.contains("show")) {
      managerAccMenuRef.current.classList.remove("show");
      ddArrow.classList.remove("open");
    } else {
      managerAccMenuRef.current.classList.add("show");
      ddArrow.classList.add("open");
    }
    setToggleDisplayManagerAccMenu(!toggleDisplayManagerAccMenu);
  };

  //Manager Logout
  const logout = () => {
    const isManager = localStorage.getItem("man_accessToken") || false;
    if (isManager) {
      const obj = {
        access_token: localStorage.getItem("man_accessToken"),
      };
      OneSignal.deleteTags("mind_pulse_manager").then(function (deleteTags) {});
      localStorage.clear();
      navigate("/login");
    }
  };

  const [managerProfileData, setManagerProfileData] = useState([]);

  const profileData = () => {
    Http.callApi(url.user_profile)
      .then((response) => {
        setManagerProfileData(response.data);
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  useEffect(() => {
    if (localStorage.getItem("man_accessToken")) {
      profileData();
    }
  }, []);

  useEffect(() => {
    const ddArrow = document.getElementsByClassName("header_dropdownArrow")[0];
    if (toggleDisplayManagerAccMenu) {
      document.addEventListener("mousedown", (event) => {
        if (
          managerAccMenuRef.current &&
          !managerAccMenuRef.current.contains(event.target) &&
          !managerAccMenuBtn.current.contains(event.target)
        ) {
          managerAccMenuRef.current.classList.remove("show");
          ddArrow.classList.remove("open");
          setToggleDisplayManagerAccMenu(false);
        }
      });
    }
  }, [toggleDisplayManagerAccMenu]);

  return (
    <header className="app_header_wrapper">
      <div className="app_header_logo_wrapper">
        <Link className="app_header_logo_container_link" to="/manager">
          <img src={appLogo} height="80" alt="logo" />
        </Link>
      </div>
      <div className="app_header_menu_wrapper">
        <ul className="app_header_menu">
          <li>
            <NavLink
              className={() =>
                checkActive()
                  ? "app_header_menu_linkActive"
                  : "app_header_menu_link"
              }
              to="/manager"
            >
              <Dashboard
                className="header_menu_link_icon"
                style={{ fontSize: 20 }}
              />
              <span className="header_menu_link_text ms-2">
                Check-in Dashboard
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "app_header_menu_linkActive" : "app_header_menu_link"
              }
              to="/manager/questionnaires"
            >
              <QuestionMarkOutlined
                className="header_menu_link_icon"
                style={{ fontSize: 20 }}
              />
              <span className="header_menu_link_text ms-2">Questionnaires</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "app_header_menu_linkActive" : "app_header_menu_link"
              }
              to="/manager/emp"
            >
              <PeopleOutlineOutlinedIcon
                className="header_menu_link_icon"
                style={{ fontSize: 20 }}
              />
              <span className="header_menu_link_text ms-2">
                People Directory
              </span>
            </NavLink>
          </li>

          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "app_header_menu_linkActive" : "app_header_menu_link"
              }
              to="/manager/configurator"
            >
              <Business
                className="header_menu_link_icon"
                style={{ fontSize: 20 }}
              />
              <span className="header_menu_link_text ms-2">
                Organisation Configurator
              </span>
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="app_header_user_wrapper position-relative">
        <button
          ref={managerAccMenuBtn}
          onClick={() => handleManagerAccountMenuOnClick()}
          className="app_header_user_btn"
        >
          <div className="header_user_img_container">
            <img
              src={
                managerProfileData.profile_image
                  ? managerProfileData.profile_image
                  : userPlaceholder
              }
              className="header_user_img"
              alt="user"
            />
          </div>
          <span className="app_header_user_name_text">
            {managerProfileData.firstname} {managerProfileData.lastname}
          </span>
          <KeyboardArrowDownOutlinedIcon className="header_dropdownArrow" />
        </button>
        <div className="header_userMenuContainer" ref={managerAccMenuRef}>
          <ul className="header_userMenu">
            <li>
              <Link
                onClick={() => handleManagerAccountMenuOnClick()}
                to="/manager/profile"
                className="userMenuLink app_blank_btn"
              >
                <img src={userIcon} className="userMenuIcon" alt="" />
                Profile
              </Link>
            </li>
            <li>
              <NavLink
                to="/manager/change-password"
                className="userMenuLink app_blank_btn"
              >
                <img src={userIcon} className="userMenuIcon" alt="" />
                Change Password
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/login"
                className="userMenu_logoutBtn"
                onClick={() => logout()}
              >
                <img src={exitIcon} className="userMenuIcon" alt="" />
                Logout
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default HeaderManager;
