import React, { useEffect } from "react";
import {
  errorResponse,
  successResponse,
  isError,
} from "../../Helpers/response.js";
import Http from "../../security/Http";
import { ToastContainer } from "react-toastify";
import { useForm } from "react-hook-form";
import url from "../../../Development.json";
import { Link, useNavigate } from "react-router-dom";

const ForgetPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  //Mail sent
  const onSubmit = (data) => {
    Http.callApi(url.forgot_password, JSON.stringify(data))
      .then((res) => {
        successResponse(res.data.message);
        setTimeout(() => {
          navigate("/email-confirmation", {
            state: { otp: res.data.otp, mobile: data.mobile },
          });
        }, 1000);
      })
      .catch((error) => {
        errorResponse("Please Try Again!");
        if (error.response) {
          errorResponse(error);
        }
      });
  };
  useEffect(() => {
    isError(errors);
  });

  return (
    <>
      <ToastContainer />
      <section className="auth_wrapper">
        <div className="container-fluid p-0">
          <div className="row recovery_box_row">
            <div className="col-lg-6 d-flex align-items-center">
              <div className="login_form_container">
                <h4 className="app_heading">Forgot your Password</h4>
                <p className="app_text mt-2">Need to reset your password?</p>
                <p className="app_text mt-2">
                  Simply enter your registered mobile number below and we will
                  send you a one-time passcode
                </p>
                <div className="login_form_wrapper">
                  <form
                    className="loginBox_form"
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="row g-4">
                      <div className="col-12">
                        <div className="app_textInputWrapper">
                          <input
                            id="mobile"
                            className="app_textInput"
                            type="number"
                            placeholder=" "
                            {...register("mobile", { required: true })}
                          />
                          <label
                            htmlFor="mobile"
                            className="appInput_Placeholder"
                          >
                            Enter your Phone Number
                          </label>
                        </div>
                      </div>
                    </div>
                    <button className="app_primary_btn w-100 mt-4">
                      Request a Password Reset
                    </button>
                    <div className="app_text_black login_page_signup_link_row">
                      <span>Remember your details?</span>
                      <Link
                        to="/login"
                        className="app_primary_text_bold app_link login_page_signup_link"
                      >
                        Login here
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-none d-lg-block">
              <div className="login_image_container"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgetPassword;