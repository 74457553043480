import React from 'react'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const CreateAnswers = ({ boilerPlateId, answerId, answersSetLength, answerText, onChange, answerIndex, onRemoveAnswer }) => {
    return (
        <div className='d-flex col'>
            <input className='create_que_input' onChange={(ev) => onChange(ev, answerId)} value={answerText} type="text" placeholder={`Answer ${answerIndex}`} />
            {answersSetLength > 1 && <button className='app_blank_btn ms-3' onClick={() => onRemoveAnswer(boilerPlateId, answerId)}><CancelOutlinedIcon style={{ color: '#2B2744' }} /></button>}
        </div>
    )
}

export default CreateAnswers