import React, { useState, useEffect } from "react";
import userPlaceholder from "../../../assets/images/users/user-vector.png";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import Http from "../../security/Http";
import url from "../../../Development.json";
import {
  errorResponse,
  successResponse,
  isError,
} from "../../Helpers/response.js";
import { ToastContainer } from "react-toastify";

const EmployeeEditProfile = () => {
  const location = useLocation();
  const { profileData } = location.state;
  const [image, setImage] = useState("");
  const navigate = useNavigate();
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  //Upload Image
  const previewUserImageOnChange = (ev) => {
    const userImg = document.getElementById("userProfileImg");
    userImg.src = URL.createObjectURL(ev.target.files[0]);
    userImg.onload = function () {
      URL.revokeObjectURL(this.src);
    };
    const image = new FormData();
    image.append("file", ev.target.files[0]);
    Http.callApi(url.upload_image, image)
      .then((res) => {
        setImage(res.data.file_url);
      })
      .catch((error) => {
        errorResponse("Please Try Again!");
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  //Update Employee Profile
  const onSubmit = (data) => {
    if (image != "") {
      data["profile_image"] = image;
    }
    Http.callApi(url.update_Profile, JSON.stringify(data))
      .then((response) => {
        successResponse("Your Profile Updated Successfully.");
        setTimeout(() => {
          navigate("/employee");
        }, 2000);
      })
      .catch((error) => {
        errorResponse("Please Try Again!");
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  useEffect(() => {
    if (profileData) {
      const data = profileData;
      setValue("firstname", data.firstname);
      setValue("lastname", data.lastname);
      setValue("company_position", data.company_position);
      setValue("email", data.email);
      setValue("mobile", data.mobile);
    }
  }, []);

  useEffect(() => {
    isError(errors);
  });

  return (
    <>
      <ToastContainer />
      <form onSubmit={handleSubmit(onSubmit)}>
        <header className="manager_profile_header">
          <h4 className="app_heading">Profile</h4>
          <button className="btn app_primary_btn">Save</button>
        </header>
        <div className="container mt-5">
          <div className="row d-flex justify-content-center">
            <div className="col-12 col-md-10 col-lg-9">
              <div className="app_card">
                <h4 className="app_heading_20_bold">Profile Picture</h4>
                <div className="edituserimg_wrapper">
                  <div className="edituserimg_container">
                    <img
                      id="userProfileImg"
                      src={
                        profileData.profile_image
                          ? `${profileData.profile_image}`
                          : userPlaceholder
                      }
                      className="header_user_img"
                      alt="user"
                    />
                  </div>
                  <div className="userEditImgInput_wrapper">
                    <label
                      htmlFor="managerProfileImgEdit"
                      className="userprofile_editImgLabel"
                    >
                      <CameraAltIcon />
                    </label>
                    <input
                      onChange={(ev) => previewUserImageOnChange(ev)}
                      type="file"
                      name="profile_image"
                      accept="image/*"
                      id="managerProfileImgEdit"
                      className="userprofile_editImgInput"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center mt-4">
            <div className="col-12 col-md-10 col-lg-9">
              <div className="app_card">
                <h4 className="app_heading_20_bold mb-4">Information</h4>
                <div className="row g-4">
                  <div className="col-lg-4">
                    <div className="app_textInputWrapper">
                      <input
                        className="app_textInput"
                        type="text"
                        placeholder=" "
                        id="firstname"
                        {...register("firstname", { required: true })}
                      />
                      <label
                        className="appInput_Placeholder"
                        htmlFor="firstname"
                      >
                        First Name
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="app_textInputWrapper">
                      <input
                        className="app_textInput"
                        type="text"
                        placeholder=" "
                        id="lastname"
                        {...register("lastname", { required: true })}
                      />
                      <label
                        className="appInput_Placeholder"
                        htmlFor="lastname"
                      >
                        Last Name
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="app_textInputWrapper">
                      <input
                        className="app_textInput"
                        type="text"
                        placeholder=" "
                        id="mobile"
                        {...register("mobile", { required: true })}
                      />
                      <label className="appInput_Placeholder" htmlFor="mobile">
                        Position
                      </label>
                    </div>
                  </div>
                  {/* <div className='col-md-6'>
                                        <div className='app_textInputWrapper'>
                                            <input className='app_textInput' type="email" placeholder=' ' id="email" {...register('email', { required: true })} />
                                            <label className='appInput_Placeholder' htmlFor='email'>Email</label>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='app_textInputWrapper'>
                                            <input className='app_textInput' type="number" placeholder=' ' id="mobile" {...register('mobile', {
                                                required: true, minLength: {
                                                    value: 10,
                                                    message: "minimum length is 10"
                                                },
                                            })} />
                                            <label className='appInput_Placeholder' htmlFor='mobile'>Phone</label>
                                        </div>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default EmployeeEditProfile;
