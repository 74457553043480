import React from "react";
import getVideoId from "get-video-id";
// import youtubeThumbnail from 'youtube-thumbnail';

const ExerciseCard = (props) => {
  return (
    <div className="col">
      <button
        className="app_blank_btn"
        onClick={() => props.onClickFunc(props.item)}
      >
        <div
          style={{
            border: "1px solid #e3e3e3",
            padding: "12px",
            borderRadius: "12px",
            backgroundColor: "#ffffff",
          }}
        >
          <div className="recipe_card">
            <div className="recipe_card_img_container">
              <img
                className="mb-2"
                src={props.item.image}
                alt="thumbnail"
                style={{
                  height: "125px",
                  width: "100%",
                  borderRadius: "8px",
                  objectFit: "cover",
                }}
              />
            </div>
            <div className="recipe_card_footer">
              <h4 className="app_text_bold recipe_card_title text-start">
                {props.item.title}
              </h4>
            </div>
          </div>
        </div>
      </button>
    </div>
  );
};

export default ExerciseCard;
