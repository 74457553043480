import React, { useState, useEffect } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import QuestionnaireDraftCard from "../../../sub-components/managerQueSub/QuestionnaireDraftCard";
import QueRemoveModal from "../../../sub-components/managerQueSub/QueRemoveModal";
import url from "../../../../Development.json";
import Http from "../../../security/Http";
import { errorResponse } from "../../../Helpers/response";
import AppLoader from "../../../sub-components/AppLoader";

const ManagerQueDraft = () => {
  const [getDrafQueList, SetDrafQueList] = useState([]); //Get Draf Question list
  const [getDrafQueItem, SetDrafQueItem] = useState([]);
  const [questionSearch, setQuestionSearch] = useState("");

  const handleQuestionOnSearch = (ev) => {
    setQuestionSearch(ev.target.value);
    let que_search = ev.target.value;
    let search = { search: que_search };
    Http.callApi(url.get_draft_questions, search)
      .then((response) => {
        SetDrafQueList(response.data);
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  // const getDraftQuestionsData = (event) => {
  //     if (event.keyCode === 13) {
  //         let que_search = questionSearch;
  //         let search = { search: que_search }
  //         setIsLoading(true);
  //         Http.callApi(url.get_draft_questions, search)
  //             .then((response) => {
  //                 SetDrafQueList(response.data);
  //                 setIsLoading(false);
  //             })
  //             .catch((error) => {
  //                 if (error.response) {
  //                     errorResponse(error);
  //                 }
  //             });
  //     }
  // }
  useEffect(() => {
    Http.callApi(url.get_draft_questions)
      .then((response) => {
        SetDrafQueList(response.data);
      })
      .catch((error) => {
        if (error.message) {
          errorResponse(error.message);
        } else {
          errorResponse("Please Try Again!");
        }
      });
  }, []);

  const getItem = (item) => {
    SetDrafQueItem(item);
  };
  return (
    <>
      <div className="container">
        <header className="que_prev_header">
          <div>
            <h4 className="app_heading_24_bold">Saved Questionnaires</h4>
          </div>
          <div className="d-flex col-12 col-md-6 col-lg-4 mt-4 mt-md-0">
            <div className="app_searchInputWrapper w-100">
              <span className="app_search_icon_wrapper">
                <SearchOutlinedIcon style={{ fontSize: 20 }} />
              </span>
              <input
                type="text"
                onChange={(ev) => handleQuestionOnSearch(ev)}
                value={questionSearch}
                placeholder="Search questionnaires"
              />
            </div>
          </div>
        </header>
        <div className="row row-cols-1 gy-4">
          {getDrafQueList.length !== 0 ? (
            getDrafQueList.map((item) => (
              <QuestionnaireDraftCard
                queId={item._id}
                queTitle={item.title}
                queResponses={item.response_count}
                queEditedTime={item.updatedAt}
                queItem={item}
                queItems={getItem}
              />
            ))
          ) : (
            <span className="validation_text_invalid">No data found</span>
          )}
        </div>
        <QueRemoveModal getItem={getDrafQueItem} />
      </div>
    </>
  );
};

export default ManagerQueDraft;
