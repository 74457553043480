import React, { useState, useRef, useEffect } from "react";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";

const QuestionnairePreviousCard = (props) => {
  let numberOfResponses = props.queResponses;
  let quePostedOn = dateFormat(props.quePostTime, "dS mmmm, yyyy");
  let queTitle = props.queTitle;

  const managerQueCardSubMenuRef = useRef(null);
  const managerQueCardSubmenuBtn = useRef(null);
  const [showManagerQueCardSubMenu, setShowManagerQueCardSubMenu] =
    useState(false);
  const handleManagerQueCardSubMenuOnClick = () => {
    setShowManagerQueCardSubMenu(!showManagerQueCardSubMenu);
  };

  useEffect(() => {
    showManagerQueCardSubMenu
      ? managerQueCardSubMenuRef.current.classList.add("show")
      : managerQueCardSubMenuRef.current.classList.remove("show");
  }, [showManagerQueCardSubMenu]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        managerQueCardSubMenuRef.current &&
        !managerQueCardSubMenuRef.current.contains(event.target) &&
        !managerQueCardSubmenuBtn.current.contains(event.target)
      ) {
        setShowManagerQueCardSubMenu(false);
      }
    }
    if (showManagerQueCardSubMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    }
  }, [
    managerQueCardSubMenuRef,
    showManagerQueCardSubMenu,
    managerQueCardSubmenuBtn,
  ]);

  return (
    <div className="col">
      <div className="manager_que_card">
        <div className="que_card_left">
          <h4 className="que_card_title text-capitalize">{queTitle}</h4>
          <div className="d-flex que_card_left_detail_container mt-4 mt-md-0">
            <div className="d-flex align-items-center me-4 mb-3 mb-md-0">
              <ForumOutlinedIcon
                style={{ color: "#9997A6", fontSize: 20, marginRight: "10px" }}
              />
              <span className="me-1">Responses:</span>
              <span style={{ fontWeight: "600" }}>{numberOfResponses}</span>
            </div>
            <div className="d-flex align-items-center">
              <WatchLaterOutlinedIcon
                style={{ color: "#9997A6", fontSize: 20, marginRight: "10px" }}
              />
              <span className="me-1">Posted on: </span>
              <span style={{ fontWeight: "600" }}>{quePostedOn}</span>
            </div>
          </div>
        </div>
        <div className="que_card_right">
          <Link
            to="/manager/view-response"
            state={{ questionId: props.queId }}
            className="app_secondary_btn que_card_view_response_btn"
          >
            View Responses
          </Link>
          <div className="position-relative">
            <button
              ref={managerQueCardSubmenuBtn}
              onClick={() => handleManagerQueCardSubMenuOnClick()}
              className="app_blank_btn ms-3"
            >
              <MoreVertOutlinedIcon style={{ fontSize: 32 }} />
            </button>
            <div
              ref={managerQueCardSubMenuRef}
              className="manager_que_card_submenu_container"
            >
              <ul className="manager_que_card_submenu">
                {/* <li>
                  <button
                    type="button"
                    className="manager_que_card_submenu_btn"
                    onClick={() => props.queItems(props)}
                    data-bs-toggle="modal"
                    data-bs-target="#quickViewQueModal"
                  >
                    <RemoveRedEyeOutlinedIcon style={{ marginRight: 15 }} />
                    View Questions
                  </button>
                </li> */}
                <li>
                  <button
                    className="manager_que_card_submenu_btn"
                    onClick={() => props.queItems(props)}
                    data-bs-toggle="modal"
                    data-bs-target="#queRemoveModal"
                  >
                    <DeleteOutlinedIcon style={{ marginRight: 15 }} />
                    Remove Questionnaire
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionnairePreviousCard;
