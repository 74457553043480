import React, { useState, useEffect, useRef } from 'react'
import AlbumIcon from '@mui/icons-material/Album';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

const QuestionTypeSelector = ({ selectedQueType, onTypeSelect, boilerPlateId }) => {
    const [toggleDD, setToggleDD] = useState(false); //Toggle display listing sorting dropdown
    
    const queTypeDDRef = useRef(null);
    const queTypeDDBtnRef = useRef(null);
    


    const showQueTypeDropDown = () => {
        const queTypeDDIcon = document.getElementsByClassName("app_dd_arrowIcon")[0];
        if (queTypeDDRef.current.classList.contains("show")) {
            queTypeDDRef.current.classList.remove("show")
            queTypeDDIcon.classList.remove("open")
        } else {
            queTypeDDRef.current.classList.add("show")
            queTypeDDIcon.classList.add("open");
        }
        setToggleDD(!toggleDD);
    }
    
    useEffect(() => {
        const queTypeDDIcon = document.getElementsByClassName("app_dd_arrowIcon")[0];
        if (toggleDD) {
            document.addEventListener("mousedown", (event) => {
                if (queTypeDDRef.current && !queTypeDDRef.current.contains(event.target) && !queTypeDDBtnRef.current.contains(event.target)) {
                    queTypeDDRef.current.classList.remove("show")
                    queTypeDDIcon.classList.remove("open")
                    setToggleDD(false);
                }
            })
        }
       
    }, [toggleDD])

    const handleQueTypeOptionOnChange = (type,value) => {
        const queTypeDDIcon = document.getElementsByClassName("app_dd_arrowIcon")[0];
        queTypeDDRef.current.classList.remove("show")
        queTypeDDIcon.classList.remove("open")
        onTypeSelect(type, boilerPlateId)
    }

    return (
        <>
        <div className='que_type_dropdown_wrapper'>
            <button ref={queTypeDDBtnRef} className="app_dropDownBtn" onClick={() => showQueTypeDropDown()}>
                {selectedQueType === 'single' ? <div className='app_blank_btn'><AlbumIcon /><span className='ms-3'>Single select question</span></div> : selectedQueType === 'multi' ?
                    <div className='app_blank_btn'><CheckBoxIcon /><span className='ms-3'>Multi select question</span></div> : <div className='app_blank_btn'><ForumOutlinedIcon /><span className='ms-3'>Text Answer</span></div>}
                <span>
                    <KeyboardArrowDownOutlinedIcon className='app_dd_arrowIcon' />
                </span>
            </button>
            <div ref={queTypeDDRef} className='app_dd_list_wrapper'>
                <ul className='app_dd_list que_type_selector_options_menu'>
                    <li><button className='app_blank_btn' onClick={() => handleQueTypeOptionOnChange('single',1)}><AlbumIcon /><span className='ms-3'>Single select question</span></button></li>
                    <li><button className='app_blank_btn' onClick={() => handleQueTypeOptionOnChange('multi',2)}><CheckBoxIcon /><span className='ms-3'>Multi select question</span></button></li>
                    <li><button className='app_blank_btn' onClick={() => handleQueTypeOptionOnChange('text',3)}><ForumOutlinedIcon /><span className='ms-3'>Text answer question </span></button></li>
                </ul>
            </div>
        </div>
        </>
    )
}

export default QuestionTypeSelector