import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";

const TextQuestionModule = (props) => {
  const [value, setValue] = useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    props.onChangeFunc(value);
  });

  return (
    <>
      <h4 className="app_text_secondary_gray text-uppercase">Question</h4>
      <h4 className="app_heading_20_bold mt-3">{props.question.title}</h4>
      <div className="answer_wrapper">
        <h4 className="app_text_primary mb-4 text-uppercase">
          Enter your answer
        </h4>
        <TextField
          id="outlined-multiline-static"
          label="Answer"
          style={{ borderRadius: "24px" }}
          className="w-100"
          multiline
          rows={4}
          value={value}
          onChange={handleChange}
        />
      </div>
    </>
  );
};

export default TextQuestionModule;
