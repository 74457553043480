import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import userPlaceholder from "../../../assets/images/users/user-vector.png";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Http from "../../security/Http";
import url from "../../../Development.json";
import { useForm } from "react-hook-form";

import {
  errorResponse,
  successResponse,
  isError,
} from "../../Helpers/response";

const AddSupportConnectModal = ({ userData, onSubmit }) => {
  const navigate = useNavigate();
  const [imageData, setImageData] = useState(null);
  const [companyData, setCompanyData] = useState({});
  const [companyId, setCompanyId] = useState("");
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  //Upload Image
  const previewUserImage = (ev) => {
    const eapImage = document.getElementById("eapProviderImg");
    eapImage.src = URL.createObjectURL(ev.target.files[0]);
    eapImage.onload = function () {
      URL.revokeObjectURL(this.src);
    };

    const image = new FormData();
    image.append("file", ev.target.files[0]);
    Http.callApi(url.upload_image, image)
      .then((res) => {
        console.log(res);
        setImageData(res.data.file_url);
      })
      .catch((error) => {
        errorResponse("Please Try Again!");
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  const profileData = () => {
    Http.callApi(url.user_profile)
      .then((response) => {
        console.log(response.data);
        if (response.data.company) {
          setCompanyData(response.data.company);
          setCompanyId(response.data.company._id);
        }
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  useEffect(() => {
    profileData();
  }, []);

  const createEap = (info) => {
    console.log(imageData);
    console.log(info);
    const data = {
      image: imageData,
      mobile: info.phone_number,
      options: {
        title: info.eap_name,
        description: info.eap_description,
        image: imageData,
      },
      featured: info.featured_eap,
      position: "",
      company: companyId,
    };

    console.log("EAP DATA", data);
    Http.callApi(url.add_eap, data)
      .then((response) => {
        console.log(response);
        window.location.reload();
      })
      .catch((error) => {
        if (error.message) {
          errorResponse(error.message);
        } else {
          errorResponse("Please Try Again!");
        }
      });
  };

  return (
    <form onSubmit={handleSubmit(createEap)}>
      <div
        className="modal fade"
        id="supportConnectModal"
        tabIndex="-1"
        aria-labelledby="supportConnectModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content" style={{ borderRadius: "12px" }}>
            <div
              className="modal-body d-flex flex-column"
              style={{ borderRadius: "12px" }}
            >
              <h4 className="text-center app_text_20_bold my-2">
                Add Support Provider
              </h4>
              <div
                style={{
                  display: "flex",
                  textAlign: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h4 style={{ fontSize: 16 }} className="app_heading_20_bold">
                  Providers Image
                </h4>
                <div
                  className="edituserimg_wrapper"
                  style={{
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="edituserimg_container">
                    <img
                      id="eapProviderImg"
                      src={imageData ? imageData : userPlaceholder}
                      className="header_user_img"
                      alt="user"
                    />
                  </div>
                  <div className="userEditImgInput_wrapper">
                    <label
                      htmlFor="eap_image_edit"
                      className="userprofile_editImgLabel"
                    >
                      <CameraAltIcon />
                    </label>
                    <input
                      onChange={(ev) => previewUserImage(ev)}
                      type="file"
                      name="eap_image"
                      accept="image/*"
                      id="eap_image_edit"
                      className="userprofile_editImgInput"
                    />
                  </div>
                </div>
              </div>
              <div className="row g-2 mt-2">
                <div className="col-lg-6 ">
                  <div className="app_textInputWrapper">
                    <input
                      className="app_textInput"
                      type="text"
                      placeholder=" "
                      id="eap_name"
                      {...register("eap_name", { required: true })}
                    />
                    <label className="appInput_Placeholder" htmlFor="eap_name">
                      Providers Name
                    </label>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="app_textInputWrapper">
                    <input
                      className="app_textInput"
                      type="text"
                      placeholder=" "
                      id="phone_number"
                      {...register("phone_number", { required: true })}
                    />
                    <label
                      className="appInput_Placeholder"
                      htmlFor="phone_number"
                    >
                      Providers Phone Number
                    </label>
                  </div>
                </div>
              </div>

              <div className="row g-2 mt-2">
                <div className="col-lg-12 ">
                  <div className="app_textInputWrapper">
                    <textarea
                      className="app_textInput"
                      type="text"
                      placeholder=" "
                      aria-multiline={true}
                      id="eap_description"
                      {...register("eap_description", { required: true })}
                    />
                    <label
                      className="appInput_Placeholder"
                      htmlFor="eap_description"
                    >
                      Providers Description
                    </label>
                  </div>
                </div>
              </div>

              <div className="row g-2 mt-2">
                <div className="col-lg-12 m-auto">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <input
                      type="checkbox"
                      placeholder=" "
                      aria-multiline={true}
                      id="featured_eap"
                      {...register("featured_eap", { required: true })}
                    />
                    <label className="px-2" htmlFor="featured_eap">
                      Make this a featured provider?
                    </label>
                  </div>
                </div>
              </div>

              <button
                className="app_primary_btn my-3"
                data-bs-dismiss="modal"
                type={"submit"}
              >
                Add Support Provider
              </button>
              <button
                className="app_secondary_btn"
                type={"button"}
                data-bs-toggle="modal"
                data-bs-target="#supportConnectModal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddSupportConnectModal;
