import React, { useState, useEffect } from "react";
import Modal from 'react-modal';

const NotificationSupportActions = ({ isOpen, onClose, user }) => {
    const [showPhone, setShowPhone] = useState(false);

    useEffect(() => {
        // Set a timer that changes the state value after 5 seconds
        var timer;
        if (showPhone == true) {
            timer = setTimeout(() => {
                setShowPhone(false);
            }, 5000);
        }

        // Clear the timer when the component is unmounted
        return () => clearTimeout(timer);
    }, [showPhone]); // Empty dependency array means this effect will only run once on mount

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: 16,
        },
        overlay: {
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: 9999,
        }
    };

    return (
        <div>
            <Modal
                isOpen={isOpen}
                onRequestClose={onClose}
                closeTimeoutMS={250}
                style={customStyles}
            >
                <h4 className="text-center app_text_20_bold my-2">
                    Contact {user?.firstname ?? ""}
                </h4>
                <p style={{ fontSize: 14 }}>
                    {user?.firstname ?? ""} has indicated they're not feeling the best, it might be worth checking in with them.
                </p>
                {user?.mobile?.length > 0 ? (
                    <>
                        {!showPhone ? (
                            <div
                                onClick={() => setShowPhone(true)}
                                style={{ cursor: 'pointer' }}
                                className="app_primary_btn mb-2">
                            Call
                            </div>) : (
                            <div className="mb-2 text-center" style={{ fontWeight: '500' }}>
                                <p>{user?.mobile ?? ""}</p>
                            </div>
                        )}
                        <a
                            href={`mailto:${user?.email}`}
                            className="app_primary_btn mb-3"
                        >
                            Email
                        </a>

                        <div className="app_secondary_btn mt-1" style={{ cursor: 'pointer' }} onClick={onClose}>Cancel</div>
                    </>
                ) : (<></>)}
            </Modal>
        </div>
    );
};

export default NotificationSupportActions;