import React, { useState, useEffect } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import QuestionnaireActiveCard from "../../../sub-components/managerQueSub/QuestionnaireActiveCard";
import QuickQueViewModal from "../../../sub-components/managerQueSub/QuickQueViewModal";
import QueRemoveModal from "../../../sub-components/managerQueSub/QueRemoveModal";
import { Link } from "react-router-dom";
import url from "../../../../Development.json";
import Http from "../../../security/Http";
import { errorResponse } from "../../../Helpers/response";
import AppLoader from "../../../sub-components/AppLoader";

const ManagerQueActive = () => {
  const [getActiveQueList, SetGetActiveQueList] = useState([]); //Get activeQuestion data
  const [getActiveQueItem, SetActiveQueItem] = useState([]);
  const [questionSearch, setQuestionSearch] = useState("");

  const handleQuestionOnSearch = (ev) => {
    setQuestionSearch(ev.target.value);
    let que_search = ev.target.value;
    let search = { search: que_search };
    Http.callApi(url.get_questions, search)
      .then((response) => {
        SetGetActiveQueList(response.data);
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  function updateQuestionnaires() {
    Http.callApi(url.get_questions)
      .then((response) => {
        SetGetActiveQueList(response.data);
      })
      .catch((error) => {
        if (error.message) {
          errorResponse(error.message);
        } else {
          errorResponse("Please Try Again!");
        }
      });
  }

  useEffect(() => {
    Http.callApi(url.get_questions)
      .then((response) => {
        SetGetActiveQueList(response.data);
      })
      .catch((error) => {
        if (error.message) {
          errorResponse(error.message);
        } else {
          errorResponse("Please Try Again!");
        }
      });
  }, []);

  const getItem = (item) => {
    SetActiveQueItem(item);
  };

  return (
    <>
      <div className="container">
        <header className="que_active_header">
          <div className="mb-4 mb-lg-0">
            <h4 className="app_heading_24_bold text-nowrap">
              Active Questionnaires
            </h4>
          </div>
          <div className="d-flex justify-content-between justify-content-lg-end w-100">
            <div className="app_searchInputWrapper me-4">
              <span className="app_search_icon_wrapper">
                <SearchOutlinedIcon style={{ fontSize: 20 }} />
              </span>
              <input
                type="text"
                onChange={(ev) => {
                  handleQuestionOnSearch(ev);
                  // getQuestionnairesData(ev);
                }}
                value={questionSearch}
                placeholder="Search questionnaires"
              />
            </div>
            <Link
              to="/manager/create-que"
              className="app_primary_btn create_que_btn"
            >
              <AddCircleOutlineOutlinedIcon />
              <span className="add_btn_with_icon_text">
                Create Questionnaire
              </span>
            </Link>
          </div>
        </header>

        <div className="row row-cols-1 gy-4">
          {getActiveQueList.length !== 0 ? (
            getActiveQueList.map((item) => {
              console.log(item);
              return (
                <>
                  <QuestionnaireActiveCard
                    key={item._id}
                    queId={item._id}
                    queTitle={item.title}
                    queResponses={item?.response_count}
                    queTimeout={item?.end_time}
                    questionId={item._id}
                    queItem={item}
                    queItems={getItem}
                  />
                </>
              );
            })
          ) : (
            <span className="validation_text_invalid">No data found</span>
          )}
        </div>
      </div>
      <QuickQueViewModal getItem={getActiveQueItem} />
      <QueRemoveModal
        getItem={getActiveQueItem}
        getQuestionnaires={updateQuestionnaires}
      />
    </>
  );
};

export default ManagerQueActive;
