import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import "@fullcalendar/core/main";
import "@fullcalendar/daygrid/main";
import { format } from "date-fns/esm";
import moment from "moment";
import Http from "../../../security/Http";
import url from "../../../../Development.json";
import { errorResponse } from "../../../Helpers/response";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import sad from "../../../../assets/images/moods/ic_sad.png";
import unhappy from "../../../../assets/images/moods/ic_unhappy.png";
import okay from "../../../../assets/images/moods/ic_okay.png";
import happy from "../../../../assets/images/moods/ic_happy.png";
import excited from "../../../../assets/images/moods/Excited.png";
import elated from "../../../../assets/images/moods/ic_elated.png";

const Calender = ({ getCalData, employee, onBackPressed, onNextPressed }) => {
  const [getEmpCal, SetEmpCal] = useState([]);
  const [eventData, setEventData] = useState([]);

  useEffect(() => {
    renderCalendarData();
    let calendarApi = calendarComponentRef.current.getApi();
  }, [getCalData]);

  const renderCalendarData = () => {

    console.log("CALENDAR DATA:", getCalData);
    let eData = [];
    getCalData.map((item) => {
      const titleChange =
        item.log_type === 1
          ? "Checkin"
          : item.log_type === 2
            ? "RDO"
            : item.log_type === 3
              ? "SL"
              : item.log_type === 5
                ? "DFL"
                : "";
      const tagType =
        item.log_type === 2
          ? "text-secondary"
          : item.log_type === 3
            ? "text-secondary"
            : item.log_type === 5
              ? "text-secondary"
              : "";
      const moodType =
        item.mood_type === 0
          ? sad
          : item.mood_type === 1
            ? unhappy
            : item.mood_type === 2
              ? okay
              : item.mood_type === 3
                ? happy
                : item.mood_type === 4
                  ? elated
                  : "";
      eData.push({
        id: "1",
        title: titleChange,
        imageurl: moodType,
        className: tagType,
        start: item.createdAt,
      });
    });

    setEventData(eData);
  };


  function renderEventContent(eventInfo) {
    return (
      <>
        {eventInfo.event.title == "Checkin" ? (
          <img
            src={eventInfo.event.extendedProps.imageurl}
            width="30"
            alt="mood"
          />
        ) : (
          <span>{eventInfo.event.title}</span>
        )}
      </>
    );
  }
  const calendarComponentRef = useRef(null);

  // const weekends = () => {
  //   const fcSatDay = document.querySelectorAll(
  //     ".fc-scrollgrid-sync-table tbody .fc-day-sat"
  //   );
  //   const fcSunDay = document.querySelectorAll(
  //     ".fc-scrollgrid-sync-table tbody .fc-day-sun"
  //   );
  //   fcSatDay.forEach((element) => {
  //     element.classList.add("fc-day-other");
  //   });
  //   fcSunDay.forEach((element) => {
  //     element.classList.add("fc-day-other");
  //   });
  // };
  // useEffect(() => {
  //   weekends();
  // });

  return (
    <>
      <div>
        <FullCalendar
          height={"75vh"}
          timeZone="Australia/Adelaide"
          ref={calendarComponentRef}
          plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
          defaultView="dayGridMonth"
          customButtons={{
            prev: {
              click: function () {
                setTimeout(() => {
                  let calendarApi = calendarComponentRef.current.getApi();
                  calendarApi.prev();
                  const currentDate =
                    calendarApi.currentDataManager.data.currentDate;
                  onBackPressed(currentDate);
                });
              },
            },
            next: {
              click: function () {
                setTimeout(() => {
                  let calendarApi = calendarComponentRef.current.getApi();
                  calendarApi.next();
                  const currentDate =
                    calendarApi.currentDataManager.data.currentDate;
                  onNextPressed(currentDate);
                });
              },
            },
          }}
          headerToolbar={{
            left: "prev",
            center: "title",
            right: "next",
          }}
          firstDay="1"
          events={eventData}
          eventContent={renderEventContent}
        />
      </div>
    </>
  );
}

export default Calender;
