import React from "react";
import MoodSelector from "../MoodSelector";
import MultiChoiceQue from "./MultiChoiceQue";
import SingleChoiceQue from "./SingleChoiceQue";
import TextQue from "./TextQue";

const QuickQueViewModal = (props) => {
  const getItem = props.getItem.queItem && props.getItem.queItem.question;
  return (
    <div
      className="modal fade"
      id="quickViewQueModal"
      tabIndex="-1"
      aria-labelledby="quickViewQueModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div
          className="modal-content p-4 border-0"
          style={{
            borderRadius: 15,
            boxShadow: "0px 1px 2px rgba(43, 39, 68, 0.1)",
          }}
        >
          <div className="modal-header border-0">
            <h5 className="modal-title" id="quickViewQueModalLabel">
              {props.getItem.queTitle}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {/* <div className='app_border_card flex-column align-items-center mb-4'>
                            <h6 className='app_text_secondary_gray'>Default Question</h6>
                            <h4 className='app_heading_20_bold mb-4'>What’s your mood right now?</h4>
                            <MoodSelector />
                        </div> */}
            {getItem?.map((data, index) => {
              if (index > 0) {
                if (data.type == 1) {
                  return (
                    <div className="my-4">
                      <SingleChoiceQue
                        queTitle={data.title}
                        answers={data.options.map((data) => {
                          return { answerText: data };
                        })}
                      />
                    </div>
                  );
                }
                if (data.type == 2) {
                  return (
                    <div className="my-4">
                      <MultiChoiceQue
                        queTitle={data.title}
                        answers={data.options.map((data) => {
                          return { answerText: data };
                        })}
                      />
                    </div>
                  );
                }
                if (data.type == 3) {
                  return <TextQue queTitle={data.title} />;
                }
              }
            })}
            {/* <SingleChoiceQue queTitle="{item.title}" answers={[{ answerText: 'Male' }, { answerText: 'Female' },]} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickQueViewModal;
