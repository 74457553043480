import React from "react";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";

const EADOptionModal = (props) => {
  console.log(props);
  return (
    <div
      className="modal fade"
      id="eadOptionModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{ borderRadius: "12px" }}
    >
      <div className="modal-dialog modal-dialog-centered modal-sm">
        <div className="modal-content p-2" style={{ borderRadius: "12px" }}>
          <button
            type="button"
            className="btn-close ead_modal_close_btn"
            data-bs-dismiss="modal"
            aria-label="Close"
            style={{ position: "absolute", right: 8, top: 8, zIndex: 999 }}
          ></button>
          <div className="modal_header_ead border-0 position-relative px-2 mt-2">
            <div className="ead_option_modal_img_container text-center">
              <img
                src={props.showEADItem.options[0].image}
                className="img-fluid"
                style={{ height: 96, width: 156, objectFit: "contain" }}
                alt="ead"
              />
            </div>
          </div>
          <div className="modal-body pt-0">
            <h4
              className="app_heading_16 justify-content-center"
              style={{ fontWeight: "600" }}
            >
              {props.showEADItem.options[0].title}
            </h4>
            <p
              className="app_text mt-2 text-center mb-2"
              style={{ color: "#4C4862", fontSize: 14 }}
            >
              {props.showEADItem.options[0].description}
            </p>
          </div>
          <a
            style={{ textDecoration: "unset" }}
            href={`tel://${props.showEADItem.mobile}`}
          >
            <div className="app_primary_btn">
              <LocalPhoneOutlinedIcon />
              <span className="app_text ms-2 text-white">
                {props.showEADItem.mobile}
              </span>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default EADOptionModal;
