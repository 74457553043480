import React from "react";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { useNavigate } from "react-router-dom";

const RecipeCard = (props) => {
  const navigate = useNavigate();
  const viewButtonClick = (data) => {
    navigate("/eatwell/recipe", { state: JSON.stringify(data) });
  };

  return (
    <div className="col">
      <a
        onClick={(e) => {
          viewButtonClick(props);
        }}
      >
        <div
          style={{
            border: "1px solid #e3e3e3",
            padding: "12px",
            borderRadius: "12px",
            backgroundColor: "#ffffff",
            height: "250px",
          }}
        >
          <div className="recipe_card">
            <div className="recipe_card_img_container">
              <img
                className="mb-2"
                src={props.recipe.image}
                alt="recipe"
                style={{
                  height: "175px",
                  width: "100%",
                  borderRadius: "8px",
                  objectFit: "cover",
                }}
              />
            </div>
            <div className="recipe_card_footer row">
              <h4 className="app_text_bold recipe_card_title">
                {props.recipe.title}
              </h4>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default RecipeCard;
