import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import axios from "axios";

// toast.configure({
//     // autoClose: 500,
// });

function customStylesDataTable() {
  const customStyles = {
    headCells: {
      style: {
        "&:last-child": {
          justifyContent: "center",
        },
      },
    },
    cells: {
      style: {
        "&:first-child": {
          justifyContent: "center",
        },
        "&:last-child": {
          justifyContent: "center",
        },
      },
    },
  };
  return customStyles;
}

function insertAfter(referenceNode, newNode) {
  referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}

//error messages
function isErrorMessage(name, message, empty) {
  var el = document.createElement("span");
  el.classList.add("validation_text_invalid");
  if (empty) {
    el.innerHTML = message;
  } else {
    el.innerHTML = humanize(name) + " " + message;
  }
  var div = document.getElementById(name).parentNode;

  div.classList.add("is-invalid");
  insertAfter(div, el);
}
function humanize(str) {
  var i,
    frags = str.split("_");
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join("");
}
function isError(error) {
  var errors_entries = Object.entries(error);
  if (errors_entries) {
    var allDivsCollections = document.getElementsByClassName(
      "validation_text_invalid"
    );
    var arr = Array.from(allDivsCollections);
    arr.forEach((singleElement) => {
      singleElement.previousSibling.classList.remove("is-invalid");
      singleElement.remove();
    });
    errors_entries.forEach((errorAll) => {
      let type = errorAll[1].type;
      let name = errorAll[1].ref.name;
      let message = "";
      let empty = "";
      if (type === "required") {
        if (errorAll[1].message) {
          message = errorAll[1].message;
          empty = errorAll[1].message;
        } else {
          message = "is required.";
        }
        isErrorMessage(name, message, empty);
      } else if (type === "minLength") {
        message = errorAll[1].message;
        empty = errorAll[1].message;
        isErrorMessage(name, message, empty);
      } else if (type === "maxLength") {
        message = errorAll[1].message;
        empty = errorAll[1].message;
        isErrorMessage(name, message, empty);
      } else if (type === "validate") {
        if (errorAll[1].message) {
          message = errorAll[1].message;
          empty = errorAll[1].message;
        } else {
          message = "Confirm Password Field is requeid";
        }
        isErrorMessage(name, message, empty);
      } else if (type === "min") {
        message = "min number is 0";
        isErrorMessage(name, message);
      } else if (type === "max") {
        message = "max number is 100";
        isErrorMessage(name, message);
      } else if (type === "validate") {
        message = errorAll[1].message;
        isErrorMessage(name, message);
      } else if (type === "pattern") {
        message = errorAll[1].message;
        empty = errorAll[1].message;
        isErrorMessage(name, message, empty);
      } else {
        message = "required.";
        isErrorMessage(name, message);
      }
    });
  }
}
function configHeaderAxios() {
  if (localStorage.getItem("usertype") == 3) {
    const config = {
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ` + localStorage.getItem("man_accessToken"),
        env: "test",
      },
    };
    return config;
  } else if (localStorage.getItem("usertype") <= 2) {
    const config = {
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ` + localStorage.getItem("emp_accessToken"),
        env: "test",
      },
    };
    return config;
  } else {
    const config = {
      headers: {
        "content-type": "application/json",
        env: "test",
      },
    };
    return config;
  }
}

// function configOptionType(type, callback, errorcallback) {
//     let urlcall = process.env.REACT_APP_BASE_URL + 'option-type' + '?type=' + type;
//     const config = configHeaderAxios();
//     axios.get(urlcall, config)
//         .then(res => {
//             if (callback != null) {
//                 callback(res);
//             }
//         })
//         .catch(err => {
//         })
// }

function errorResponse(error) {
  toast.error(error);
  if (error.response.status === 409) {
    let errorData = error.response.data;
    if (errorData) {
      console.log(errorData);
      var errors = Object.values(errorData);
      if (errors) {
        console.log(errors);
        errors.forEach((err) => {
          toast.error(humanize(err));
        });
      }
    }
  } else if (error.response.status === 401) {
    let errorData = error.response.data;
    toast.error(errorData);
    localStorage.clear();
    window.location.href = "/login";
  } else if (error.response.data.message) {
    toast.error(error.response.data.message);
    localStorage.clear();
    window.location.href = "/login";

  }
}
//success messages
function successResponse(response) {
  toast.success(response);
  if (response.status === 200) {
    if (response.data.message) {
      toast.success(response.data.message);
    }
  }
}

export {
  errorResponse,
  successResponse,
  isError,
  configHeaderAxios,
  customStylesDataTable,
};
