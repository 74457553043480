import React, { useState, useEffect } from "react";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Link, useNavigate } from "react-router-dom";
import url from "../../../Development.json";
import Http from "../../security/Http";
import { ToastContainer } from "react-toastify";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useForm } from "react-hook-form";
import {
  errorResponse,
  successResponse,
  isError,
} from "../../Helpers/response.js";

const Signup = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [gender, setgender] = useState("");
  const [setType, getType] = useState("");

  const navigate = useNavigate();

  const handleOnShowPassword = (ev) => {
    ev.preventDefault();
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    isError(errors);
  });
  const handleSelectgender = (ev) => {
    setgender(ev.target.value);
  };
  //Register Manager
  const onSubmit = (data) => {
    var email = data.email;
    data["device"] = "3";
    data["username"] = data["email"];
    data["pushToken"] = "test";
    data["mobile"] = data["phone_no"];
    data["usertype"] = 3;
    Http.callApi(url.manager_register, JSON.stringify(data))
      .then(() => {
        setTimeout(() => {
          navigate("/account/verify", {
            state: {
              mobile: data["phone_no"],
            },
          });
        }, 2000);
      })
      .catch((error) => {
        if (error.response.data.errors) {
          let arr = error.response.data.errors;
          Object.values(arr).map((item) => {
            errorResponse(item);
          });
        }
        if (!error) {
          errorResponse("Please Try Again!");
        }
      });
  };

  const handleChange = (event) => {
    getType(event);
  };

  return (
    <>
      <ToastContainer />
      <section className="auth_wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <div className="login_form_container">
                <h4 className="app_heading">Signup</h4>
                <div className="login_form_wrapper">
                  <form
                    className="signupBox_form"
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="row mt-4">
                      <div className="col-md-6">
                        <div className="app_textInputWrapper">
                          <input
                            id="firstname"
                            className="app_textInput"
                            type="text"
                            placeholder=" "
                            {...register("firstname", {
                              required: "First name required",
                            })}
                          />
                          <label
                            htmlFor="firstname"
                            className="appInput_Placeholder"
                          >
                            First Name
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="app_textInputWrapper">
                          <input
                            id="lastname"
                            className="app_textInput"
                            type="text"
                            placeholder=" "
                            {...register("lastname", {
                              required: "Last name required",
                            })}
                          />
                          <label
                            htmlFor="lastname"
                            className="appInput_Placeholder"
                          >
                            Last Name
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col">
                        <div className="app_textInputWrapper">
                          <input
                            id="email"
                            className="app_textInput"
                            type="email"
                            placeholder=" "
                            {...register("email", { required: true })}
                          />
                          <label
                            htmlFor="email"
                            className="appInput_Placeholder"
                          >
                            Email Address
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col">
                        <div className="app_textInputWrapper">
                          <input
                            id="phone_no"
                            className="app_textInput"
                            type="number"
                            placeholder=" "
                            {...register("phone_no", {
                              required: "Mobile number is Required",
                              minLength: {
                                value: 8,
                                message: "Minimum 8 digit is Required",
                              },
                              maxLength: {
                                value: 14,
                                message: "Maximum 14 digit is Required",
                              },
                            })}
                          />
                          <label
                            htmlFor="phone_no"
                            className="appInput_Placeholder"
                          >
                            Phone number
                          </label>
                        </div>
                      </div>
                    </div>
                    {setType == "employee" ? (
                      <div className="row mt-4">
                        <div className="col">
                          <div className="app_textInputWrapper">
                            <input
                              id="manager_code"
                              className="app_textInput"
                              type="text"
                              placeholder=" "
                              {...register("manager_code", {
                                required: "Manager code is required",
                              })}
                            />
                            <label
                              htmlFor="manager_code"
                              className="appInput_Placeholder"
                            >
                              Manager Code
                            </label>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="row mt-4">
                      <div className="col">
                        <div className="app_textInputWrapper">
                          <input
                            id="password"
                            className="app_textInput"
                            type={showPassword ? "text" : "password"}
                            placeholder=" "
                            {...register("password", {
                              required: true,
                              maxLength: {
                                value: 15,
                                message: "maximum length is 15",
                              },
                              minLength: {
                                value: 6,
                                message: "minimum length is 6",
                              },
                            })}
                          />
                          <label
                            htmlFor="password"
                            className="appInput_Placeholder"
                          >
                            Password
                          </label>
                          <button
                            className="show_password_btn"
                            onClick={(ev) => handleOnShowPassword(ev)}
                          >
                            <VisibilityOutlinedIcon
                              style={{
                                color: showPassword ? "#000" : "#a6a9b3",
                              }}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="app_text_black login_page_signup_link_row">
                      <p>
                        By signing up to Mind Pulse, you agree to our{" "}
                        <a
                          style={{ textDecoration: "unset", color: "#5153f5" }}
                          href="https://app.websitepolicies.com/policies/view/uhq1gm26"
                        >
                          Terms & Conditions
                        </a>{" "}
                        and our{" "}
                        <a
                          style={{ textDecoration: "unset", color: "#5153f5" }}
                          href="https://app.websitepolicies.com/policies/view/deu50268"
                        >
                          Privacy Policy
                        </a>
                      </p>
                    </div>
                    <button className="app_primary_btn w-100 mt-4">
                      Signup
                    </button>
                    <div className="app_text_black login_page_signup_link_row">
                      <span>Already have an account?</span>
                      <Link
                        to="/login"
                        className="app_primary_text_bold app_link login_page_signup_link"
                      >
                        Login here
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="login_image_container"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Signup;