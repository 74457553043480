import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import RecipeCard from "../../sub-components/employeeSub/RecipeCard";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { ArrowBackIosOutlined } from "@mui/icons-material";

const EatwellCategory = () => {
  const { state } = useLocation();
  const [getEatData, setGetEatData] = useState(); //Get Eatwell data
  const navigate = useNavigate();

  useEffect(() => {
    if (state) {
      let data = JSON.parse(state);
      console.log(data);
      setGetEatData(data);
    }
  }, []);

  const viewButtonClick = () => {
    let path = "/wellness/eatwell";
    navigate(path, { state });
  };

  return (
    <>
      <div className="emp_home_wrapper" style={{ height: "100%" }}>
        <div className="container py-5">
          <div className="col-12 col-md-10 col-lg-10 mx-auto">
            <button
              className="app_primary_text_bold app_link app_blank_btn"
              style={{ width: 'unset', marginBottom: 8, }}
              onClick={(e) => {
                viewButtonClick();
              }}
            >
              <ArrowBackIosOutlined
                style={{
                  color: "#5153F5",
                  fontSize: 14,
                  marginRight: 8,
                }}
              />
              Back
            </button>
            <h4 className="app_heading mb-5">{getEatData?.name}</h4>
            <div
              className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 g-4"
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              {getEatData?.recipes?.map((recipe) => (
                <RecipeCard key={recipe.id} recipe={recipe} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EatwellCategory;
