import React, { useEffect, useState } from "react";
import url from "../../../../Development.json";
import { errorResponse, successResponse } from "../../../Helpers/response";
import Http from "../../../security/Http";
import { useNavigate } from "react-router-dom";

const DecreaseMoodModal = ({ userData }) => {
  const navigate = useNavigate();
  return (
    <div
      className="modal fade"
      id="decreasedMoodModal"
      tabIndex="-1"
      aria-labelledby="decreasedMoodModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content" style={{ borderRadius: "12px" }}>
          <div
            className="modal-body d-flex flex-column"
            style={{ borderRadius: "12px" }}
          >
            <h4 className="text-center app_text_20_bold my-2">
              We've noticed you've had a few bad days {userData.firstname}
            </h4>

            <p className="text-center my-2" style={{ fontSize: 14 }}>
              A notification has been sent to your support person.
            </p>

            <p className="text-center my-2" style={{ fontSize: 14 }}>
              In the meantime, we recommend you take control of your wellbeing
              and head to Wellness.
            </p>

            <button
              className="app_primary_btn my-3"
              data-bs-dismiss="modal"
              onClick={() => {
                navigate("/wellness");
              }}
            >
              Head to Wellness
            </button>
            <button
              className="app_secondary_btn"
              data-bs-toggle="modal"
              data-bs-target="#decreasedMoodConfirmModal"
              onClick={() => {
                navigate("/employee");
              }}
            >
              Head Home
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DecreaseMoodModal;
