import React from 'react'
import QuestionTypeSelector from './QuestionTypeSelector';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CreateAnswers from './CreateAnswers';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

const CreateQueBoilerPlateCard = ({ queBoilerPlateId, selectedQueType, onTypeSelect, boilerPlateQueText, onQueTextChange, boilerPlateAnswersSet, boilerPlateAnswersSetLen, boilerPlateAnswerOnChange, boilerPlateAnswerOnAdd, boilerPlateAnswerOnRemove, onBoilerPlateRemove,question }) => {
    return (
        <div className='app_border_card my-4 flex-column'>
            <div className='d-flex justify-content-between w-100 mb-4'>
                <QuestionTypeSelector boilerPlateId={queBoilerPlateId} selectedQueType={selectedQueType} onTypeSelect={onTypeSelect} />
                <button className='app_blank_btn' onClick={() => onBoilerPlateRemove(queBoilerPlateId)} ><DeleteOutlineOutlinedIcon /></button>
            </div>
            <div className='d-flex flex-column'>
                <h4 className='app_text_bold mb-3'>Question</h4>
                <input className='create_que_input' onChange={(ev) => onQueTextChange(ev, queBoilerPlateId)} value={boilerPlateQueText} type="text" placeholder='Question' />
                {/* <input className='app_textInput' type="text" id="firstname" placeholder=' '  {...register('firstname', { required: true })} /> */}
            </div>
            {selectedQueType !== 'text' && <div className='d-flex flex-column mt-4'>
                <h4 className='app_text_bold mb-3'>Answer(s)</h4>
                <div className='row row-cols-1 g-3'>
                    {boilerPlateAnswersSet.map((item, index) => <CreateAnswers key={item.answerId} boilerPlateId={queBoilerPlateId} answerId={item.answerId} answersSetLength={boilerPlateAnswersSetLen} answerText={item.answerText} onChange={boilerPlateAnswerOnChange} answerIndex={index + 1} onRemoveAnswer={boilerPlateAnswerOnRemove} />)}
                </div>
                <button className='app_blank_btn app_primary_text_bold mt-4' onClick={() => boilerPlateAnswerOnAdd(queBoilerPlateId)} ><AddCircleOutlineOutlinedIcon /><span className='app_primary_text_bold ms-2'>Add Answer</span></button>
            </div>}
        </div>
    )
}

export default CreateQueBoilerPlateCard