import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import EmployeeCard from "../../sub-components/managerEmpSub/EmployeeCard"

import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Http from "../../security/Http";
import url from "../../../Development.json";
import { errorResponse } from "../../Helpers/response";
import SupportPersonCard from "../../sub-components/supportPeopleCard";
import AddSupportPersonModal from "./SupportPeopleModal";

const SupportPeople = () => {
    const [supportPeople, setSupportPeople] = useState([]);

    useEffect(() => {
        getSupportPeople()
    }, []);

    const getSupportPeople = () => {
        Http.callApi(url.get_support_people)
            .then((response) => {
                console.log(response);
                setSupportPeople(response.data);
            }).catch((error) => {
                if (error.response) {
                    errorResponse(error);
                }
            });
    }

    const deleteSupportPerson = (id) => {
        console.log(id);
        const data = {
            'person': id
        }
        console.log(data);
        Http.callApi(url.delete_support_people, data)
            .then((response) => {
                console.log(response);
                getSupportPeople();
            }).catch((error) => {
                if (error.response) {
                    errorResponse(error);
                }
            });
    }

    const addSupportPerson = (value) => {
        console.log(value);

        const data = {
            'person': value
        }
        console.log(data);
        Http.callApi(url.add_support_people, data)
            .then((response) => {
                console.log(response);
                getSupportPeople();
            })
            .catch((error) => {
                if (error.response) {
                    errorResponse(error);
                }
            });
    }

    return (
        <>
            <div className="container">
                <header className="que_active_header">
                    <div className="mb-4 mb-lg-0">
                        <h4 className="app_heading_24_bold text-nowrap">
                            Support People
                        </h4>
                    </div>
                    <div className="d-flex justify-content-between justify-content-lg-end w-100">
                        <div className="app_searchInputWrapper me-4">
                            <span className="app_search_icon_wrapper">
                                <SearchOutlinedIcon style={{ fontSize: 20 }} />
                            </span>
                        </div>
                        <button
                            type="button"
                            className="app_primary_btn create_que_btn"
                            data-bs-toggle="modal"
                            data-bs-target="#supportPersonModal"
                        >
                            <AddCircleOutlineOutlinedIcon />
                            <span className="add_btn_with_icon_text">Add a Support Person</span>
                        </button>
                    </div>
                </header>

                <div className="row row-cols-1 gy-4">
                    {supportPeople.length !== 0 ? (
                        supportPeople.map((Emp) => (
                            <SupportPersonCard
                                key={Emp._id}
                                empId={Emp._id}
                                item={Emp}
                                empFirstname={Emp.firstname}
                                empLastname={Emp.lastname}
                                empCompanyposition={Emp.company_position}
                                empMobile={Emp.mobile}
                                empEmail={Emp.email}
                                empGender={Emp.gender}
                                empImage={Emp.profile_image}
                                deleteSupportPerson={deleteSupportPerson}
                            />
                        ))
                    ) : (
                        <span className="validation_text_invalid">
                            You currently have no Support People
                        </span>
                    )}
                </div>
            </div>

            <AddSupportPersonModal onSubmit={addSupportPerson} />
        </>
    );
}

export default SupportPeople;