import React, { useState } from "react";

const AddSupportPersonModal = (props) => {
    const [supportPerson, setSupportPerson] = useState("");

    const submitChange = () => {
        props.onSubmit(supportPerson);
    }
    return (
        <>
            <div
                className="modal fade"
                id="supportPersonModal"
                tabIndex="-1"
                aria-labelledby="addSupportPersonModal"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-sm">
                    <div className="modal-content">
                        <div className="modal-body d-flex flex-column">
                            <h4 className="text-center my-2" style={{ fontSize: 20 }}>
                                Add a Support Person
                            </h4>
                            <p style={{ fontSize: 14, textAlign: 'center' }}>Please enter your Support Person's code to add them as a Nominated Support Contact</p>
                            <div className="app_textInputWrapper mb-2">
                                <input
                                    type="text"
                                    id="supportCode"
                                    className="app_textInput"
                                    placeholder="Enter Support Person Code"
                                    onChange={(value) => setSupportPerson(value.target.value)}
                                />
                            </div>
                            <button
                                className="app_primary_btn"
                                data-bs-dismiss="modal"
                                onClick={submitChange}
                            >
                                Add Support Person
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddSupportPersonModal;
