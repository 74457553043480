import React, { useState, useEffect } from "react";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import eadPlaceholder from "../../../assets/images/ead_placeholder.png";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { Link } from "react-router-dom";
import EADOptionModal from "../../sub-components/employeeSub/EADOptionModal";
import url from "../../../Development.json";
import Http from "../../security/Http";
import { errorResponse } from "../../Helpers/response.js";
import AppLoader from "../../sub-components/AppLoader";

const EADOption = (props) => {
  let eadImgSrc = props.eadImg ? props.eadImg : eadPlaceholder;
  return (
    <div className="col">
      <div className="ead_option_row d-flex justify-content-between">
        <Link
          role="button"
          onClick={() => props.onSelectFunc(props.item)}
          data-bs-toggle="modal"
          data-bs-target="#eadOptionModal"
          to=""
          className="d-flex align-items-center text-decoration-none"
        >
          <div className="ead_option_img_container">
            <img
              src={props.item.image}
              alt="ead"
              className="img-fluid"
              style={{ height: 96, width: 156, objectFit: "contain" }}
            />
          </div>
          <h4 className="app_text_bold ms-3">{props.item.options[0].title}</h4>
        </Link>
        <Link
          role="button"
          onClick={() => props.onSelectFunc(props.item)}
          data-bs-toggle="modal"
          data-bs-target="#eadOptionModal"
          to=""
          className="d-flex align-items-center text-decoration-none"
        >
          <ArrowForwardIosOutlinedIcon
            style={{ fontSize: 20, color: "#2B2744" }}
          />
        </Link>
      </div>
    </div>
  );
};

const EmployeeEAD = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [getEadList, SetEadList] = useState([]); //Get Ead List
  const [featuredSupport, setFeaturedSupport] = useState(null);
  const [empProfileData, setEmpProfileData] = useState([]);

  const getEadData = () => {
    setIsLoading(true);
    Http.callApi(url.get_eap)
      .then((response) => {
        var data = response.data;

        if (data) {
          for (let i = 0; i < data.length; i++) {
            if (data[i].featured === true) {
              console.log("Found:", data[i]);
              setFeaturedSupport(data[i]);
              data.splice(i, 1);
              break; // stop the loop once the item is found
            }
          }
        }
        console.log(data);
        SetEadList(data);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.message) {
          errorResponse(error.message);
        } else {
          errorResponse("Please Try Again!");
        }
      });
  };

  useEffect(() => {
    profileData();
    getEadData();
  }, []);

  const [selectedEADOption, setSelectedEADOPtion] = useState(null);

  const getSelectedEADId = (eadItem) => {
    setSelectedEADOPtion(eadItem);
  };

  const profileData = () => {
    Http.callApi(url.user_profile)
      .then((response) => {
        console.log(response);
        setEmpProfileData(response.data);
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        <>
          {selectedEADOption ? (
            <EADOptionModal showEADItem={selectedEADOption} />
          ) : (
            <></>
          )}
          <div
            className="emp_home_wrapper"
            style={{
              height: "100%",
              background: `linear-gradient(45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 45%, ${
                empProfileData.company
                  ? empProfileData.company.company_primary_color
                  : "rgba(81, 83, 245, 1)"
              } 150%)`,
            }}
          >
            <div className="container py-5 d-flex justify-content-center">
              <div className="col-12 col-md-10 col-lg-8">
                <>
                  <div className="d-flex justify-content-center align-items-center">
                    <h4 className="app_heading">Support Connect</h4>
                  </div>
                  <div className="w-100" style={{ marginTop: 32 }}>
                    {featuredSupport ? (
                      <div
                        className="ead_head_img_container text-center mb-5 row d-flex"
                        style={{ justifyContent: "center" }}
                      >
                        <img
                          className="img-fluid"
                          style={{
                            height: 172,
                            width: 272,
                            objectFit: "contain",
                          }}
                          src={featuredSupport.image}
                          alt="Featured Provider"
                        />
                        <h4 className="app_text_bold ms-3 mb-4">
                          {featuredSupport.options[0].title}
                        </h4>
                        {featuredSupport ? (
                          <div className="app_primary_btn">
                            <LocalPhoneOutlinedIcon />
                            <span className="app_text ms-2 text-white">
                              {featuredSupport.mobile}
                            </span>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="row row-cols-1 g-4">
                      {getEadList?.map((item) => (
                        <EADOption
                          key={item.id}
                          item={item}
                          onSelectFunc={getSelectedEADId}
                        />
                      ))}
                    </div>
                  </div>
                </>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EmployeeEAD;
