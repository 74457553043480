import React from "react";
import { Link } from "react-router-dom";

const QueSubmitConfirmation = () => {
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "600px", flexDirection: "column" }}
    >
      <img
        src={require("../../../assets/lottieJson/verified.gif")}
        width={192}
        height={192}
        style={{ marginBottom: "24px" }}
      />
      <p style={{ fontSize: 24, fontWeight: "600" }} className="mb-4">
        Thanks for submitting the questionnaire! 😊
      </p>
      <Link to="/employee" key="" className="app_primary_btn w-50 mx-auto">
        Back to Home
      </Link>
    </div>
  );
};

export default QueSubmitConfirmation;
