import React from "react";
import url from "../../../Development.json";
import Http from "../../security/Http";
import { errorResponse, successResponse } from "../../Helpers/response";
import { ToastContainer } from "react-toastify";

const QueRemoveModal = (props) => {
  //Previous and Draf Question delete
  const queDelete = () => {
    const obj = { id: props.getItem.queId };
    Http.callApi(url.delete_question, obj)
      .then(() => {
        props.getQuestionnaires();
      })
      .catch((error) => {
        errorResponse("Please Try Again!");
        if (error.response) {
          errorResponse(error);
        }
      });
    props.newDataFunc();
  };

  return (
    <>
      <ToastContainer />
      <div
        className="modal fade"
        id="queRemoveModal"
        tabIndex="-1"
        aria-labelledby="queRemoveModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div
            className="modal-content p-4"
            style={{ borderRadius: "20px", border: "unset" }}
          >
            <div className="modal-body">
              <div className="remove_que_modal_container">
                <p className="app_heading_20_bold text-center mb-5">
                  Are you sure you want to remove
                  <br />
                  {props.getItem.queTitle}?
                </p>
                <div className="d-flex w-100 justify-content-center">
                  <button
                    data-bs-dismiss="modal"
                    className="me-3 app_secondary_btn w-50"
                  >
                    Cancel
                  </button>
                  <button
                    data-bs-dismiss="modal"
                    onClick={() => queDelete()}
                    className="app_danger_btn w-50"
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QueRemoveModal;
