import React, { useState, useRef, useEffect, useCallback } from "react";
import Slider from "@mui/material/Slider";
import PauseRounded from "@mui/icons-material/PauseRounded";
import CloseIcon from "@mui/icons-material/Close";
import PlayArrowRounded from "@mui/icons-material/PlayArrowRounded";
import url from "../../../Development.json";
import Http from "../../security/Http";
import { errorResponse } from "../../Helpers/response.js";

const MindfullnessAudioPlayer = (props) => {
  const [isPlaying, setIsPlaying] = useState();
  const [currentAudio, setCurrentAudio] = useState(props.selectedAudioTrack);
  const [audioPlayerTitle, setAudioPlayerTitle] = useState("");
  const [audioDuration, setAudioDuration] = useState(0);
  const [audioPlayerCurrentTime, setAudioPlayerCurrentTime] = useState(0);
  const audioPlayer = useRef();
  const animationRef = useRef();
  const [getMindfulnessList, SetMindfulnessList] = useState([]);

  const audioPlaying = useCallback(() => {
    audioPlayer.current
      ? setAudioPlayerCurrentTime(Math.floor(audioPlayer.current.currentTime))
      : setAudioPlayerCurrentTime(0);
    animationRef.current = requestAnimationFrame(audioPlaying);
    if (audioPlayer.current && audioPlayer.current.ended) {
      setIsPlaying(false);
    }
  }, []);

  useEffect(() => {
    setIsPlaying(props.isPlaying);
    props.selectedAudioTrack
      ? setCurrentAudio(props.selectedAudioTrack)
      : setCurrentAudio("");
  }, [props, isPlaying]);

  useEffect(() => {
    Http.callApi(url.get_mindfulness)
      .then((response) => {
        SetMindfulnessList(response.data);
      })
      .catch((error) => {
        if (error.message) {
          errorResponse(error.message);
        } else {
          errorResponse("Please Try Again!");
        }
      });
  }, []);

  useEffect(() => {
    let seconds = Math.floor(audioPlayer.current.duration);
    setAudioDuration(seconds);
    setTimeout(() => {
      let seconds = Math.floor(audioPlayer.current.duration);
      setAudioDuration(seconds);
    }, 500);
  }, [audioPlayer?.current?.loadedmetadata, audioPlayer?.current?.readyState]);

  const togglePlayPause = () => {
    let prevVal = isPlaying;
    setIsPlaying(!prevVal);
    if (prevVal) {
      audioPlayer.current.pause();
      cancelAnimationFrame(animationRef.current);
      props.handleSelectedAudioOnToggle();
    } else {
      setTimeout(() => audioPlayer.current.play(), 200);
      animationRef.current = requestAnimationFrame(audioPlaying);
      props.handleSelectedAudioOnToggle();
    }
  };

  useEffect(() => {
    if (props?.isPlaying) {
      setTimeout(() => audioPlayer.current.play(), 200);
      animationRef.current = requestAnimationFrame(audioPlaying);
    } else {
      audioPlayer.current.pause();
      cancelAnimationFrame(animationRef.current);
    }
  }, [props, audioPlaying]);

  const onAudioPlayerCloseBtnClick = () => {
    setIsPlaying(false);
    props.closeFunc();
    document
      .getElementById("mindfullnessAudioPlayerId")
      .classList.remove("open");
    audioPlayer.current.pause();
    cancelAnimationFrame(animationRef.current);
    setAudioPlayerCurrentTime(0);
    audioPlayer.current.currentTime = 0;
  };

  const onAudioChange = useCallback(() => {
    setTimeout(() => audioPlayer.current.play(), 200);
    animationRef.current = requestAnimationFrame(audioPlaying);
  }, [audioPlaying]);

  const formatAudioPlayerSliderLabelDuration = (duration) => {
    let minute = Math.floor((duration % 3600) / 60);
    let second = Math.floor((duration % 60) / 1);

    if (isNaN(minute) && isNaN(second)) {
      minute = 0;
      second = 0;
    }

    return `${minute < 10 ? `0${minute}` : minute}:${
      second < 10 ? `0${second}` : second
    }`;
  };

  const sliderOnChange = (val) => {
    audioPlayer.current.currentTime = val;
    setAudioPlayerCurrentTime(val);
  };

  useEffect(() => {
    if (currentAudio) {
      const audioSrc = props.item;
      audioPlayer.current.src = audioSrc.audio;

      setAudioPlayerTitle(audioSrc.title);
      setCurrentAudio(currentAudio);
    }
  }, [audioPlayer, currentAudio, props.item]);

  useEffect(() => {
    let preVal = currentAudio;
    if (preVal !== props.selectedPodId) {
      setIsPlaying(true);
    }
    if (currentAudio) {
      onAudioChange();
    }
  }, [currentAudio, props.selectedPodId, onAudioChange]);

  return (
    <div
      id="mindfullnessAudioPlayerId"
      className="audioPlayer_wrapper"
      style={{ position: "fixed", bottom: 0, width: "100%" }}
    >
      <div className="app_container py-4" style={{ paddingBottom: 16 }}>
        <div className="row">
          <div className="audioPlayerAudio_wrapper d-flex">
            <audio id="appAudioPlayer" ref={audioPlayer} preload="metadata" />
            <div className="audioPlayerControl_wrapper">
              <button
                aria-label={isPlaying ? "play" : "pause"}
                onClick={() => togglePlayPause()}
                className="audioPlayerControl_btn"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: 48,
                  height: 48,
                  borderRadius: "50%",
                  border: 0,
                  background: isPlaying ? "#F0F2FE" : "#3b37da",
                }}
              >
                {isPlaying ? (
                  <PauseRounded
                    sx={{ fontSize: "30px" }}
                    htmlColor={isPlaying ? "#3b37da" : "#F0F2FE"}
                  />
                ) : (
                  <PlayArrowRounded
                    sx={{ fontSize: "30px" }}
                    htmlColor={"#fff"}
                  />
                )}
              </button>
            </div>
            <div
              className="audioPlayerSlider_wrapper px-4"
              style={{ width: "100%" }}
            >
              <h5 className="app_text_bold text-nowrap">
                {audioPlayerTitle
                  ? audioPlayerTitle
                  : "Select a Mindfulness exercise to play"}
              </h5>
              <Slider
                style={{ height: 5, padding: "12px 0 10px" }}
                size="small"
                value={audioPlayerCurrentTime}
                min={0}
                step={1}
                max={!isNaN(audioDuration) ? audioDuration : 0}
                onChange={(_, value) => sliderOnChange(value)}
                sx={{
                  color: "#3b37da",
                  height: 4,
                  "& .MuiSlider-thumb": {
                    width: 16,
                    height: 16,
                    transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
                    "&:before": {
                      boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
                    },
                    "&:hover, &.Mui-focusVisible": {},
                    "&.Mui-active": {
                      width: 20,
                      height: 20,
                    },
                  },
                  "& .MuiSlider-rail": {
                    opacity: 0.28,
                  },
                }}
              />
              <div className="audioPlayerSliderLabel_container d-flex">
                <div className="audioPlayerSlider_label d-flex">
                  {formatAudioPlayerSliderLabelDuration(audioPlayerCurrentTime)}
                </div>
                <div style={{ flex: 1 }} />
                <div className="audioPlayerSlider_label d-flex">
                  {formatAudioPlayerSliderLabelDuration(audioDuration)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MindfullnessAudioPlayer;
