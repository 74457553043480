import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import url from "../../../Development.json";
import Http from "../../security/Http";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import RecipeCard from "../../sub-components/employeeSub/RecipeCard";
import { errorResponse } from "../../Helpers/response.js";
import AppLoader from "../../sub-components/AppLoader";

const EmployeeEatWell = () => {
  const [getEatList, SetGetEatList] = useState([]); //Get Eatwell list
  const [pageTitle, setPageTitle] = useState("");
  const [empProfileData, setEmpProfileData] = useState([]);
  const navigate = useNavigate();

  // Define a custom comparator function
  const compareItems = (a, b) => {
    if (a.position < b.position) {
      return -1;
    }
    if (a.position > b.position) {
      return 1;
    }
    return 0;
  };

  const profileData = () => {
    Http.callApi(url.user_profile)
      .then((response) => {
        console.log(response);
        setEmpProfileData(response.data);
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  const getEatData = () => {
    Http.callApi(url.get_eatwell)
      .then((response) => {
        var data = response.data;

        console.log(data);

        // Sort the items within each object in the data array
        data.forEach((obj) => {
          obj.recipes.sort(compareItems);
        });
        console.log(data);

        SetGetEatList(data);
      })
      .catch((error) => {
        if (error.message) {
          errorResponse(error.message);
        } else {
          errorResponse("Please Try Again!");
        }
      });
  };

  const getPageTitle = () => {
    const data = {
      page: "eat_well",
    };
    Http.callApi(url.get_page_title, data)
      .then((response) => {
        setPageTitle(response.data.heading);
      })
      .catch((error) => {
        if (error.message) {
          errorResponse(error.message);
        } else {
          errorResponse("Please Try Again!");
        }
      });
  };

  useEffect(() => {
    profileData();
    getEatData();
    getPageTitle();
  }, []);

  const viewButtonClick = (data) => {
    navigate("/wellness/eatwell/all", {
      state: JSON.stringify(data),
    });
  };

  return (
    <>
      <div
        className="emp_home_wrapper"
        style={{
          height: "100%",
          background: `linear-gradient(45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 45%, ${
            empProfileData.company
              ? empProfileData.company.company_primary_color
              : "rgba(81, 83, 245, 1)"
          } 150%)`,
        }}
      >
        <div className="container py-5">
          <div className="col-12 col-md-10 col-lg-10 mx-auto">
            <h4 className="app_heading justify-content-center align-items-center">
              {pageTitle ? pageTitle : "Eat well 🍎"}
            </h4>
            <div className="row mt-5">
              {getEatList.map((eat) => (
                <div className="d-flex flex-column">
                  <div
                    className="d-flex justify-content-between align-items-center"
                    style={{ marginBottom: 32 }}
                  >
                    <h4 className="app_heading_24_bold">{eat.name}</h4>
                    <div style={{ flex: 1 }} />
                    <button
                      className="app_primary_text_bold app_link app_blank_btn"
                      style={{ width: 'unset' }}
                      onClick={(e) => {
                        viewButtonClick(eat);
                      }}
                    >
                      View All {eat.name} Items
                      <ArrowForwardIosOutlinedIcon
                        style={{
                          color: "#5153F5",
                          fontSize: 14,
                          marginLeft: 8,
                        }}
                      />
                    </button>
                  </div>
                  <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-xl-4 g-4">
                    {eat.recipes.map((subItem, index) => {
                      if (index < 4) {
                        return <RecipeCard key={subItem.id} recipe={subItem} />;
                      }
                    })}
                  </div>
                  <div className="" style={{ margin: "32px 0" }} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeEatWell;
