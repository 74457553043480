import React from "react";
import url from "../../../Development.json";
import Http from "../../security/Http";
import { errorResponse, successResponse } from "../../Helpers/response";
import { ToastContainer } from "react-toastify";

const EmpRemoveModal = (props) => {
  //Employee delete
  const empDelete = () => {
    const obj = { employee_id: props.empItem.empId };
    Http.callApi(url.delete_employee, obj)
      .then((response) => {
        props.onDelete();
      })
      .catch((error) => {
        errorResponse("Please Try Again!");
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  return (
    <>
      <ToastContainer />
      <div
        className="modal fade"
        id="empRemoveModal"
        tabIndex="-1"
        aria-labelledby="empRemoveModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div
            className="modal-content p-4"
            style={{ border: "unset", borderRadius: "20px" }}
          >
            <div className="modal-body">
              <div className="remove_que_modal_container">
                <p className="app_heading_20_bold text-center mb-5">
                  Are you sure you want to remove <br />
                  {props.empItem.empFirstname} {props.empItem.empLastname} from
                  your team?
                </p>
                <div className="d-flex w-100 justify-content-center">
                  <button
                    data-bs-dismiss="modal"
                    className="me-3 app_secondary_btn w-50"
                  >
                    Cancel
                  </button>
                  <button
                    data-bs-dismiss="modal"
                    onClick={() => empDelete()}
                    className="app_danger_btn w-50"
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmpRemoveModal;
