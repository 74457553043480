import { Steps } from "antd";
import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { EATWellTestAPI } from "../../../TestStorage/testAPI";
import { ArrowBackIosOutlined } from "@mui/icons-material";

const { Step } = Steps;

const RecipeDetail = () => {
  const { state } = useLocation();
  const [getData, setGetData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    getStateData();
  }, []);

  const getStateData = () => {
    console.log(state);
    let data = JSON.parse(state);
    console.log(data.recipe);
    setGetData(data.recipe);
    setIsLoading(false);
  };

  const [current, setCurrent] = useState(0);
  const onChange = (value) => {
    setCurrent(value);
  };

  const viewButtonClick = () => {
    let path = "/wellness/eatwell";
    navigate(path, { state });
  };

  return !isLoading ? (
    <div className="container py-5">
      <div className="col-12 col-md-10 col-lg-8 col-xl-8 mx-auto d-grid justify-content-center">
        <button
          className="app_primary_text_bold app_link app_blank_btn"
          style={{ width: 'unset', marginBottom: 8, }}
          onClick={(e) => {
            viewButtonClick();
          }}
        >
          <ArrowBackIosOutlined
            style={{
              color: "#5153F5",
              fontSize: 14,
              marginRight: 8,
            }}
          />
          Back
        </button>
        <h4 className="app_heading mb-4">{getData.title}</h4>
        <div
          className="recipe_detail_img_container"
          style={{ borderRadius: "12px" }}
        >
          <img
            src={getData.image}
            className="img-fluid"
            style={{
              borderRadius: "12px",
              width: "100%",
              height: "50vh",
              objectFit: "cover",
            }}
            alt="recipe"
          />
        </div>

        <h4 className="app_heading_20_bold my-4">Ingredients</h4>
        {getData.ingredients.map((ingredient) => {
          return (
            <div>
              <div
                className="align-items-center py-2"
                style={{ display: "flex", flex: 1, flexDirection: "row" }}
              >
                <div
                  style={{
                    backgroundColor: "blue",
                    width: "12px",
                    height: "12px",
                    borderRadius: "50px",
                    marginBottom: "0px",
                  }}
                />
                <p style={{ marginBottom: "0px", marginLeft: "8px" }}>
                  {ingredient.ingredients}
                </p>
              </div>
            </div>
          );
        })}

        <h4 className="app_heading_20_bold my-4">Method</h4>
        {getData.description.map((method) => {
          return (
            <div>
              <div
                className="align-items-center py-2"
                style={{ display: "flex", flex: 1, flexDirection: "row" }}
              >
                <p style={{ marginBottom: "0px" }}>
                  {method.description.split("\n").map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </p>
              </div>
            </div>
          );
        })}

        <h4 className="app_heading_20_bold my-4">Nutritional Value</h4>
        <div>
          <div
            className="align-items-center py-2"
            style={{ display: "flex", flex: 1, flexDirection: "row" }}
          >
            <p style={{ marginBottom: "0px" }}>
              {getData.nutritional_value.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </p>
          </div>
        </div>
        <h4 className="app_heading_20_bold my-4">Health Fact</h4>
        <p style={{ marginBottom: "0px" }}>
          {getData.health_fact.split("\n").map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </p>
      </div>
    </div>
  ) : null;
};

export default RecipeDetail;
