import React, { useEffect, useRef, useState } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useLocation } from "react-router-dom";
import Http from "../../../security/Http";
import url from "../../../../Development.json";
import { errorResponse } from "../../../Helpers/response";
import moodSad from "../../../../assets/images/moods/ic_sad.png";
import moodUnhappy from "../../../../assets/images/moods/ic_unhappy.png";
import moodOkay from "../../../../assets/images/moods/ic_okay.png";
import moodHappy from "../../../../assets/images/moods/ic_happy.png";
import moodElated from "../../../../assets/images/moods/ic_elated.png";
import userPlaceholder from "../../../../assets/images/users/user-vector.png";

const ManagerViewResponse = () => {
  const [selectedResponder, setSelectedResponder] = useState("");
  const viewResponseLeftWrapperRef = useRef(null);
  const viewResponseRightWrapperRef = useRef(null);
  const responderBtnCardRef = useRef();
  const location = useLocation();
  const { questionId } = location.state; //Get Question Id
  const [getEmpList, SetEmpList] = useState([]);
  const [getQuelist, SetQueList] = useState([]);

  const handleResponseOnClick = (resId) => {
    const obj = { id: location.state.questionId, userid: resId };
    Http.callApi(url.get_emp_answer, obj)
      .then((response) => {
        console.log(response.data);
        SetQueList(response.data);

        setSelectedResponder(resId);
        viewResponseLeftWrapperRef.current.classList.add("hide");
        viewResponseRightWrapperRef.current.classList.add("show");
      })
      .catch((error) => {
        if (error.message) {
          errorResponse(error.message);
        } else {
          errorResponse("Please Try Again!");
        }
      });
  };

  const handleBackToRespondersOnClick = () => {
    viewResponseLeftWrapperRef.current.classList.remove("hide");
    viewResponseRightWrapperRef.current.classList.remove("show");
  };

  const resEmployee = () => {
    console.log("DATA TO SEND: ", location.state.questionId);
    const obj = { id: location.state.questionId };
    Http.callApi(url.get_answer, {
      id: location.state.questionId,
    })
      .then((response) => {
        console.log(response);
        SetEmpList(response.data);
      })
      .catch((error) => {
        if (error.message) {
          errorResponse(error.message);
        } else {
          errorResponse("Please Try Again!");
        }
      });
  };

  const downloadCSV = () => {
    console.log("Downloading CSV");
    console.log("Sending CSV Data Request: ", questionId);

    Http.callApi(url.download_questionnaire_csv, {
      questionId: questionId,
    })
      .then((response) => {
        console.log(response);
        window.location.href = response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    resEmployee();
  }, []);

  return (
    <div className="container-fluid view_response_wrapper">
      <div className="row position-relative">
        <div
          ref={viewResponseLeftWrapperRef}
          className="col-lg-5 view_response_left_wrapper"
        >
          <div className="view_response_left_container">
            <h4 className="app_text_20_semi_bold mb-2">
              Questionnaire Responses
            </h4>
            <p style={{ fontWeight: "600", fontSize: 14 }} className="mb-2">
              <span style={{ fontWeight: "400", fontSize: 14 }}>Viewing: </span>
              {getEmpList[0]?.title}
            </p>
            <p style={{ fontWeight: "600", fontSize: 14 }} className="mb-2">
              <span style={{ fontWeight: "400", fontSize: 14 }}>
                Number of Responses:{" "}
              </span>
              {getEmpList.length}
            </p>
            <button
              className="app_primary_btn my-1 mb-4"
              data-bs-dismiss="modal"
              onClick={() => downloadCSV()}
            >
              Export Responses
            </button>
            <div className="app_searchInputWrapper w-100">
              <span className="app_search_icon_wrapper">
                <SearchOutlinedIcon style={{ fontSize: 20 }} />
              </span>
              <input type="text" placeholder="Search by name" />
            </div>
            <div className="view_response_responders_wrapper">
              <div className="row row-cols-1 g-4">
                {getEmpList.length !== 0 ? (
                  getEmpList.map((item, index) => {
                    return (
                      <div className="col" key={item && item.employee ? item.employee._id : ""}>
                        <button
                          id={`${item && item.employee ? item.employee._id : ""}Btn`}
                          ref={responderBtnCardRef}
                          onClick={() =>
                            handleResponseOnClick(item && item.employee ? item.employee._id : "")
                          }
                          className={`app_card responder_card ${
                            selectedResponder === item && item.employee ? item.employee._id : ""
                              ? "active"
                              : ""
                          } `}
                        >
                          <div className="responder_user_container">
                            <div className="responder_user_detail_container">
                              <h4 className="app_text_bold text-start mb-1">
                                Submission {index + 1}
                              </h4>
                            </div>
                          </div>
                          <div className="d-flex align-items-center ms-auto">
                            <h4 className="app_text d-flex align-items-center text-nowrap">
                              <span className="app_text_bold me-1">4</span>
                              <span className="text-muted fw-boler">of 4</span>
                              <CheckCircleIcon
                                style={{
                                  color: "#27AE60",
                                  marginLeft: 5,
                                  fontSize: 20,
                                }}
                              />
                            </h4>
                          </div>
                        </button>
                      </div>
                    );
                  })
                ) : (
                  <span className="validation_text_invalid text-center">
                    No responses have been received as of yet
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          ref={viewResponseRightWrapperRef}
          className="col-lg-7 view_response_right_wrapper"
        >
          {!selectedResponder ? (
            <div className="view_response_placeholder">
              <QuestionAnswerOutlinedIcon style={{ fontSize: 140 }} />
              <h4
                className="app_heading_24_bold mt-3"
                style={{ color: "grey" }}
              >
                Select the responder to view their answers
              </h4>
            </div>
          ) : (
            <div className="view_response_right_container">
              <h4 className="app_heading_20_bold mb-4">
                <span>Answers</span>
                <button
                  onClick={() => handleBackToRespondersOnClick()}
                  className="app_blank_btn app_primary_text_bold ms-auto text-decoration-underline d-flex d-lg-none"
                >
                  Back to responses
                </button>
              </h4>
              {getQuelist != null &&
                getQuelist.answer.question.map((value, index) => {
                  console.log(value);
                  return (
                    <>
                      {value.type == 0 ? (
                        <>
                          <div className="view_response_que_wrapper">
                            <h4 className="app_text_16_bold">{value.title}</h4>
                            <div className="view_response_answer_wrapper flex-row">
                              <div className="mood_container">
                                <img
                                  className="img-fluid"
                                  src={
                                    value.answer == "Sad"
                                      ? moodSad
                                      : value.answer == "Unhappy"
                                      ? moodUnhappy
                                      : value.answer == "Okay"
                                      ? moodOkay
                                      : value.answer == "Happy"
                                      ? moodHappy
                                      : value.answer == "Elated"
                                      ? moodElated
                                      : "No image Found"
                                  }
                                  alt="mood"
                                />
                              </div>
                              <h4 className="app_text ms-3 d-flex align-items-center text-capitalize">
                                {value.answer}
                              </h4>
                            </div>
                          </div>
                          <hr className="app_divider view_response_divider" />
                        </>
                      ) : value.type == 1 ? (
                        <>
                          <div className="view_response_que_wrapper">
                            <h4 className="app_text_16_bold">{value.title}</h4>
                            <div className="view_response_answer_wrapper">
                              <h4 className="app_text">{value.answer}</h4>
                            </div>
                          </div>
                          <hr className="app_divider view_response_divider" />
                        </>
                      ) : value.type == 2 ? (
                        <>
                          <div className="view_response_que_wrapper">
                            <h4 className="app_text_16_bold">{value.title}</h4>
                            <div className="view_response_answer_wrapper">
                              <div className="row row-cols-1 g-2">
                                {value.answer &&
                                  value.answer.map((answer, index) => {
                                    return (
                                      <div key={index} className="col">
                                        <h4 className="app_text">
                                          <span className="app_text_bold me-2">{`Option ${
                                            index + 1
                                          }:`}</span>
                                          {answer}
                                        </h4>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                          <hr className="app_divider view_response_divider" />
                        </>
                      ) : (
                        <>
                          <div className="view_response_que_wrapper">
                            <h4 className="app_text_16_bold">{value.title}</h4>
                            <div className="view_response_answer_wrapper">
                              <p className="app_text">{value.answer}</p>
                            </div>
                          </div>
                          <hr className="app_divider view_response_divider" />
                        </>
                      )}
                    </>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManagerViewResponse;
