import React from 'react'

const TextQue = (props) => {
    let queTitle = props.queTitle;
    return (
        <div className='quick_view_que_modal_que_container'>
            <h4 className='app_text_bold mb-3'>{queTitle}</h4>
            <input type="text" className="quick_view_que_modal_text_input" placeholder='Text Answer from Employees' />
        </div>
    )
}

export default TextQue