import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import userPlaceholder from "../../../../assets/images/users/user-vector.png";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
// import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
// import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import url from "../../../../Development.json";
import { useForm } from "react-hook-form";
import {
  errorResponse,
  successResponse,
  isError,
} from "../../../Helpers/response";
import Http from "../../../security/Http";
import { ToastContainer } from "react-toastify";

const ManagerCreateEmployeeProfile = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [gender, setgender] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [toggleDD, setToggleDD] = useState(false);
  // const [selectedEmpPos, setSelectedEmpPos] = useState('Select Position');
  const empPosDDRef = useRef(null);
  const empPosDDBtnRef = useRef(null);
  const [image, setImage] = useState("");
  const [getMngId, setMngIdData] = useState([]); //Get manager Id

  const previewUserImageOnChange = (ev) => {
    const userImg = document.getElementById("userProfileImg");
    userImg.src = URL.createObjectURL(ev.target.files[0]);
    userImg.onload = function () {
      URL.revokeObjectURL(this.src);
    };

    const image = new FormData();
    image.append("file", ev.target.files[0]);
    Http.callApi(url.upload_image, image)
      .then((res) => {
        setImage(res.data.file_url);
      })
      .catch((error) => {
        errorResponse("Please Try Again!");
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  const profileData = () => {
    Http.callApi(url.user_profile)
      .then((response) => {
        setMngIdData(response.data);
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };
  useEffect(() => {
    profileData();
  }, []);

  // const showEmpPosDropDown = (e) => {
  //     e.preventDefault();
  //     const empPosDDIcon = document.getElementsByClassName("app_dd_arrowIcon")[0];
  //     if (empPosDDRef.current.classList.contains("show")) {
  //         empPosDDRef.current.classList.remove("show")
  //         empPosDDIcon.classList.remove("open")
  //     } else {
  //         empPosDDRef.current.classList.add("show")
  //         empPosDDIcon.classList.add("open");
  //     }
  //     setToggleDD(!toggleDD);
  // }

  // const handleEmpPosOptionOnChange = (e, pos) => {
  //     e.preventDefault();
  //     const empPosDDIcon = document.getElementsByClassName("app_dd_arrowIcon")[0];
  //     empPosDDRef.current.classList.remove("show")
  //     empPosDDIcon.classList.remove("open")
  //     setSelectedEmpPos(pos)
  // }
  useEffect(() => {
    isError(errors);
  });
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");

  useEffect(() => {
    const empPosDDIcon = document.getElementsByClassName("app_dd_arrowIcon")[0];
    if (toggleDD) {
      document.addEventListener("mousedown", (event) => {
        if (
          empPosDDRef.current &&
          !empPosDDRef.current.contains(event.target) &&
          !empPosDDBtnRef.current.contains(event.target)
        ) {
          empPosDDRef.current.classList.remove("show");
          empPosDDIcon.classList.remove("open");
          setToggleDD(false);
        }
      });
    }
  }, [toggleDD]);

  const handleOnShowPassword = (ev) => {
    ev.preventDefault();
    setShowPassword(!showPassword);
  };
  const handleOnShowConfirmPassword = (ev) => {
    ev.preventDefault();
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSelectgender = (ev) => {
    console.log(1);
    setgender(ev.target.value);
    console.log(ev.target.value);
  };

  //Create Employee profile
  const onSubmit = (data) => {
    data["device"] = 3;
    data["usertype"] = 2;
    data["username"] = { 2: data["email"], 3: data["phone_no"] };
    data["pushToken"] = "test";
    data["profile_image"] = image;
    data["gender"] = parseInt(data.gender);
    data["manager_id"] = getMngId._id;
    Http.callApi(url.create_employee, JSON.stringify(data))
      .then((response) => {
        console.log("EMPLOYEE RESPONSE: ", response);
        setTimeout(() => {
          navigate("/manager/emp");
        }, 500);
      })
      .catch((error) => {
        console.log("EMPLOYEE ERROR RESPONSE: ", error.response.data);

        if (error.response.data.message != "") {
          errorResponse(error.response.data.message);
        }

        if (error.response.data.errors) {
          let arr = error.response.data.errors;
          Object.values(arr).map((item) => {
            errorResponse(item);
          });
        }
        if (!error) {
          errorResponse("Please Try Again!");
        }
      });
  };
  return (
    <>
      <ToastContainer />
      <form auto Complete="off" onSubmit={handleSubmit(onSubmit)}>
        <header
          className="header_manager_secondary"
          style={{ paddingBottom: 32 }}
        >
          <div className="manager_create_que_header">
            <h4 className="app_heading w-100">Create Person Profile</h4>
            <div className="d-flex justify-content-lg-end mt-4 mt-lg-0 w-100">
              <button
                className="app_primary_btn me-3"
                onClick={() => {
                  navigate("/manager/emp");
                }}
              >
                Discard
              </button>
              <button type="submit" className="app_primary_btn ms-3">
                Create
              </button>
            </div>
          </div>
        </header>
        <div className="create_que_form_container container">
          <div className="col col-sm-10 col-lg-8">
            <div className="app_card">
              <h4 className="app_heading_20_bold">Profile Picture</h4>
              <div className="edituserimg_wrapper">
                <div className="edituserimg_container">
                  <img
                    id="userProfileImg"
                    src={userPlaceholder}
                    className="header_user_img"
                    alt="user"
                  />
                </div>
                <div className="userEditImgInput_wrapper">
                  <label
                    htmlFor="empProfileImgEdit"
                    className="userprofile_editImgLabel"
                  >
                    <CameraAltIcon />
                  </label>
                  <input
                    onChange={(ev) => previewUserImageOnChange(ev)}
                    type="file"
                    name="profile_image"
                    accept="image/*"
                    id="empProfileImgEdit"
                    className="userprofile_editImgInput"
                  />
                </div>
              </div>
            </div>
            <div className="app_card mt-4">
              <h4 className="app_heading_20_bold mb-4">Person Information</h4>
              <div className="row g-4">
                <div className="col-md-6">
                  <div className="app_textInputWrapper">
                    <input
                      className="app_textInput"
                      type="text"
                      id="firstname"
                      placeholder=" "
                      {...register("firstname", {
                        required: true,
                        pattern: {
                          value: /.*\S.*/,
                          message: "White space not allowed",
                        },
                      })}
                    />
                    <label className="appInput_Placeholder" htmlFor="firstname">
                      Name
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="app_textInputWrapper">
                    <input
                      className="app_textInput"
                      type="text"
                      id="lastname"
                      placeholder=" "
                      {...register("lastname", {
                        required: true,
                        pattern: {
                          value: /.*\S.*/,
                          message: "White space not allowed",
                        },
                      })}
                    />
                    <label className="appInput_Placeholder" htmlFor="lastname">
                      Last Name
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="app_textInputWrapper">
                    <input
                      className="app_textInput"
                      type="email"
                      id="email"
                      placeholder=" "
                      autoComplete={"off"}
                      {...register("email", {
                        required: true,
                        pattern: {
                          value: /.*\S.*/,
                          message: "White space not allowed",
                        },
                      })}
                    />
                    <label className="appInput_Placeholder" htmlFor="email">
                      Email
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="app_textInputWrapper">
                    <input
                      className="app_textInput"
                      type="number"
                      id="phone_no"
                      placeholder=" "
                      {...register("phone_no", {
                        required: "Mobile is Required",
                        minLength: {
                          value: 8,
                          message: "Minimum 8 digit  is Required",
                        },
                        maxLength: {
                          value: 14,
                          message: "Maximum 14 digit  is Required",
                        },
                      })}
                    />
                    <label className="appInput_Placeholder" htmlFor="phone_no">
                      Phone
                    </label>
                  </div>
                </div>

                {/* <div className='col-md-6'>
                                <div className='app_dropdown_wrapper'>
                                    <button ref={empPosDDBtnRef} className="app_dropDownBtn" onClick={(e) => showEmpPosDropDown(e)}>
                                        <div>
                                            <WorkOutlineOutlinedIcon /><span className='ms-3'>{selectedEmpPos}</span>
                                        </div>
                                        <span>
                                            <KeyboardArrowDownOutlinedIcon className='app_dd_arrowIcon' />
                                        </span>
                                    </button>
                                    <div ref={empPosDDRef} className='app_dd_list_wrapper'>
                                        <ul className='app_dd_list que_type_selector_options_menu'>
                                            <li><button className='app_blank_btn' onClick={(e) => handleEmpPosOptionOnChange(e, 'Web Developer')}><span className='ms-3'>Web Developer</span></button></li>
                                            <li><button className='app_blank_btn' onClick={(e) => handleEmpPosOptionOnChange(e, 'Android Developer')}><span className='ms-3'>Android Developer</span></button></li>
                                            <li><button className='app_blank_btn' onClick={(e) => handleEmpPosOptionOnChange(e, 'iOS Developer')}><span className='ms-3'>iOS Developer</span></button></li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}
              </div>
              {/* <div className="row mt-4">
                <h4 className="app_heading_20_bold">Gender</h4>
                <div className="d-flex flex-column flex-md-row mt-3">
                  <div className="form-check me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="gender"
                      value="1"
                      id="gender"
                      onChange={(ev) => handleSelectgender(ev)}
                      {...register("gender", {
                        required: "Gender is Required",
                      })}
                    />
                    <label className="form-check-label" htmlFor="gender">
                      Male
                    </label>
                  </div>
                  <div className="form-check me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="2"
                      name="gender"
                      id="gender"
                      onChange={(ev) => handleSelectgender(ev)}
                      {...register("gender", {
                        required: "Gender is Required",
                      })}
                    />
                    <label className="form-check-label" htmlFor="gender">
                      Female
                    </label>
                  </div>
                  <div className="form-check me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="3"
                      name="gender"
                      id="gender"
                      onChange={(ev) => handleSelectgender(ev)}
                      {...register("gender", {
                        required: "Gender is Required",
                      })}
                    />
                    <label className="form-check-label" htmlFor="gender">
                      Non Binary
                    </label>
                  </div>
                  {/* <div className="form-check me-3">
                                    <input className="form-check-input" type="radio" name="empGenderSelector" id="empGenderSelector3" />
                                    <label className="form-check-label" htmlFor="empGenderSelector3">
                                        Rather not say
                                    </label>
                                </div> */}
            </div>
            <div className="app_card mt-4">
              <h4 className="app_heading_20_bold mb-4">Credentials</h4>
              <div className="row g-4">
                <div className="col-md-6">
                  <div className="app_textInputWrapper">
                    <input
                      id="password"
                      className="app_textInput"
                      type={showPassword ? "text" : "password"}
                      placeholder=" "
                      defaultValue=""
                      autoComplete="new-password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      {...register("password", {
                        required: true,
                        maxLength: {
                          value: 15,
                          message: "maximum length is 15",
                        },
                        minLength: {
                          value: 6,
                          message: "minimum length is 6",
                        },
                      })}
                    />
                    <label htmlFor="password" className="appInput_Placeholder">
                      Password
                    </label>
                    <button
                      className="show_password_btn"
                      onClick={(ev) => handleOnShowPassword(ev)}
                    >
                      <VisibilityOutlinedIcon
                        style={{ color: showPassword ? "#000" : "#a6a9b3" }}
                      />
                    </button>
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <div className="app_textInputWrapper">
                    <input
                      id="appLoginFormConfirmPasswordField"
                      className="app_textInput"
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder=" "
                      // autoComplete="off"
                      onChange={handleCPassword}
                    />
                    <label
                      htmlFor="appLoginFormConfirmPasswordField"
                      className="appInput_Placeholder"
                      >
                      Confirm Password
                    </label>
                     
                    <button
                      className="show_password_btn"
                      onClick={(ev) => handleOnShowConfirmPassword(ev)}
                    >
                      <VisibilityOutlinedIcon
                        style={{
                          color: showConfirmPassword ? "#000" : "#a6a9b3",
                        }}
                      />
                    </button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ManagerCreateEmployeeProfile;
