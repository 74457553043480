import React, { useEffect, useState } from "react";
import userPlaceholder from "../../../assets/images/users/user-vector.png";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import { Link } from "react-router-dom";
import Http from "../../security/Http";
import url from "../../../Development.json";
import { errorResponse } from "../../Helpers/response";
import { PeopleOutlined } from "@mui/icons-material";

const EmployeeProfile = () => {
  // const previewUserImageOnChange = (ev) => {
  //     const userImg = document.getElementById("empProfileImg");
  //     userImg.src = URL.createObjectURL(ev.target.files[0]);
  //     userImg.onload = function () {
  //         URL.revokeObjectURL(this.src);
  //     }
  // }
  // useEffect(() => {
  //     const userImg = document.getElementById("empProfileImg");
  //     if (!userImg.getAttribute('src')) {
  //         userImg.setAttribute('src', userPlaceholder)
  //     }
  // })
  const [empProfileData, setEmpProfileData] = useState([]); //Get Employee profile

  const profileData = () => {
    Http.callApi(url.user_profile)
      .then((response) => {
        console.log(response.data);
        setEmpProfileData(response.data);
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };
  useEffect(() => {
    profileData();
  }, []);

  return (
    <>
      <header className="manager_profile_header">
        <h4 className="app_heading">Profile</h4>
      </header>
      <div className="container mt-5">
        <div className="row d-flex justify-content-center">
          <div className="col-12 col-md-10 col-lg-8">
            <div className="app_card">
              <div className="d-flex">
                <div className="d-flex">
                  <div className="d-flex align-items-center">
                    <div className="user_img_container">
                      <img
                        src={
                          empProfileData.profile_image
                            ? empProfileData.profile_image
                            : userPlaceholder
                        }
                        alt="manager"
                        className="header_user_img"
                      />
                    </div>
                    <h4 className="app_text_20_bold mb-0 ms-3">
                      {empProfileData.firstname} {empProfileData.lastname}
                    </h4>
                  </div>
                </div>
                <div className="ms-auto d-flex align-items-center">
                  <Link
                    to="/employee/edit-profile"
                    state={{ profileData: empProfileData }}
                    className="app_primary_btn create_que_btn"
                  >
                    <BorderColorOutlinedIcon
                      style={{ color: "#F6F6F9", fontSize: 20 }}
                    />
                    <span className="add_btn_with_icon_text">Edit Profile</span>
                  </Link>
                </div>
              </div>
              <hr className="app_divider my-4" />
              <div className="row g-4">
                <div className="col-sm-6">
                  <div className="d-flex">
                    <EmailOutlinedIcon style={{ color: "#5153F5" }} />
                    <span className="app_text_16_bold ms-2">Email</span>
                  </div>
                  <div className="mt-1 mt-sm-3">
                    <span className="app_text">{empProfileData.email}</span>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="d-flex">
                    <LocalPhoneOutlinedIcon style={{ color: "#5153F5" }} />
                    <span className="app_text_16_bold ms-2">Phone</span>
                  </div>
                  <div className="mt-1 mt-sm-3">
                    <span className="app_text">{empProfileData.mobile}</span>
                  </div>
                </div>
                <div className="col-sm-6 mt-5">
                  <div className="d-flex">
                    <PeopleOutlined style={{ color: "#5153F5" }} />
                    <span className="app_text_16_bold ms-2">
                      Support Person Code
                    </span>
                  </div>
                  <div className="mt-1 mt-sm-3">
                    <span className="app_text">
                      {empProfileData.supportCode}
                    </span>
                  </div>
                </div>
                <div className="col-sm-6 mt-5">
                  <div className="d-flex">
                    <PeopleOutlined style={{ color: "#5153F5" }} />
                    <span className="app_text_16_bold ms-2">
                      Organisation Code
                    </span>
                  </div>
                  <div className="mt-1 mt-sm-3">
                    <span className="app_text">
                      {empProfileData.manager_code ??
                        "Controlled by your Organisation"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeProfile;
