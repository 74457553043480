import React, { useState, useEffect } from "react";
import ExerciseCard from "../../sub-components/employeeSub/ExerciseCard";
import ExerciseVideoPopup from "../../sub-components/employeeSub/ExerciseVideoPopup";
import { useLocation } from "react-router-dom";

const ExerciseCategory = () => {
  const [isVideoPopupOpen, setIsVideoPopupOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState({});
  const { state } = useLocation();
  const [getData, setGetData] = useState([]);

  useEffect(() => {
    if (state && state) {
      let data = JSON.parse(state);
      console.log(data);
      setGetData(data);
    }
  }, []);

  const handleVideoPopupOnClick = (item) => {
    console.log("Video Popup Invoked");
    console.log(item);
    setIsVideoPopupOpen(true);
    setSelectedVideo(item);
  };
  const handlePopupOnCloseClick = () => {
    setIsVideoPopupOpen(false);
  };

  return (
    <>
      {isVideoPopupOpen && (
        <ExerciseVideoPopup
          key={getData._id}
          item={selectedVideo}
          isOpen={isVideoPopupOpen}
          closeModal={handlePopupOnCloseClick}
        />
      )}
      <div className="emp_home_wrapper" style={{ height: "100vh" }}>
        <div className="container py-5">
          <div className="col-12 col-md-10 col-lg-10 mx-auto">
            <h4 className="app_heading mb-5">{getData.title}</h4>
            <div
              className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 g-4"
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              {getData?.exercises?.map((exercise) => (
                <ExerciseCard
                  key={exercise.id}
                  item={exercise}
                  onClickFunc={handleVideoPopupOnClick}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExerciseCategory;
