import React from 'react';
import { Navigate, Outlet } from 'react-router';

//Manager Authetication 
const AuthMangerVerifyRoute = ({ isAuthenticated }) => (
    isAuthenticated && localStorage.getItem('man_accessToken') && localStorage.getItem('man_accessToken') != '' ?
    <Outlet />
    :
        <Navigate to={'/login'} />

);

export default AuthMangerVerifyRoute;