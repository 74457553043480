import React, { useEffect, useState } from "react";
// import { Tooltip, tooltipClasses } from '@mui/material';
// import { styled } from '@mui/material/styles';
import sad from "../../../assets/images/moods/Sad.png";
import unhappy from "../../../assets/images/moods/Unhappy.png";
import okay from "../../../assets/images/moods/Neutral.png";
import happy from "../../../assets/images/moods/Happy.png";
import excited from "../../../assets/images/moods/Excited.png";
import Http from "../../security/Http";
import url from "../../../Development.json";

const EmployeeMoodSelector = ({ moodOnChange, selectedMood }) => {
  const [userProfile, setUserProfile] = useState({});
  const profileData = () => {
    Http.callApi(url.user_profile)
      .then((response) => {
        console.log(response);
        setUserProfile(response.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    profileData();
  }, []);

  const moodData = [
    {
      moodId: 0,
      moodImg:
        userProfile && userProfile.sadMindmoji ? userProfile.sadMindmoji : sad,
      moodName: "sad",
    },
    {
      moodId: 1,
      moodImg:
        userProfile && userProfile.unhappyMindmoji
          ? userProfile.unhappyMindmoji
          : unhappy,
      moodName: "unhappy",
    },
    {
      moodId: 2,
      moodImg:
        userProfile && userProfile.okayMindmoji
          ? userProfile.okayMindmoji
          : okay,
      moodName: "okay",
    },
    {
      moodId: 3,
      moodImg:
        userProfile && userProfile.happyMindmoji
          ? userProfile.happyMindmoji
          : happy,
      moodName: "happy",
    },
    {
      moodId: 4,
      moodImg:
        userProfile && userProfile.elatedMindmoji
          ? userProfile.elatedMindmoji
          : excited,
      moodName: "elated",
    },
  ];

  return (
    <>
      <h4
        className="app_heading py-5 text-center"
        style={{ justifyContent: "center" }}
      >
        How are you feeling?
      </h4>
      <div className="d-flex flex-column emp_mood_wrapper position-relative">
        <div
          className="d-flex mt-5 mb-5"
          style={{ display: "flex", justifyContent: "space-evenly" }}
        >
          {moodData.map((item) => {
            return (
              <div
                key={item.moodId}
                className={
                  moodData[selectedMood].moodName !== item.moodName
                    ? "emp_mood_option_container px-3 h-50 w-50"
                    : "emp_mood_option_container px-3 h-75 w-75"
                }
              >
                <input
                  id={`${item.moodName}MoodOption`}
                  onChange={(e) => {
                    moodOnChange(item.moodId);
                  }}
                  value={item.moodName}
                  className="d-none emp_mood_option_input"
                  type="radio"
                  name="moodOptions"
                />

                <label
                  className="emp_mood_option_label"
                  htmlFor={`${item.moodName}MoodOption`}
                >
                  <img
                    src={item.moodImg}
                    className="img-fluid"
                    style={{
                      height: "164px",
                      width: "164px",
                      objectFit: "contain",
                    }}
                    alt={item.moodName}
                  />
                </label>
              </div>
            );
          })}
        </div>
        <h4
          className="selected_mood_text text-center"
          style={{ color: "#9997A6", fontSize: 18, textTransform: "uppercase" }}
        >
          {moodData[selectedMood].moodName}
        </h4>
      </div>
    </>
  );
};

export default EmployeeMoodSelector;
