import React from 'react'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SingleChoiceQue from './SingleChoiceQue'
import MultiChoiceQue from "./MultiChoiceQue";
import TextQue from "./TextQue";

const DisplayQuestionCard = ({ queId, queType, queText, answersSet, onQueRemove }) => {

    return (
        <div className='app_card mt-4 position-relative'>
            <button className='app_blank_btn display_que_card_remove_btn' onClick={() => onQueRemove(queId)} ><DeleteOutlineOutlinedIcon /></button>
            {queType === 'single' ? 
            <SingleChoiceQue queTitle={queText} value='1'  answers={answersSet} /> : queType === 'multi' ? <MultiChoiceQue value='2' queTitle={queText} answers={answersSet} /> : <TextQue value='3' queType={queType} queTitle={queText} />}
        </div>
    )
}

export default DisplayQuestionCard