import React, { useState, useEffect } from "react";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Result } from 'antd';
import { LikeOutlined } from '@ant-design/icons';
import { useForm } from "react-hook-form";
import url from "../../../Development.json";
import { useNavigate } from "react-router-dom";
import Http from "../../security/Http";
import { ToastContainer } from "react-toastify";
import {
    errorResponse,
    successResponse,
    isError,
} from "../../Helpers/response";

const SetPassword = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const navigate = useNavigate();
    useEffect(() => {
        isError(errors);
    });
    const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [setPassword, getPassword] = useState();
    const [setcnPassword, getcnPassword] = useState();
    const [passwordError, setPasswordError] = useState("");


    const handleOnShowPassword = (ev) => {
        ev.preventDefault();
        setShowPassword(!showPassword)
    }
    const handleSetPassword = () => {
        setShowPasswordConfirmation(true)
    }

    const SetPasswordConfirmation = () => {
        return (<div className='w-75 mx-auto'>
            <h3 className='mb-5 text-center'>Mind pulse</h3>
            <Result
                title="Password updated"
                subTitle="Password has been changed successfully go to login page to login with new password"
                extra={<a href='/login' className='text-decoration-none mx-auto'>Go to Login</a>}
                icon={< LikeOutlined style={{ color: 'ActiveBorder' }} />}
            />
        </div>)
    }

    const onSubmit = (data) => {
        setPasswordError(setPassword !== setcnPassword);
        if (passwordError == "") {
            Http.callApi(url.password_update, JSON.stringify(data))
                .then(() => {
                    successResponse("Password has been change Successfully.");
                    setTimeout(() => {
                        localStorage.clear();
                        navigate("/login");
                    }, 2000);
                })
                .catch((error) => {
                    if (error.response.data) {
                        let arr = error.response.data;
                        Object.values(arr).map((item) => {
                            errorResponse(item);
                        });
                    }
                    if (!error) {
                        errorResponse("Please Try Again!");
                    }
                });
        }

    };

    const handleChange = (event) => {
        getPassword(event);
    }

    const handleChanges = (event) => {
        getcnPassword(event);
    }
    return (
        <>
            <ToastContainer />
            <section className='auth_wrapper'>
                <div className='container-fluid p-0'>
                    <div className='row vh-100'>
                        <div className='col-lg-6 h-100 d-flex align-items-center'>
                            <div className='login_form_container'>
                                <h4 className='app_heading_24_bold text-nowrap'>Create new password</h4>
                                <div className='login_form_wrapper'>
                                    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                                        <div className='row g-4'>
                                            <div className='col-12'>
                                                <div className='app_textInputWrapper'>
                                                    <input id='old_password' className='app_textInput' type={showPassword ? 'text' : 'password'} placeholder=' '
                                                        {...register("old_password", {
                                                            required: "Old password is required",
                                                        })} />
                                                    <label htmlFor='old_password' className='appInput_Placeholder'>Enter Old password</label>
                                                    <button className='show_password_btn' onClick={(ev) => handleOnShowPassword(ev)}>
                                                        <VisibilityOutlinedIcon style={{ color: showPassword ? '#000' : "#a6a9b3" }} />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                                <div className='app_textInputWrapper'>
                                                    <input id='new_password' className='app_textInput' type={showPassword ? 'text' : 'password'} placeholder=' '
                                                        {...register("new_password", {
                                                            required: "New password is required",
                                                        })}
                                                        onChange={(e) => handleChange(e.target.value)}
                                                    />
                                                    <label htmlFor='appNewPasswordField' className='appInput_Placeholder'>Enter new password</label>
                                                    <button className='show_password_btn' onClick={(ev) => handleOnShowPassword(ev)}>
                                                        <VisibilityOutlinedIcon style={{ color: showPassword ? '#000' : "#a6a9b3" }} />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                                <div className='app_textInputWrapper'>
                                                    <input id='confirm_password' className='app_textInput' type={'password'} placeholder=' '
                                                        {...register("confirm_password", {
                                                            required: "Confirm password is required",
                                                        })}
                                                        onChange={(e) => handleChanges(e.target.value)}
                                                    />
                                                    <label htmlFor='appConfirmPasswordField' className='appInput_Placeholder'>Confirm password</label>
                                                </div>
                                            </div>
                                        </div>
                                        {passwordError ? <span style={{ color: "red" }}>Password do not match</span> : ""}
                                        <button className='app_primary_btn w-100 mt-4' onClick={() => handleSetPassword()}>Set Password</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 d-none d-lg-block'>
                            <div className='login_image_container'>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SetPassword