import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import icon1 from "../../../assets/images/actions/mood_capture.png";
import icon2 from "../../../assets/images/actions/wellness.png";
import moodCaptureCalendar from "../../../assets/images/actions/mood_calendar.png";
import peopleCalendar from "../../../assets/images/actions/people_calendar.png";
import questionnaireIcon from "../../../assets/images/actions/question.gif";
import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";
import { Link } from "react-router-dom";
import Http from "../../security/Http";
import url from "../../../Development.json";
import { errorResponse } from "../../Helpers/response";

const EmployeeHome = () => {
  const [empProfileData, setEmpProfileData] = useState([]); //Get Employee profile
  const [getDate, setDate] = useState(""); //Get Employee profile
  const [getEmpList, SetEmpList] = useState([]);

  const profileData = () => {
    var greetings = "";
    Http.callApi(url.user_profile)
      .then((response) => {
        console.log(response);
        var date = new Date();
        var hr = date.getHours();
        if (hr < 12) {
          greetings = "Good Morning";
        } else if (hr < 17) {
          greetings = "Good Afternoon";
        } else {
          greetings = "Good Evening";
        }
        setDate(greetings);
        setEmpProfileData(response.data);
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  useEffect(() => {
    profileData();
    Http.callApi(url.get_employee)
      .then((response) => {
        console.log(response.data);
        SetEmpList(response.data);
      })
      .catch((error) => {
        if (error.message) {
          errorResponse(error.message);
        } else {
          errorResponse("Please Try Again!");
        }
      });
  }, []);

  return (
    <>
      <div
        className="emp_home_wrapper"
        style={{
          height: "90vh",
          background: `linear-gradient(45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 45%, ${
            empProfileData.company
              ? empProfileData.company.company_primary_color
              : "rgba(81, 83, 245, 1)"
          } 150%)`,
        }}
      >
        <div className="container d-flex flex-column justify-content-center align-items-center h-100">
          <div className="d-flex flex-column flex-sm-row align-items-center mb-5 flex-wrap justify-content-center">
            <span className="app_heading_normal emp_home_greeting_text">
              {getDate}
            </span>
            <h4 className="app_heading_normal ms-2">
              {empProfileData.firstname} {empProfileData.lastname}
            </h4>
          </div>
          <div className="row w-100 d-flex justify-content-center g-3">
            <div className="col-12 col-md-10 col-lg-8">
              <Link
                style={{ textDecoration: "unset" }}
                to="/daily-log"
                className="app_card emp_home_card"
              >
                <div
                  style={{ alignItems: "center" }}
                  className="d-flex justify-center"
                >
                  <div className="emp_home_card_img_container justify-center">
                    <img
                      src={icon1}
                      alt="home icon"
                      style={{ height: 48, width: 48, marginRight: 24 }}
                    />
                  </div>
                  <h4
                    className="app_text_16_bold emp_home_card_text justify-center"
                    style={{ marginBottom: "unset" }}
                  >
                    Daily Mood Capture
                  </h4>
                  <div style={{ flex: 1 }} />
                  <span>
                    <ArrowForwardIosIcon style={{ color: "#2B2744" }} />
                  </span>
                </div>
              </Link>
            </div>
            <div className="col-12 col-md-10 col-lg-8">
              <Link
                style={{ textDecoration: "unset" }}
                to="/wellness"
                className="app_card emp_home_card"
              >
                <div style={{ alignItems: "center" }} className="d-flex">
                  <div className="emp_home_card_img_container">
                    <img
                      src={icon2}
                      alt="home icon"
                      style={{ height: 48, width: 48, marginRight: 24 }}
                    />
                  </div>
                  <h4
                    className="app_text_16_bold emp_home_card_text"
                    style={{ marginBottom: "unset" }}
                  >
                    Wellness
                  </h4>
                  <div style={{ flex: 1 }} />
                  <span>
                    <ArrowForwardIosIcon style={{ color: "#2B2744" }} />
                  </span>
                </div>
              </Link>
            </div>

            <div className="col-12 col-md-10 col-lg-8">
              <Link
                style={{ textDecoration: "unset" }}
                to="/calendar"
                className="app_card emp_home_card"
              >
                <div style={{ alignItems: "center" }} className="d-flex">
                  <div className="emp_home_card_img_container">
                    <img
                      src={moodCaptureCalendar}
                      alt="home icon"
                      style={{ height: 48, width: 48, marginRight: 24 }}
                    />
                  </div>
                  <h4
                    className="app_text_16_bold emp_home_card_text"
                    style={{ marginBottom: "unset" }}
                  >
                    Mood Capture Calendar
                  </h4>
                  <div style={{ flex: 1 }} />
                  <span>
                    <ArrowForwardIosIcon style={{ color: "#2B2744" }} />
                  </span>
                </div>
              </Link>
            </div>

            {getEmpList.length > 0 ? (
              <div className="col-12 col-md-10 col-lg-8">
                <Link
                  style={{ textDecoration: "unset" }}
                  to="/calendar/people"
                  className="app_card emp_home_card"
                >
                  <div style={{ alignItems: "center" }} className="d-flex">
                    <div className="emp_home_card_img_container">
                      <img
                        src={peopleCalendar}
                        alt="home icon"
                        style={{ height: 48, width: 48, marginRight: 24 }}
                      />
                    </div>
                    <h4
                      className="app_text_16_bold emp_home_card_text"
                      style={{ marginBottom: "unset" }}
                    >
                      People Calendar
                    </h4>
                    <div style={{ flex: 1 }} />
                    <span>
                      <ArrowForwardIosIcon style={{ color: "#2B2744" }} />
                    </span>
                  </div>
                </Link>
              </div>
            ) : (
              <></>
            )}

            <div className="col-12 col-md-10 col-lg-8">
              <Link
                style={{ textDecoration: "unset" }}
                to="/employee/questionnaires"
                className="app_card emp_home_card"
              >
                <div style={{ alignItems: "center" }} className="d-flex">
                  <div className="emp_home_card_img_container">
                    <img
                      src={questionnaireIcon}
                      alt="home icon"
                      style={{ height: 48, width: 48, marginRight: 24 }}
                    />
                  </div>
                  <h4
                    className="app_text_16_bold emp_home_card_text"
                    style={{ marginBottom: "unset" }}
                  >
                    Questions
                  </h4>
                  <div style={{ flex: 1 }} />
                  <span>
                    <ArrowForwardIosIcon style={{ color: "#2B2744" }} />
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeHome;
