import React, { useState, useEffect } from "react";
import userPlaceholder from "../../../../assets/images/users/user-vector.png";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Http from "../../../security/Http";
import url from "../../../../Development.json";
import { HexColorPicker } from "react-colorful";

import {
  errorResponse,
  successResponse,
  isError,
} from "../../../Helpers/response";
import { ToastContainer } from "react-toastify";
import AddSupportConnectModal from "../../../sub-components/employeeSub/SupportConnectModal";
import CompanyEAPModal from "../../../pages/managerPages/CompanyConfigurator/CompanyEAPModal";

import TimePicker from "react-bootstrap-time-picker";
import moment from "moment";

const CompanyConfigurator = () => {
  const [userData, setUserData] = useState({});
  const [companyData, setCompanyData] = useState({});
  const [image, setImage] = useState("");
  const navigate = useNavigate();
  const [eap, setEap] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [color, setColor] = useState("#b32aa9");
  const [selectedEADOption, setSelectedEADOPtion] = useState(null);
  const [selectedTime, setSelectedTime] = useState(0);
  const [serverTime, setServerTime] = useState(0);

  const handleImportTime = (time) => {
    console.log(time);
    const date = new Date(time);
    const formattedDate = date.toLocaleTimeString("en-US", { hour12: false });

    console.log(formattedDate);
    const finalDate = getTotalSeconds(formattedDate);
    console.log(finalDate);
    return finalDate;
  };

  function getTotalSeconds(formattedDate) {
    const [hours, minutes, seconds] = formattedDate.split(":");
    const totalSeconds =
      parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds);
    return totalSeconds;
  }

  const handleTimeChange = (time) => {
    setSelectedTime(time);
    console.log(time);

    const date = new Date(time * 1000);
    console.log(date.toISOString());
    setServerTime(date.toISOString().slice(0, 19).replace("T", " "));
  };

  const onChange = (timeValue) => {
    setValue(timeValue);
  };

  const getSubscriptions = () => {
    Http.callApi(url.get_subscriptions, {})
      .then((res) => {
        console.log(res);
        setSubscriptions(res.data);
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  function formatDate(dateString) {
    const dateObj = new Date(dateString);
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1; // Months are 0-based
    const year = dateObj.getFullYear();

    return `${getWithOrdinal(day)} of each month`;
  }

  function formatYearDate(dateString) {
    const dateObj = new Date(dateString);
    const day = dateObj.getDate();
    const month = dateObj.toLocaleString('default', { month: 'long' });
    const year = dateObj.getFullYear();

    return `${getWithOrdinal(day)} of ${month} `;
  }


  function getWithOrdinal(n) {
    const s = ["th", "st", "nd", "rd"];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  //Upload Image
  const previewUserImageOnChange = (ev) => {
    const userImg = document.getElementById("userProfileImg");
    userImg.src = URL.createObjectURL(ev.target.files[0]);
    userImg.onload = function () {
      URL.revokeObjectURL(this.src);
    };

    const image = new FormData();
    image.append("file", ev.target.files[0]);
    Http.callApi(url.upload_image, image)
      .then((res) => {
        console.log(res);
        setImage(res.data.file_url);
      })
      .catch((error) => {
        errorResponse("Please Try Again!");
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  //Update Manager Profile
  const onSubmit = (data) => {
    if (image !== "") {
      data["company_image"] = image;
    }
    if (selectedTime != null) {
      data["notification_time"] = serverTime;
    }
    if (color !== "") {
      data["color"] = color;
    }
    Http.callApi(url.update_company_details, JSON.stringify(data))
      .then((response) => {
        console.log(response);
        successResponse(
          "Company Updated Successfully. Your Registered People will see any changes made the next time they open Mind Pulse"
        );
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  const profileData = () => {
    Http.callApi(url.user_profile)
      .then((response) => {
        console.log(response.data);
        setUserData(response.data);
        if (response.data.company) {
          setCompanyData(response.data.company);
          setValue("company_name", response.data.company.company_name);
          setValue("company_phone", response.data.company.company_phone);
          setValue(
            "notification_time",
            new Date(
              response.data.company.company_notification_time
            ).getSeconds()
          );

          console.log(
            moment(
              response.data.company.company_notification_time
            ).milliseconds()
          );
          console.log(
            handleImportTime(response.data.company.company_notification_time)
          );
          setSelectedTime(
            handleImportTime(response.data.company.company_notification_time)
          );
          setColor(response.data.company.company_primary_color);
          getCompanyEAPS({ companyId: response.data.company._id });
        }
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  useEffect(() => {
    profileData();
    getSubscriptions();
  }, []);

  const getCompanyEAPS = ({ companyId }) => {
    const data = {
      company: companyId,
    };
    Http.callApi(url.get_company_eap, data)
      .then((response) => {
        console.log(response);
        setEap(response.data);
      })
      .catch((error) => {
        console.log(error);
        if (error.message) {
          errorResponse(error.message);
        } else {
          errorResponse("Please Try Again!");
        }
      });
  };

  const getSelectedEADId = (eadItem) => {
    setSelectedEADOPtion(eadItem);
  };

  let renewalText = companyData.planFrequency && companyData.planFrequency == "monthly"
    ? `${companyData.planFrequency.charAt(0).toUpperCase() + companyData.planFrequency.slice(1)} on ${formatDate(companyData.companyPlanStartDate)}`
    : `Annually on ${formatYearDate(companyData.companyPlanStartDate)}`;

  return (
    <>
      {selectedEADOption ? (
        <CompanyEAPModal showEADItem={selectedEADOption} />
      ) : (
        <></>
      )}
      <ToastContainer />
      <form onSubmit={handleSubmit(onSubmit)}>
        <header className="manager_profile_header">
          <h4 className="app_heading">Company Configurator</h4>
          <button className="btn app_primary_btn">Save</button>
        </header>
        <div className="container mt-5">
          <div className="row d-flex justify-content-center">
            <div className="col-12 col-md-10 col-lg-9">
              <div
                className="app_card"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "transparent",
                  border: "unset",
                }}
              >
                <h4 className="app_heading_20_bold">Company Logo</h4>
                <div className="edituserimg_wrapper">
                  <div className="edituserimg_container">
                    <img
                      id="userProfileImg"
                      src={
                        companyData.company_logo
                          ? companyData.company_logo
                          : userPlaceholder
                      }
                      className="header_user_img"
                      alt="user"
                    />
                  </div>
                  <div className="userEditImgInput_wrapper">
                    <label
                      htmlFor="managerProfileImgEdit"
                      className="userprofile_editImgLabel"
                    >
                      <CameraAltIcon />
                    </label>
                    <input
                      onChange={(ev) => previewUserImageOnChange(ev)}
                      type="file"
                      name="company_image"
                      accept="image/*"
                      id="managerProfileImgEdit"
                      className="userprofile_editImgInput"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center mt-4">
            <div className="col-12 col-md-10 col-lg-9">
              <div className="app_card d-flex">
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <h4 className="app_heading_20_bold mb-4">
                    Company Information
                  </h4>
                  <div style={{ flex: 1 }} />
                  <h4 className="app_text_16_bold mb-4">
                    Organisation Code:{" "}
                    <span style={{ fontWeight: "400" }}>
                      {userData.manager_code
                        ? userData.manager_code.toUpperCase()
                        : ""}
                    </span>
                  </h4>
                </div>
                <div className="row g-4">
                  <div className="col-lg-6">
                    <div className="app_textInputWrapper">
                      <input
                        className="app_textInput"
                        type="text"
                        placeholder=" "
                        id="nacompany_nameme"
                        {...register("company_name", { required: true })}
                      />
                      <label
                        className="appInput_Placeholder"
                        htmlFor="company_name"
                      >
                        Company Name
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="app_textInputWrapper">
                      <input
                        className="app_textInput"
                        type="text"
                        placeholder=" "
                        id="company_phone"
                        {...register("company_phone", { required: true })}
                      />
                      <label
                        className="appInput_Placeholder"
                        htmlFor="company_phone"
                      >
                        Company Phone
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="app_textInputWrapper">
                      <TimePicker
                        className="app_textInput"
                        style={{ border: "none" }}
                        start="00:00"
                        end="23:59"
                        step={15}
                        onChange={handleTimeChange}
                        value={selectedTime}
                      />
                      <label
                        className="appInput_Placeholder"
                        htmlFor="company_phone"
                      >
                        Notification Time
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <h4
                      style={{ fontWeight: "600", fontSize: 16 }}
                      className="mb-2"
                    >
                      Company Primary Colour
                    </h4>
                    <HexColorPicker
                      style={{ width: "100%" }}
                      color={color}
                      onChange={setColor}
                    />
                    <div className="value" style={{ borderLeftColor: color }}>
                      Current color is {color}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row d-flex justify-content-center mt-4 mb-5">
            <div className="col-12 col-md-10 col-lg-9">
              <div className="app_card">
                <div className="col">
                  <div className="row">
                    <h4
                      style={{ width: "auto" }}
                      className="app_heading_20_bold"
                    >
                      Support Connect Providers
                    </h4>
                    <div style={{ flex: 1 }} />
                    <button
                      onClick={() => {
                        var addModal = document.getElementById(
                          "supportConnectModalButton"
                        );
                        // Simulate a click event
                        addModal.click();
                      }}
                      type="button"
                      className="btn app_primary_btn mx-2"
                      style={{ width: "auto" }}
                    >
                      Add Support Provider
                    </button>
                  </div>
                  <p style={{ fontSize: 14 }}>
                    Have specific Support Connect Providers for your
                    organisation? Add them below
                  </p>
                </div>
                <div className="row row-cols-1 row-cols-md-12 mb-2">
                  {eap.length > 0 ? (
                    eap.map((eapData) => {
                      return (
                        <Link
                          className="col-3 g-2"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            alignContent: "center",
                            border: "1px solid #e3e3e3",
                            borderRadius: "12px",
                            padding: "10px",
                            textDecoration: "none",
                            color: "black",
                          }}
                          role="button"
                          onClick={() => setSelectedEADOPtion(eapData)}
                          data-bs-toggle="modal"
                          data-bs-target="#eadOptionModal"
                          to=""
                        >
                          <img
                            src={eapData.image}
                            className="mb-4"
                            style={{
                              height: "64px",
                              width: "96px",
                              objectFit: "contain",
                            }}
                            roundedCircle
                          />
                          <p className="mb-1 text-center">
                            {eapData.options[0].title}
                          </p>
                          <p>{eapData.mobile}</p>
                        </Link>
                      );
                    })
                  ) : (
                    <div
                      className="text-center mt-4"
                      style={{ fontSize: "14px" }}
                    >
                      <p>
                        No Company-Specific Support Connect Providers have been
                        added yet
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row d-flex justify-content-center mt-4 mb-5">
            <div className="col-12 col-md-10 col-lg-9">
              <div className="app_card">
                <div className="col">
                  <div className="row">
                    <h4
                      style={{ width: "auto" }}
                      className="app_heading_20_bold mb-0"
                    >
                      Subscription & Seat Management
                    </h4>
                    <div style={{ flex: 1 }} />
                  </div>
                  <p className="mt-2" style={{ fontSize: 14 }}>
                    You'll find your organsiations subscription & user access
                    details below.
                  </p>
                </div>
                <div className="row px-2 mb-1">
                  <div
                    className="col-md-4"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      alignContent: "center",
                      border: "1px solid #e3e3e3",
                      borderRadius: "12px",
                      padding: "10px",
                    }}
                  >
                    <p
                      style={{ fontSize: 14 }}
                      className="mt-2 mb-0 text-center"
                    >
                      Mind Pulse Subscription
                    </p>
                    <p className="mb-0" style={{ fontSize: 14 }}>
                      {companyData.seats} Seats
                    </p>
                    <p style={{ fontSize: 14 }}>
                      Using {companyData.usedSeats} Seats
                    </p>
                    <p style={{ fontSize: 14 }} className="text-center">
                      $
                      {parseFloat(
                        companyData.perUserPrice
                      ).toFixed(2)}{" "}
                      AUD p/Seat p/Month
                    </p>

                    <p
                      style={{
                        fontSize: 14,
                        color: "green",
                      }}
                      className="text-center"
                    >
                      Renews {renewalText}
                    </p>

                    <p className="text-center" style={{ fontSize: 12 }}>
                      Please contact our Admin Team to edit your
                      Subscription
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <button
        id="supportConnectModalButton"
        type="button"
        hidden={true}
        data-bs-toggle="modal"
        data-bs-target="#supportConnectModal"
      />

      <AddSupportConnectModal onSubmit={() => { }} />
    </>
  );
};

export default CompanyConfigurator;
