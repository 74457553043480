import React from "react";
import AppRouter from "./components/app-routing/AppRouter";

const App = () => {
  return (
    <>
        <AppRouter />
    </>
  );
};
export default App;
