import React, { useEffect, useState } from "react";
import DecreaseMoodConfirmModal from "../../sub-components/employeeSub/DailyLogModals/DecreaseMoodConfirmModal";
import DecreaseMoodModal from "../../sub-components/employeeSub/DailyLogModals/DecreaseMoodModal";
import IncreasedMoodConfirmModal from "../../sub-components/employeeSub/DailyLogModals/IncreasedMoodConfirmModal";
import IncreasedMoodModal from "../../sub-components/employeeSub/DailyLogModals/IncreasedMoodModal";
import EmployeeMoodSelector from "../../sub-components/employeeSub/EmployeeMoodSelector";
import Http from "../../security/Http";
import url from "../../../Development.json";
import { errorResponse, successResponse } from "../../Helpers/response";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckInModal from "../../sub-components/employeeSub/DailyLogModals/CheckInModal";

const DailyLog = () => {
  const [selectedMood, setSelectedMood] = useState(2);
  const [moodWiseModal, setMoodWisemodal] = useState("");
  const [empProfileData, setEmpProfileData] = useState([]);

  const handleMoodOnChange = (e) => {
    console.log(e);
    setSelectedMood(e);
  };
  const profileData = () => {
    Http.callApi(url.user_profile)
      .then((response) => {
        console.log(response.data);
        setEmpProfileData(response.data);
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };
  useEffect(() => {
    profileData();
  }, []);

  const submitMood = () => {
    const obj = {
      log_type: 1,
      usertype: 1,
      mood_type: selectedMood,
      is_notify: 0,
    };
    Http.callApi(url.user_dailyLog, obj)
      .then((response) => {
        console.log(response);
        if (response.data.type === "normal") {
          console.log("Data Type is Normal");
          // Get the element by ID
          var increaseModal = document.getElementById("increaseModalTrigger");
          // Simulate a click event
          increaseModal.click();
        } else if (response.data.type === "decrease") {
          var decreaseModal = document.getElementById("decreaseModalTrigger");
          // Simulate a click event
          decreaseModal.click();
        }
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  useEffect(() => {
    if (selectedMood === "happy" || selectedMood === "excited") {
      setMoodWisemodal("increasedMoodModal");
    } else {
      setMoodWisemodal("decreasedMoodModal");
    }
  }, [selectedMood]);

  const showNotCheckingInModal = () => {
    console.log("Tapped Skip Checkin");
    var increaseModal = document.getElementById("checkModalTrigger");
    // Simulate a click event
    increaseModal.click();
  };

  return (
    <>
      <section className="que_main_wrapper">
        <div className="que_wrapper d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-md-9 col-lg-7 col-xl-6 mx-auto d-flex flex-column pb-3 justify-content-center daily_log_col">
                <EmployeeMoodSelector
                  moodOnChange={handleMoodOnChange}
                  selectedMood={selectedMood}
                />

                <button
                  id="increaseModalTrigger"
                  type="button"
                  hidden={true}
                  data-bs-toggle="modal"
                  data-bs-target="#increasedMoodConfirmModal"
                />

                <button
                  id="decreaseModalTrigger"
                  type="button"
                  hidden={true}
                  data-bs-toggle="modal"
                  data-bs-target="#decreasedMoodModal"
                />

                <button
                  id="checkModalTrigger"
                  type="button"
                  hidden={true}
                  data-bs-toggle="modal"
                  data-bs-target="#checkModal"
                />

                {selectedMood !== "Select Mood" ? (
                  <button
                    onClick={() => {
                      submitMood();
                    }}
                    type="button"
                    className="app_primary_btn"
                  >
                    Submit
                  </button>
                ) : (
                  <button className="app_primary_btn" disabled>
                    Select Mood
                  </button>
                )}
                <a
                  onClick={() => showNotCheckingInModal()}
                  className="mt-3"
                  style={{
                    width: "100%",
                    textDecoration: "unset",
                    color: "#a3a3a3",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <p>SKIP</p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <CheckInModal />
        <IncreasedMoodConfirmModal userData={empProfileData} />
        <DecreaseMoodModal userData={empProfileData} />
      </section>
    </>
  );
};

export default DailyLog;
