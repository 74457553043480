import React, { useState, useEffect } from "react";
import AudiotrackOutlinedIcon from "@mui/icons-material/AudiotrackOutlined";
import PauseOutlinedIcon from "@mui/icons-material/PauseOutlined";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import MindfullnessAudioPlayer from "../../sub-components/employeeSub/MindfullnessAudioPlayer";
import url from "../../../Development.json";
import Http from "../../security/Http";
import { errorResponse } from "../../Helpers/response.js";
import AppLoader from "../../sub-components/AppLoader";

const EmployeeVirtualCommute = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedAudioTrack, setSelecetdAudioTrack] = useState("");
  const [selectedAudioFile, setSelectedAudioFile] = useState("");
  const [selectedAudioItem, setSelectedAudioItem] = useState({});
  const [isPlaying, setIsPlaying] = useState(false);
  const [isAudioPlayerOpen, setIsAudioPlayerOpen] = useState(false);
  const [audioPlayerTitle, setAudioPlayerTitle] = useState();
  const [getMindfulnessList, SetMindfulnessList] = useState([]); //Get Mindfulness list
  const [pageTitle, setPageTitle] = useState("");
  const [empProfileData, setEmpProfileData] = useState([]);

  const profileData = () => {
    Http.callApi(url.user_profile)
      .then((response) => {
        console.log(response);
        setEmpProfileData(response.data);
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  const getMindfulnessData = () => {
    setIsLoading(true);
    Http.callApi(url.get_virtual_commute)
      .then((response) => {
        console.log(response.data);
        SetMindfulnessList(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.message) {
          errorResponse(error.message);
        } else {
          errorResponse("Please Try Again!");
        }
      });
  };

  const getPageTitle = () => {
    const data = {
      page: "virtual_commute",
    };
    Http.callApi(url.get_page_title, data)
      .then((response) => {
        console.log(response);
        setPageTitle(response.data.heading);
      })
      .catch((error) => {
        if (error.message) {
          errorResponse(error.message);
        } else {
          errorResponse("Please Try Again!");
        }
      });
  };

  useEffect(() => {
    profileData();
    getPageTitle();
    getMindfulnessData();
  }, []);

  const handleAudioCardTrackClick = (audioId) => {
    console.log(audioId);
    setSelectedAudioItem(audioId);
    setSelecetdAudioTrack(audioId._id);
    setSelectedAudioFile(audioId.audio);
    setAudioPlayerTitle(audioId.title);
    setIsAudioPlayerOpen(true);
    setIsPlaying(true);
  };

  const audioPlayerClose = () => {
    setIsAudioPlayerOpen(false);
    setIsPlaying(false);
    setSelecetdAudioTrack("");
  };

  const handleSelectedAudioOnToggle = () => {
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    if (isAudioPlayerOpen) {
      document
        .getElementById("mindfullnessAudioPlayerId")
        .classList.add("open");
    }
  }, [isAudioPlayerOpen]);

  const MindfullnessAudioCard = (props) => {
    const [duration, setDuration] = useState("");
    function durationTime(d) {
      d = Number(d);
      var h = Math.floor(d / 3600);
      var m = Math.floor((d % 3600) / 60);
      var s = Math.floor((d % 3600) % 60);
      var hDisplay = h > 0 ? h + (h == 1 ? "h " : "h ") : "";
      var mDisplay = m > 0 ? m + (m == 1 ? "m " : "m ") : "";
      var sDisplay = s > 0 ? s + (s == 1 ? "s " : "s ") : "";
      setDuration(hDisplay + mDisplay + sDisplay);
    }
    useEffect(() => {
      durationTime(props.audioTrackDuration);
    }, []);

    return (
      <div className="col">
        <div className="d-flex justify-content-center align-items-center">
          <div
            onClick={() => handleAudioCardTrackClick(props.item)}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 48,
              width: 48,
              padding: 8,
              borderRadius: "50%",
              color:
                selectedAudioTrack === props.item._id && isPlaying
                  ? "#f0f2fe"
                  : "#5153f5",
              backgroundColor:
                selectedAudioTrack === props.item._id && isPlaying
                  ? "#5153f5"
                  : "#f0f2fe",
            }}
          >
            <AudiotrackOutlinedIcon />
          </div>
          <div className="audio_text_container mx-3">
            <button
              onClick={() => handleAudioCardTrackClick(props.item)}
              className="app_blank_btn app_text_bold"
            >
              {props.item.title}
            </button>
            <span className="audio_card_small_label" style={{ fontSize: 12 }}>
              {props.item.play_duration}
            </span>
          </div>
          <div style={{ flex: 1 }} />
          <div className="d-flex align-items-center justify-content-between align-items-center">
            {selectedAudioTrack === props.item._id && (
              <button
                onClick={() => handleSelectedAudioOnToggle()}
                className="app_blank_btn d-flex align-items-center"
              >
                <span className="audio_control_icon me-2">
                  {isPlaying ? (
                    <PauseOutlinedIcon style={{ fontSize: 18 }} />
                  ) : (
                    <PlayArrowIcon style={{ fontSize: 18 }} />
                  )}
                </span>
                <span className="audio_card_small_label">
                  {isPlaying ? "Playing" : "Paused"}
                </span>
              </button>
            )}
          </div>
        </div>
        <hr className="app_divider mt-3" />
      </div>
    );
  };
  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        <div
          className="emp_home_wrapper"
          style={{
            height: "90vh",
            background: `linear-gradient(45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 45%, ${
              empProfileData.company
                ? empProfileData.company.company_primary_color
                : "rgba(81, 83, 245, 1)"
            } 150%)`,
          }}
        >
          <div className="container py-5">
            <section className="mindfull_wrapper">
              <div className="container d-flex justify-content-center">
                <div className="col-12 col-md-10 col-lg-8">
                  <h4 className="app_heading justify-content-center">
                    {pageTitle
                      ? pageTitle
                      : "Find a quiet spot or put in your headphones 🎧"}
                  </h4>
                  <div className="mt-4 row row-cols-1 g-4">
                    {getMindfulnessList.map((item) => (
                      <MindfullnessAudioCard key={item._id} item={item} />
                    ))}
                  </div>
                </div>
              </div>
              <div style={{ height: 108 }} />
            </section>
          </div>
        </div>
      )}
      <MindfullnessAudioPlayer
        audioPlayerTitle={audioPlayerTitle}
        isPlaying={isPlaying}
        item={selectedAudioItem}
        handleSelectedAudioOnToggle={handleSelectedAudioOnToggle}
        selectedAudioTrack={selectedAudioTrack}
        closeFunc={audioPlayerClose}
      />
    </>
  );
};

export default EmployeeVirtualCommute;
