import React from "react";
import { UndoOutlined, LikeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Http from "../../../security/Http";
import url from "../../../../Development.json";

const IncreasedMoodConfirmModal = ({ userData }) => {
  const navigate = useNavigate();

  const undoAnswer = () => {
    const obj = {
      log_type: 1,
      usertype: 1,
      mood_type: 5,
      is_notify: 0,
      undo: true,
    };
    Http.callApi(url.user_dailyLog, obj)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  return (
    <div
      className="modal fade"
      id="increasedMoodConfirmModal"
      tabIndex="-1"
      aria-labelledby="increasedMoodModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-md show">
        <div className="modal-content" style={{ borderRadius: "12px" }}>
          <div
            className="modal-body d-flex flex-column p-4"
            style={{ borderRadius: "12px" }}
          >
            <div className="d-flex justify-content-center my-3">
              <img
                height={164}
                width={164}
                src={require("../../../../assets/lottieJson/verified.gif")}
              />
            </div>
            <h4 className="app_heading_20_bold justify-content-center text-center mb-4">
              Thank you for checking-in today {userData.firstname}!
            </h4>
            <button
              className="app_primary_btn"
              onClick={() => navigate("/employee")}
              data-bs-dismiss="modal"
            >
              Head Home
            </button>
            <button
              className="app_blank_btn app_text_gray w-100 d-flex mx-auto mt-4"
              style={{ justifyContent: 'center' }}
              data-bs-dismiss="modal"
              type={"button"}
              onClick={undoAnswer}
            >
              <UndoOutlined className="me-2" />
              Undo my answer
            </button>
            <hr />
            <h4 className="app_heading_20_bold justify-content-center text-center mb-4 mt-2">
              Take control of your wellbeing
            </h4>
            <button
              className="app_primary_btn"
              onClick={() => navigate("/wellness")}
              data-bs-dismiss="modal"
            >
              Head to Wellness
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncreasedMoodConfirmModal;
