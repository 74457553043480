import React, { useEffect, useState } from "react";
import url from "../../Development.json";
import { errorResponse, successResponse } from "../Helpers/response";
import Http from "../security/Http";

const NotifySupportPersonModal = (props) => {
  const [empProfileData, setEmpProfileData] = useState([]);
  const [supportPeople, setSupportPeople] = useState([]);

  const getSupportPeople = () => {
    Http.callApi(url.user_support_people)
      .then((response) => {
        console.log(response);
        setSupportPeople(response.data);
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  const profileData = () => {
    Http.callApi(url.user_profile)
      .then((response) => {
        console.log(response);
        setEmpProfileData(response.data);
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };
  useEffect(() => {
    profileData();
    getSupportPeople();
  }, []);

  const onSubmit = (id) => {
    const obj = {
      is_notify: 1,
      user_id: empProfileData._id,
      support_person_id: id,
    };
    Http.callApi(url.user_dailyLog, obj)
      .then(() => {
        successResponse(
          "Your nominated support person will check-in with you soon."
        );
      })
      .catch((error) => {
        console.log("ERROR DATA", error);
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  return (
    <div
      className="modal fade"
      id="notifySupportPersonModal"
      tabIndex="-1"
      aria-labelledby="notifySupportPersonModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-sm">
        <div className="modal-content">
          <div className="modal-body d-flex flex-column">
            <h4 className="text-center app_text_20_bold my-2">
              Notify Support Person
            </h4>
            <p style={{ fontSize: 14 }}>
              Would you like someone to check in with you?
            </p>
            {supportPeople.map((person) => {
              return (
                <button
                  className="app_primary_btn my-1"
                  data-bs-dismiss="modal"
                  onClick={() => onSubmit(person._id)}
                >
                  Notify {person.firstname}
                </button>
              );
            })}
            <button
              className="app_secondary_btn mt-1"
              data-bs-toggle="modal"
              data-bs-target="#decreasedMoodConfirmModal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotifySupportPersonModal;
