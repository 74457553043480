import React from "react";

const HeaderManagerEmp = () => {
  return (
    <header className="header_manager_secondary">
      <div className="container">
        <h4 className="app_heading mb-4">People Directory</h4>
      </div>
    </header>
  );
};

export default HeaderManagerEmp;
