import React, { useEffect, useState } from "react";
import url from "../../../../Development.json";
import { errorResponse, successResponse } from "../../../Helpers/response";
import Http from "../../../security/Http";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
// import OneSignal from 'react-onesignal';

const CreateQueSubmitConfirmModal = (props) => {
  const navigate = useNavigate();

  return (
    <>
      <ToastContainer />
      <div
        className="modal fade"
        id="createQueSubmitConfirmModal"
        tabIndex="-1"
        aria-labelledby="createQueSubmitConfirmModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div
            className="modal-content p-4"
            style={{ borderRadius: "20px", border: "unset" }}
          >
            <div className="modal-body">
              <div className="remove_que_modal_container">
                <p className="app_heading_20_bold text-center mb-4">
                  Everyone in your organisation will be notified that this questionnaire is available to complete.
                </p>
                <div className="d-flex w-100 justify-content-center">
                  <button
                    data-bs-dismiss="modal"
                    className="me-3 app_secondary_btn w-50"
                  >
                    Cancel
                  </button>
                  <button
                    data-bs-dismiss="modal"
                    className="app_primary_btn w-50"
                    onClick={() => {
                      props.submitFunction();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateQueSubmitConfirmModal;
