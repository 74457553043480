import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import icon1 from "../../../assets/images/actions/mood_capture.png";
import icon2 from "../../../assets/images/actions/wellness.png";
import moodCaptureCalendar from "../../../assets/images/actions/mood_calendar.png";
import peopleCalendar from "../../../assets/images/actions/people_calendar.png";
import questionnaireIcon from "../../../assets/images/actions/question.gif";
import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";
import { Link } from "react-router-dom";
import Http from "../../security/Http";
import url from "../../../Development.json";
import { errorResponse } from "../../Helpers/response";

const EmployeeQuestionnaires = () => {
  const [empProfileData, setEmpProfileData] = useState([]); //Get Employee profile
  const [getDate, setDate] = useState(""); //Get Employee profile
  const [getEmpList, SetEmpList] = useState([]);
  const [pending, setPending] = useState([]);
  const [previous, setPrevious] = useState([]);

  const profileData = () => {
    var greetings = "";
    Http.callApi(url.user_profile)
      .then((response) => {
        console.log(response);
        var date = new Date();
        var hr = date.getHours();
        if (hr < 12) {
          greetings = "Good Morning";
        } else if (hr < 17) {
          greetings = "Good Afternoon";
        } else {
          greetings = "Good Evening";
        }
        setDate(greetings);
        setEmpProfileData(response.data);
        getPendingQuestionnaires();
        getPreviousQuestionnaires();
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  useEffect(() => {
    profileData();
  }, []);

  const getPendingQuestionnaires = () => {
    Http.callApi(url.list_pending_question)
      .then((response) => {
        console.log(response.data);
        var pendingList = response.data.filter(
          (element) => element.is_completed !== 1
        );
        setPending(pendingList);
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  const getPreviousQuestionnaires = () => {
    Http.callApi(url.list_questions)
      .then((response) => {
        var previousList = response.data.filter(
          (element) => element.is_completed === 1
        );
        setPrevious(previousList);
        console.log(pending);
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  return (
    <>
      <div
        className="emp_home_wrapper"
        style={{
          overflow: "scroll",
          paddingTop: 48,
          height: "90vh",
          background: `linear-gradient(45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 45%, ${
            empProfileData.company
              ? empProfileData.company.company_primary_color
              : "rgba(81, 83, 245, 1)"
          } 150%)`,
        }}
      >
        <div className="container d-flex flex-column justify-content-center align-items-center">
          <div className="d-flex flex-column flex-sm-row align-items-center mb-5 flex-wrap justify-content-center">
            <span className="app_heading_normal emp_home_greeting_text">
              {getDate}
            </span>
            <h4 className="app_heading_normal ms-2">
              {empProfileData.firstname} {empProfileData.lastname}
            </h4>
          </div>
          <div className="row w-100 d-flex justify-content-center g-3">
            <div className="col-12 col-md-10 col-lg-8">
              <p style={{ color: "#9997A6", fontWeight: "600" }}>
                Pending Questionnaires
              </p>
              {pending.length > 0 ? (
                pending.map((data) => (
                  <div
                    style={{
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      backgroundColor: "#ffffff",
                      borderRadius: "12px",
                      padding: "24px",
                      marginBottom: "16px",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        borderRadius: "12px",
                        flexDirection: "column",
                        display: "flex",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: 16,
                          marginBottom: 12,
                        }}
                      >
                        {data.title}
                      </p>
                      <p
                        style={{
                          marginBottom: 0,
                          paddingBottom: 0,
                          color: "#9997A6",
                          fontWeight: "600",
                          fontSize: 14,
                        }}
                      >
                        <span style={{ color: "black", fontWeight: "600" }}>
                          0
                        </span>{" "}
                        of {data.question.length} Questions Answered
                      </p>
                    </div>
                    <div style={{ flex: 1 }} />
                    <Link
                      style={{ textDecoration: "unset" }}
                      to="/questionnaire"
                      state={{ questionnaire: data }}
                    >
                      <div
                        style={{
                          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                          backgroundColor: "#E5E5FE",
                          borderRadius: "12px",
                          padding: "16px 24px",
                          flexDirection: "row",
                          display: "flex",
                        }}
                      >
                        <p
                          style={{
                            color: "#5153F5",
                            fontSize: 14,
                            fontWeight: "500",
                            marginBottom: "0px",
                          }}
                        >
                          Complete Questionnaire
                        </p>
                      </div>
                    </Link>
                  </div>
                ))
              ) : (
                <div
                  style={{
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#ffffff",
                    borderRadius: "12px",
                    padding: "24px",
                    marginBottom: "16px",
                    flexDirection: "row",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <img
                    style={{ height: 48, width: 48 }}
                    src={require("../../../assets/images/ic_pending.png")}
                    alt="Previous Icon"
                  />
                  <p
                    style={{
                      marginLeft: 12,
                      marginBottom: 0,
                      paddingBottom: 0,
                    }}
                  >
                    Your pending questionnaires will appear here
                  </p>
                </div>
              )}
              <hr style={{ color: "#e3e3e3" }} />
            </div>
            <div className="col-12 col-md-10 col-lg-8">
              <p style={{ color: "#9997A6", fontWeight: "600" }}>
                Previous Questionnaires
              </p>
              {previous.length > 0 ? (
                previous.map((e) => (
                  <div
                    style={{
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      backgroundColor: "#ffffff",
                      borderRadius: "12px",
                      padding: "24px",
                      marginBottom: "16px",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        borderRadius: "12px",
                        flexDirection: "column",
                        display: "flex",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: 16,
                          marginBottom: 12,
                        }}
                      >
                        {e.title}
                      </p>
                      <p
                        style={{
                          marginBottom: 0,
                          paddingBottom: 0,
                          color: "#9997A6",
                          fontWeight: "600",
                          fontSize: 14,
                        }}
                      >
                        Successfully Completed
                      </p>
                    </div>
                    <div style={{ flex: 1 }} />
                    <p
                      style={{
                        marginBottom: 0,
                        paddingBottom: 0,
                        color: "#9997A6",
                        fontWeight: "600",
                        fontSize: 14,
                      }}
                    >
                      <span style={{ color: "black", fontWeight: "600" }}>
                        {e.question.length}
                      </span>{" "}
                      of {e.question.length}
                    </p>
                    <img
                      style={{ width: 20, height: 20, marginLeft: 8 }}
                      src={require("../../../assets/images/ic_success.png")}
                    />
                  </div>
                ))
              ) : (
                <div
                  style={{
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#ffffff",
                    borderRadius: "12px",
                    padding: "24px",
                    marginBottom: "16px",
                    flexDirection: "row",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <img
                    style={{ height: 48, width: 48 }}
                    src={require("../../../assets/images/ic_previous.png")}
                    alt="Previous Icon"
                  />
                  <p
                    style={{
                      marginLeft: 12,
                      marginBottom: 0,
                      paddingBottom: 0,
                    }}
                  >
                    Your past questionnaires will appear here
                  </p>
                </div>
              )}
            </div>

            {/* <div className="col-12 col-md-10 col-lg-8">
              <Link
                style={{ textDecoration: "unset" }}
                to="/questionnaire"
                className="app_card emp_home_card"
              >
                <div style={{ alignItems: "center" }} className="d-flex">
                  <div className="emp_home_card_img_container">
                    <img
                      src={questionnaireIcon}
                      alt="home icon"
                      style={{ height: 48, width: 48, marginRight: 24 }}
                    />
                  </div>
                  <h4
                    className="app_text_16_bold emp_home_card_text"
                    style={{ marginBottom: "unset" }}
                  >
                    Questions
                  </h4>
                  <div style={{ flex: 1 }} />
                  <span>
                    <ArrowForwardIosIcon style={{ color: "#2B2744" }} />
                  </span>
                </div>
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeQuestionnaires;
