import React, { useEffect, useState } from "react";
import url from "../../../../Development.json";
import { errorResponse, successResponse } from "../../../Helpers/response";
import Http from "../../../security/Http";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ViewAgenda } from "@mui/icons-material";

const CheckInModal = () => {
  const [empProfileData, setEmpProfileData] = useState([]);
  const navigate = useNavigate();

  const profileData = () => {
    Http.callApi(url.user_profile)
      .then((response) => {
        setEmpProfileData(response.data);
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };
  useEffect(() => {
    profileData();
  }, []);

  //   Daily log check in
  const onSubmit = (type) => {
    const obj = {
      log_type: type,
      usertype: 2,
      user_id: empProfileData._id,
    };
    Http.callApi(url.user_dailyLog, JSON.stringify(obj))
      .then((response) => {
        console.log(response);
        navigate("/employee");
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  const viewPage = () => {
    navigate("/daily-log");
  };
  return (
    <>
      <ToastContainer />
      <div
        className="modal fade"
        id="checkModal"
        tabIndex="-1"
        aria-labelledby="increasedMoodModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content">
            <div className="modal-body d-flex flex-column">
              <h4 className="text-center my-2" style={{ fontSize: 20 }}>
                Not checking in?
              </h4>
              <hr className="app_divider my-3" />
              <button
                className="app_secondary_btn"
                data-bs-dismiss="modal"
                onClick={() => onSubmit(2)}
              >
                RDO
              </button>
              <button
                className="app_secondary_btn my-3"
                data-bs-dismiss="modal"
                onClick={() => onSubmit(3)}
              >
                Sick Leave
              </button>
              <button
                className="app_secondary_btn"
                data-bs-dismiss="modal"
                onClick={() => onSubmit(5)}
              >
                I don't feel like it today
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckInModal;
