import React, { useState, useEffect } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import QuickQueViewModal from "../../../sub-components/managerQueSub/QuickQueViewModal";
import QueRemoveModal from "../../../sub-components/managerQueSub/QueRemoveModal";
import QuestionnairePreviousCard from "../../../sub-components/managerQueSub/QuestionnairePreviousCard";
import url from "../../../../Development.json";
import Http from "../../../security/Http";
import { errorResponse } from "../../../Helpers/response";
import AppLoader from "../../../sub-components/AppLoader";

const ManagerQuePrevious = () => {
  // const [isLoading, setIsLoading] = useState(true)
  const [getPrevQueList, SetGetPrevQueList] = useState([]); //get Previous QuestionsData list
  const [getPrevQueItem, SetPrevQueItem] = useState([]);
  const [questionSearch, setQuestionSearch] = useState("");

  const handleQuestionOnSearch = (ev) => {
    setQuestionSearch(ev.target.value);
    let que_search = ev.target.value;
    let search = { search: que_search };

    Http.callApi(url.get_previous_question, search)
      .then((response) => {
        SetGetPrevQueList(response.data);
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  // const getPreviousQuestionsData = (event) => {
  //     if (event.keyCode === 13) {
  //         let que_search = questionSearch;
  //         let search = { search: que_search }
  //         setIsLoading(true);
  //         Http.callApi(url.get_previous_question, search)
  //             .then((response) => {
  //                 SetGetPrevQueList(response.data);
  //                 setIsLoading(false);
  //             })
  //             .catch((error) => {
  //                 if (error.response) {
  //                     errorResponse(error);
  //                 }
  //             });
  //     }

  // }
  useEffect(() => {
    // setIsLoading(true);
    Http.callApi(url.get_previous_question)
      .then((response) => {
        SetGetPrevQueList(response.data);
        // setIsLoading(false);
      })
      .catch((error) => {
        if (error.message) {
          errorResponse(error.message);
        } else {
          errorResponse("Please Try Again!");
        }
      });
  }, []);

  const getItem = (item) => {
    SetPrevQueItem(item);
  };

  return (
    <>
      <div className="container">
        <header className="que_prev_header">
          <div>
            <h4 className="app_heading_24_bold">Previous Questionnaires</h4>
          </div>
          <div className="d-flex col-12 col-md-6 col-lg-4 mt-4 mt-md-0">
            <div className="app_searchInputWrapper w-100">
              <span className="app_search_icon_wrapper">
                <SearchOutlinedIcon style={{ fontSize: 20 }} />
              </span>
              <input
                type="text"
                onChange={(ev) => handleQuestionOnSearch(ev)}
                value={questionSearch}
                placeholder="Search questionnaires"
              />
            </div>
          </div>
        </header>
        <div className="row row-cols-1 gy-4">
          {getPrevQueList.length !== 0 ? (
            getPrevQueList?.map((item) => (
              <QuestionnairePreviousCard
                key={item._id}
                queId={item._id}
                queTitle={item.title}
                queResponses={item.response_count}
                quePostTime={item.createdAt}
                queItem={item}
                queItems={getItem}
              />
            ))
          ) : (
            <span className="validation_text_invalid">No data found</span>
          )}
        </div>
        <QuickQueViewModal getItem={getPrevQueItem} />
        <QueRemoveModal getItem={getPrevQueItem} />
      </div>
    </>
  );
};

export default ManagerQuePrevious;
