import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HeaderManager from "./HeaderManager";
import { Outlet } from "react-router-dom";
import AppLoader from "../sub-components/AppLoader";

const LayoutManager = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const AuthRoute = () => {
    const isLoginEmp = localStorage.getItem("emp_accessToken") || false;
    const isLoginMan = localStorage.getItem("man_accessToken") || false;

    if (localStorage.getItem("usertype") == 1) {
      const render = isLoginMan ? navigate("/manager") : navigate("/login");

      return render;
    } else if (localStorage.getItem("usertype") == 2) {
      const render = isLoginEmp ? navigate("/") : navigate("/login");

      return render;
    }
  };
  useEffect(() => {
    setTimeout(() => setIsLoading(false), 1500);
    AuthRoute();
  }, []);

  return (
    <>
      {isLoading && <AppLoader />}
      <HeaderManager />
      <section className="manager_wrapper app_bg-secondary position-relative">
        <Outlet />
      </section>
    </>
  );
};

export default LayoutManager;
