import EmployeeEAD from "../pages/employeePages/EmployeeEAD";
import EmployeeEatWell from "../pages/employeePages/EmployeeEatWell";
import EmployeeHome from "../pages/employeePages/EmployeeHome";
import EmployeeProfile from "../pages/employeePages/EmployeeProfile";
import EmployeeWellness from "../pages/employeePages/EmployeeWellness";
import EmployeeEditProfile from "../pages/employeePages/EmployeeEditProfile";
import ManagerCreateEmployeeProfile from "../pages/managerPages/managerEmpPages/ManagerCreateEmployeeProfile";
import ManagerEmp from "../pages/managerPages/managerEmpPages/ManagerEmp";
import ManagerCal from "../pages/managerPages/managerCalPages/ManagerCal";
import ManagerEditProfile from "../pages/managerPages/ManagerProfile/ManagerEditProfile";
import ManagerProfile from "../pages/managerPages/ManagerProfile/ManagerProfile";
import ManagerCreateQue from "../pages/managerPages/managerQuePages/ManagerCreateQue";
import ManagerQue from "../pages/managerPages/managerQuePages/ManagerQue";
import ManagerQueDrafEdit from "../pages/managerPages/managerQuePages/ManagerQueDrafEdit";
import ManagerViewResponse from "../pages/managerPages/managerQuePages/ManagerViewResponse";
import EatwellCategory from "../pages/employeePages/EatwellCategory";
import RecipeDetail from "../pages/employeePages/RecipeDetail";
import Exercise from "../pages/employeePages/Exercise";
import Login from "../pages/authentication/Login";
import OtpVarification from "../pages/authentication/OtpVarification";
import Signup from "../pages/authentication/Signup";

import ExerciseCategory from "../pages/employeePages/ExerciseCategory";
import EmployeeMindfullness from "../pages/employeePages/EmployeeMindfullness";
import Questionnaire from "../pages/employeePages/Questionnaire";
import ForgetPassword from "../pages/authentication/ForgetPassword";
import LinkSentConfirmation from "../pages/authentication/LinkSentConfirmation";
import SetPassword from "../pages/authentication/SetPassword";

import DailyLog from "../pages/employeePages/DailyLog";
import EmployeeVirtualCommute from "../pages/employeePages/EmployeeVirtualCommute";
import MoodCaptureCalendar from "../pages/employeePages/MoodCaptureCalendar";
import PeopleCalendar from "../pages/employeePages/PeopleCalendar";
import CompanyConfigurator from "../pages/managerPages/CompanyConfigurator/CompanyConfigurator";
import EmployeeQuestionnaires from "../pages/employeePages/EmployeeQuestionnaires";

import ResetPassword from "../pages/authentication/ResetPassword";
import SupportPeople from "../pages/employeePages/SupportPeople";
import NotificationsView from "../pages/notifications/NotificationsView";

//Manager Routes
const ManagerRoutes = [
  {
    index: true,
    auth: true,
    path: "/manager",
    element: <ManagerCal />,
  },
  {
    path: "/manager/questionnaires",
    element: <ManagerQue />,
    auth: true,
    title: "Check-in Dashboard",
  },
  {
    path: "/manager/configurator",
    element: <CompanyConfigurator />,
    auth: true,
    title: "Company Configurator",
  },
  {
    path: "/manager",
    auth: true,
    element: <ManagerQue />,
    title: "Manager Home",
  },
  {
    path: "/manager/emp",
    element: <ManagerEmp />,
    auth: true,
    title: "Employee Directory",
  },
  {
    path: "/manager/create-que",
    auth: true,
    element: <ManagerCreateQue />,
    title: "Create Questionnaire",
  },
  {
    path: "/manager/que-edit",
    auth: true,
    element: <ManagerQueDrafEdit />,
    title: "Edit Questionnaire",
  },
  {
    path: "/manager/view-response",
    auth: true,
    element: <ManagerViewResponse />,
    title: "Questionnaire Responses",
  },
  {
    path: "/manager/create-emp",
    auth: true,
    element: <ManagerCreateEmployeeProfile />,
    title: "Create Employee Profile",
  },
  {
    auth: true,
    path: "/manager/profile",
    element: <ManagerProfile />,
    title: "Manager Profile",
  },
  {
    auth: true,
    path: "/manager/edit-profile",
    element: <ManagerEditProfile />,
    title: "Manager Edit profile",
  },
  {
    path: "/manager/change-password",
    auth: true,
    element: <SetPassword />,
    title: "Manager Change Password",
  },
];

//Employee Route
const EmployeeRoutes = [
  {
    index: true,
    path: "/employee",
    auth: true,
    element: <EmployeeHome />,
  },
  {
    path: "/calendar",
    element: <MoodCaptureCalendar />,
    auth: true,
    title: "Employee Calender",
  },
  {
    path: "/calendar/people",
    element: <PeopleCalendar />,
    auth: true,
    title: "People Calendar",
  },
  {
    path: "/employee",
    element: <EmployeeHome />,
    auth: true,
    title: "Employee Home",
  },
  {
    path: "/employee/questionnaires",
    element: <EmployeeQuestionnaires />,
    auth: true,
    title: "Questionnaires",
  },
  {
    path: "/questionnaire",
    auth: true,
    element: <Questionnaire />,
    title: "Questionnaire",
  },
  {
    path: "login",
    auth: false,
    element: <Login />,
  },
  {
    path: "/account/verify",
    auth: false,
    element: <OtpVarification />,
  },
  {
    path: "signup",
    auth: false,
    element: <Signup />,
  },
  {
    path: "/profile",
    auth: true,
    element: <EmployeeProfile />,
    title: "Employee Profile",
  },
  {
    path: "/daily-log",
    auth: true,
    element: <DailyLog />,
    title: "Employee Daily Log",
  },
  {
    path: "/wellness",
    auth: true,
    element: <EmployeeWellness />,
    title: "Wellness",
  },
  {
    path: "wellness/support-connect",
    auth: true,
    element: <EmployeeEAD />,
    title: "EAD",
  },

  {
    path: "wellness/virtual-commute",
    auth: true,
    element: <EmployeeVirtualCommute />,
    title: "Virtual Commute - Wellness | Mind Pulse",
  },

  {
    path: "wellness/eatwell",
    auth: true,
    element: <EmployeeEatWell />,
    title: "Eat Well",
  },
  {
    path: "wellness/eatwell/all",
    auth: true,
    element: <EatwellCategory />,
    title: "Eat Well - Wellness | Mind Pulse",
  },
  {
    path: "wellness/eatwell/lunch",
    auth: true,
    element: <EatwellCategory />,
    title: "Eatwell Lunch",
  },
  {
    path: "wellness/eatwell/dinner",
    auth: true,
    element: <EatwellCategory />,
    title: "Eatwell Dinner",
  },
  {
    path: "/eatwell/recipe/",
    auth: true,
    element: <RecipeDetail />,
    title: "Recipe Detail",
  },
  {
    path: "wellness/exercise",
    auth: true,
    element: <Exercise />,
    title: "Exercise",
  },
  {
    path: "wellness/exercise/all",
    auth: true,
    element: <ExerciseCategory />,
    title: "Exercises - Wellness | Mind Pulse",
  },
  {
    path: "wellness/exercise/medium",
    element: <ExerciseCategory />,
    title: "Medium Exercise",
  },
  {
    path: "wellness/exercise/heavy",
    element: <ExerciseCategory />,
    title: "Heavy Exercise",
  },
  {
    path: "wellness/mindfullness",
    auth: true,
    element: <EmployeeMindfullness />,
    title: "Mindfullness",
  },
  {
    auth: true,
    path: "/employee/edit-profile",
    element: <EmployeeEditProfile />,
    title: "Employee Edit profile",
  },
  {
    path: "/account-recovery",
    auth: false,
    element: <ForgetPassword />,
  },
  {
    path: "/employee/change-password",
    auth: true,
    element: <SetPassword />,
    title: "Employee Change Password",
  },
  {
    path: "/email-confirmation",
    auth: false,
    element: <LinkSentConfirmation />,
  },
  {
    path: "/password/reset",
    auth: false,
    element: <ResetPassword />,
    title: "Reset your Password",
  },
  {
    path: "/employee/support/people",
    auth: true,
    element: <SupportPeople />,
    title: "Configure your Support People",
  },
  {
    path: "/employee/notifications",
    auth: true,
    element: <NotificationsView />,
    title: "Notifications",
  }
];

export { ManagerRoutes, EmployeeRoutes };
