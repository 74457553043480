import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import MoodSelector from "../../../sub-components/MoodSelector";
import CreateQueBoilerPlateCard from "../../../sub-components/managerQueSub/CreateQueBoilerPlateCard";
import DisplayQuestionCard from "../../../sub-components/managerQueSub/DisplayQuestionCard";
import CreateQueSubmitConfirmModal from "./CreateQueSubmitConfirmModal";
import url from "../../../../Development.json";
import { errorResponse, successResponse } from "../../../Helpers/response";
import Http from "../../../security/Http";
import { ToastContainer } from "react-toastify";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { DatePicker } from "antd";
import moment from "moment";

const ManagerQueDrafEdit = () => {
  const navigate = useNavigate();
  let answerUid = () => `answer${Math.random().toString(36).substring(2, 9)}`; //unique id for each answer in form
  let queBoilerPlateUid = () =>
    `queBoilerPlate${Math.random().toString(36).substring(2, 9)}`; //unique id for each question set in form
  const { state } = useLocation();
  const [getQueData, setGetQueData] = useState(); //Get Question data
  //Final form submit data state without form title and default question
  const [formQueset, setFormQueset] = useState([]);
  const [getMngId, setMngIdData] = useState([]);
  //boiler plate state for new question
  const [getQuestion, setQuestion] = useState([]);
  const [getQuestionData, setQuestionData] = useState([]);
  const [getTitle, setTitle] = useState("");
  const [selectedDuration, setSelectedDuration] = useState("select duration");
  const [setdays, seDays] = useState("");
  const [toggleD, setToggleD] = useState(false); //Toggle display listing sorting dropdown
  const dayTypeDDRef = useRef(null);
  const dayTypeDDBtnRef = useRef(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [action, setAction] = useState("");

  const [selectedDate, setSelectedDate] = useState("");
  const [queBoilerPlate, setQueBoilerPlate] = useState([
    {
      queBoilerPlateId: queBoilerPlateUid(),
      queBoilerPlateSelectedQueType: "single",
      queBoilerPlateQueText: "",
      queBoilerPlateAnswersSet: [
        {
          answerId: answerUid(),
          answerText: "",
        },
      ],
    },
  ]);

  const showDaysTypeDropDown = () => {
    if (dayTypeDDRef.current.classList.contains("show")) {
      dayTypeDDRef.current.classList.remove("show");
    } else {
      dayTypeDDRef.current.classList.add("show");
    }
    setToggleD(!toggleD);
  };

  useEffect(() => {
    if (toggleD) {
      document.addEventListener("mousedown", (event) => {
        if (
          dayTypeDDRef.current &&
          !dayTypeDDRef.current.contains(event.target) &&
          !dayTypeDDBtnRef.current.contains(event.target)
        ) {
          dayTypeDDRef.current.classList.remove("show");
          setToggleD(false);
        }
      });
    }
  }, [toggleD]);

  useEffect(() => {
    if (state && state.data) {
      let data = JSON.parse(state.data);
      console.log(data);
      setGetQueData(data);
      setSelectedDate(data.queItem?.end_time);
      let questionarr = [];
      let ansArr = [];
      {
        data?.queItem?.question.map((item, index) => {
          if (item.type != 0) {
            item.options.map((value) => {
              ansArr.push({
                answerId: answerUid(),
                answerText: value,
              });
            });
            questionarr.push({
              queBoilerPlateSelectedQueType:
                item.type == 0
                  ? "mood"
                  : item.type == 1
                  ? "single"
                  : item.type == 2
                  ? "multi"
                  : "text",
              queBoilerPlateQueText: item.title,
              queBoilerPlateId: queBoilerPlateUid(),
              queBoilerPlateAnswersSet: ansArr,
            });
          }
          ansArr = [];
        });
      }
      setFormQueset(questionarr);
    }
  }, []);

  //handle module on discard click
  const handleDiscardBtnClick = () => {
    navigate("/manager");
  };

  //Add question to form
  const queBoilerPlateAddBtnClick = () => {
    if (queBoilerPlate.length === 0) {
      setQueBoilerPlate([
        {
          queBoilerPlateId: queBoilerPlateUid(),
          queBoilerPlateSelectedQueType: "single",
          queBoilerPlateQueText: "",
          queBoilerPlateAnswersSet: [
            {
              answerId: answerUid(),
              answerText: "",
            },
          ],
        },
      ]);
    } else if (
      queBoilerPlate.filter((item) => item.queBoilerPlateQueText !== "")
        .length > 0
    ) {
      if (
        queBoilerPlate.filter(
          (item) =>
            item.queBoilerPlateAnswersSet.filter(
              (answer) => answer.answerText !== ""
            ).length > 1
        ).length > 0
      ) {
        setFormQueset((prev) => {
          return [...prev, ...queBoilerPlate];
        });
        setQueBoilerPlate([
          {
            queBoilerPlateId: queBoilerPlateUid(),
            queBoilerPlateSelectedQueType: "single",

            queBoilerPlateQueText: "",
            queBoilerPlateAnswersSet: [
              {
                answerId: answerUid(),
                answerText: "",
              },
            ],
          },
        ]);
      } else if (
        queBoilerPlate.filter(
          (item) => item.queBoilerPlateSelectedQueType === "text"
        ).length > 0
      ) {
        setFormQueset((prev) => {
          return [...prev, ...queBoilerPlate];
        });
        setQueBoilerPlate([
          {
            queBoilerPlateId: queBoilerPlateUid(),
            queBoilerPlateSelectedQueType: "single",

            queBoilerPlateQueText: "",
            queBoilerPlateAnswersSet: [
              {
                answerId: answerUid(),
                answerText: "",
              },
            ],
          },
        ]);
        // setQuestion([
        //     // queBoilerPlate.queBoilerPlateAnswersSet.map(item => {
        //     //     return [
        //     //             item.answerText,
        //     //         ]
        //     // }
        //     {
        //         "title": queBoilerPlate.queBoilerPlateQueText,
        //         "type": queBoilerPlate.queBoilerPlateSelectedQueType,
        //     }
        // ]);
      } else {
        console.log(
          "Please add at least 2 answer options if using a Selectable Question"
        );
        errorResponse(
          "Please add at least 2 answer options if using a Selectable Question"
        );
      }
    } else {
      console.log("Add at least 1 question");
      errorResponse("Please add at least 1 question");
    }
  };

  //Remove boilerplate from form
  const queBoilerPlateRemoveBtnClick = (id) => {
    setQueBoilerPlate((prev) =>
      prev.filter((item) => item.queBoilerPlateId !== id)
    );
  };

  //handle boiler plate question type
  const handleQueTypeOnChange = (type, queBoilerPlateId) => {
    setQueBoilerPlate((prev) =>
      prev.map((item) => {
        if (item.queBoilerPlateId === queBoilerPlateId) {
          item.queBoilerPlateSelectedQueType = type;
          return item;
        } else {
          return item;
        }
      })
    );
  };

  const handleDayOnChange = (day, type) => {
    setSelectedDuration(day);
    seDays(type);
    const queTypeDDIcon =
      document.getElementsByClassName("app_dd_arrowIcon")[0];
    dayTypeDDRef.current.classList.remove("show");
    queTypeDDIcon.classList.remove("open");
  };

  //handle boiler plate question
  const boilerPlateQueOnChange = (ev, queBoilerPlateId) => {
    setQueBoilerPlate((prev) =>
      prev.map((item) => {
        if (item.queBoilerPlateId === queBoilerPlateId) {
          item.queBoilerPlateQueText = ev.target.value;
          return item;
        } else {
          return item;
        }
      })
    );
  };

  // handle boiler plate answers text
  const handleAnswersOnChange = (ev, id) => {
    setQueBoilerPlate((prev) =>
      prev.map((item) => {
        item.queBoilerPlateAnswersSet.map((item) => {
          if (item.answerId === id) {
            item.answerText = ev.target.value;
            return item;
          } else {
            return item;
          }
        });
        return item;
      })
    );
  };

  //add more answer to boilerplate
  const handleAddAnswerOnClick = (boilerPlateId) => {
    setQueBoilerPlate((prev) =>
      prev.map((item) => {
        if (item.queBoilerPlateId === boilerPlateId) {
          item.queBoilerPlateAnswersSet = [
            ...item.queBoilerPlateAnswersSet,
            {
              answerId: answerUid(),
              answerText: "",
            },
          ];
          return item;
        } else {
          return item;
        }
      })
    );
  };

  //remove answer from boilerplate
  const handleAnswerOnRemove = (boilerPlateId, ansId) => {
    setQueBoilerPlate((prev) =>
      prev.map((item) => {
        if (item.queBoilerPlateId === boilerPlateId) {
          item.queBoilerPlateAnswersSet = item.queBoilerPlateAnswersSet.filter(
            (answer) => answer.answerId !== ansId
          );
          return item;
        } else {
          return item;
        }
      })
    );
  };

  const profileData = () => {
    Http.callApi(url.user_profile)
      .then((response) => {
        setMngIdData(response.data);
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };
  useEffect(() => {
    profileData();
  }, []);

  const handleDisplayQueOnRemove = (queId) => {
    setFormQueset((prev) => {
      return prev.filter((item) => item.queBoilerPlateId !== queId);
    });
  };

  //mood question
  const question = [];

  const setQuestionsData = () => {
    let questionArr = [];
    let ansArr = [];
    formQueset.map((value) => {
      value.queBoilerPlateAnswersSet.map((item) => {
        ansArr.push(item.answerText);
      });
      questionArr.push({
        title: value.queBoilerPlateQueText,
        type:
          value.queBoilerPlateSelectedQueType === "single"
            ? 1
            : value.queBoilerPlateSelectedQueType === "multi"
            ? 2
            : value.queBoilerPlateSelectedQueType === "text"
            ? 3
            : value.queBoilerPlateSelectedQueType,
        options: value.queBoilerPlateSelectedQueType === "text" ? [] : ansArr,
      });
      ansArr = [];
    });
    //Merge Array
    let questions = question.concat(questionArr);
    setQuestion(questions);
  };

  function handleDateChange(date) {
    setSelectedDate(date);
  }

  useEffect(() => {
    setQuestionsData();
  }, [formQueset]);

  //Update Draf Question
  const onDrafsubmit = (data) => {
    if (data.length === 1) {
      errorResponse("please create at least one question.");
    } else if (selectedDate === "") {
      errorResponse("Please select duration time");
    } else {
      let arr = [];
      arr["device"] = 3;
      arr["title"] = getTitle == "" ? getQueData?.queTitle : getTitle;
      arr["pushToken"] = "test";
      arr["is_draft"] = 1;
      arr["end_time"] = selectedDate;
      arr["id"] = getQueData.queId;
      arr["question"] = data;
      data["manager_id"] = getMngId._id;
      const obj = Object.assign({}, arr);
      Http.callApi(url.update_question, JSON.stringify(obj))
        .then(() => {
          successResponse("Update Question has been Successfully.");
          setTimeout(() => {
            navigate("/manager/questionnaires");
          }, 500);
        })
        .catch((error) => {
          errorResponse("Please Try Again!");
          if (error.response) {
            errorResponse(error);
          }
        });
    }
  };

  const submitQuestionnaire = () => {
    if (getQuestion.lemgth === 0) {
      queBoilerPlateAddBtnClick();
    }
    setAction("submit");
    setIsSubmitting(true);
    setQuestionData(getQuestion);
  };

  useEffect(() => {
    console.log("USEEFFECT QUESTION SET: ", getQuestion);

    if (isSubmitting && getQuestion.length > 0) {
      if (action === "draft") {
        console.log("Draft Question");
        onDrafsubmit(getQuestion);
      } else {
        console.log("Submit Question");
        onSubmit(getQuestion);
      }
    }
  }, [getQuestionData]);

  const onSubmit = (questions) => {
    console.log("QUESTION SET: ", questions);
    if (questions.length === 1) {
      errorResponse("please create at least one question.");
    } else if (selectedDate === "") {
      errorResponse("Please select duration time");
    } else {
      let arr = [];
      arr["device"] = 3;
      arr["title"] = getTitle == "" ? getQueData?.queTitle : getTitle;
      arr["pushToken"] = "test";
      arr["is_draft"] = 0;
      arr["end_time"] = selectedDate == "" ? getQueData.end_time : selectedDate;
      arr["id"] = getQueData.queId;
      arr["question"] = questions;
      questions["manager_id"] = getMngId._id;
      const obj = Object.assign({}, arr);
      Http.callApi(url.update_question, JSON.stringify(obj))
        .then(() => {
          successResponse("Update Question has been Successfully.");
          setTimeout(() => {
            navigate("/manager/questionnaires");
          }, 500);
        })
        .catch((error) => {
          errorResponse("Please Try Again!");
          if (error.response) {
            errorResponse(error);
          }
        });
    }
  };

  const submitDraftInfo = () => {
    if (getQuestion.lemgth === 0) {
      queBoilerPlateAddBtnClick();
    }
    setAction("draft");
    setIsSubmitting(true);
    setQuestionData(getQuestion);
  };

  return (
    <>
      <ToastContainer />
      <header
        className="header_manager_secondary"
        style={{ paddingBottom: 32 }}
      >
        <div className="manager_create_que_header">
          <h4 className="app_heading flex-nowrap w-100">Edit Questionnaire</h4>
          <div className="d-flex justify-content-lg-end mt-4 mt-lg-0 w-100">
            <button
              className="app_blank_btn me-3"
              onClick={() => handleDiscardBtnClick()}
            >
              Discard
            </button>
            <button
              className="app_secondary_btn ms-3"
              onClick={() => {
                submitDraftInfo();
              }}
            >
              Save as Draft
            </button>
            <button
              className="app_primary_btn ms-3"
              data-bs-toggle="modal"
              data-bs-target="#createQueSubmitConfirmModal"
            >
              Submit
            </button>
          </div>
        </div>
      </header>
      <div className="create_que_form_container container">
        <div className="col col-sm-10 col-lg-8">
          <input
            type="text"
            placeholder="Enter Form Title"
            defaultValue={getQueData?.queTitle}
            onChange={(e) => setTitle(e.target.value)}
            className="craete_que_form_title_input mb-5"
          />
          <h6 className="app_text_secondary_gray">Select end date</h6>
          <div className="que_type_dropdown_wrapper mb-4">
            <div className="que_type_dropdown_wrapper mb-4">
              <DatePicker
                className="app_dropDownBtn py-3"
                selected={selectedDate}
                placeholder="Select end date"
                onChange={handleDateChange}
                format="DD MMMM yyyy"
              />
            </div>
            <div ref={dayTypeDDRef} className="app_dd_list_wrapper">
              <ul className="app_dd_list que_type_selector_options_menu">
                <li>
                  <button
                    className="app_blank_btn"
                    onClick={() => handleDayOnChange("1 day", 1440)}
                  >
                    <span className="ms-3">1 day</span>
                  </button>
                </li>
                <li>
                  <button
                    className="app_blank_btn"
                    onClick={() => handleDayOnChange("2 day", 2880)}
                  >
                    <span className="ms-3">2 day</span>
                  </button>
                </li>
                <li>
                  <button
                    className="app_blank_btn"
                    onClick={() => handleDayOnChange("3 day", 4320)}
                  >
                    <span className="ms-3">3 day</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="app_border_card flex-column align-items-center">
            <h6 className="app_text_secondary_gray">Default Question</h6>
            <h4 className="app_heading_20_bold mb-4">
              What’s your mood right now?
            </h4>
            <MoodSelector />
          </div> */}
          {formQueset.map((item, index) => (
            <DisplayQuestionCard
              queId={item.queBoilerPlateId}
              queType={item.queBoilerPlateSelectedQueType}
              queText={item.queBoilerPlateQueText}
              answersSet={item.queBoilerPlateAnswersSet}
              onQueRemove={handleDisplayQueOnRemove}
            />
          ))}
          {queBoilerPlate.map((item) => {
            return (
              <CreateQueBoilerPlateCard
                key={item.queBoilerPlateId}
                queBoilerPlateId={item.queBoilerPlateId}
                selectedQueType={item.queBoilerPlateSelectedQueType}
                onTypeSelect={handleQueTypeOnChange}
                onDaySelect={handleDayOnChange}
                boilerPlateQueText={item.queBoilerPlateQueText}
                onQueTextChange={boilerPlateQueOnChange}
                onBoilerPlateRemove={queBoilerPlateRemoveBtnClick}
                boilerPlateAnswersSet={item.queBoilerPlateAnswersSet}
                boilerPlateAnswerOnChange={handleAnswersOnChange}
                boilerPlateAnswerOnAdd={handleAddAnswerOnClick}
                boilerPlateAnswerOnRemove={handleAnswerOnRemove}
                boilerPlateAnswersSetLen={item.queBoilerPlateAnswersSet.length}
                question={question}
              />
            );
          })}
          <button
            className="app_primary_btn mt-4"
            onClick={() => queBoilerPlateAddBtnClick()}
          >
            <AddCircleOutlineOutlinedIcon />
            <span className="add_btn_with_icon_text">Add Question</span>
          </button>
        </div>
      </div>
      <CreateQueSubmitConfirmModal
        title={getTitle == "" ? getQueData?.queTitle : getTitle}
        id={getQueData?.queId}
        submitFunction={submitQuestionnaire}
        end_time={selectedDate}
        getQuestion={getQuestion}
        managerId={getMngId}
      />
    </>
  );
};

export default ManagerQueDrafEdit;
