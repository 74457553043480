import React, { useEffect, useRef, useState } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
// import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useLocation } from "react-router-dom";
import Http from "../../security/Http";
import url from "../../../Development.json";
import { errorResponse } from "../../Helpers/response";
import userPlaceholder from "../../../assets/images/users/user-vector.png";
import Calender from "../managerPages/managerCalPages/Calender";
import AppLoader from "../../sub-components/AppLoader";
import sad from "../../../assets/images/moods/Sad.png";
import unhappy from "../../../assets/images/moods/Unhappy.png";
import okay from "../../../assets/images/moods/Neutral.png";
import happy from "../../../assets/images/moods/Happy.png";
import excited from "../../../assets/images/moods/Excited.png";

const MoodCaptureCalendar = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedResponder, setSelectedResponder] = useState("");
  const viewResponseLeftWrapperRef = useRef(null);
  const viewResponseRightWrapperRef = useRef(null);
  const responderBtnCardRef = useRef();
  const location = useLocation();
  const [getEmpList, SetEmpList] = useState([]);
  const [getQuelist, SetQueList] = useState([]);
  const [getEmpCal, SetEmpCal] = useState([]);
  const [getEmpName, SetEmpName] = useState("");
  const [empSearch, setEmpSearch] = useState("");

  useEffect(() => {
    const obj = {
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
    };
    Http.callApi(url.employee_log, obj)
      .then((response) => {
        console.log(response.data);
        SetEmpCal(response.data);
        setIsLoading(!isLoading);
      })
      .catch((error) => {
        if (error.message) {
          errorResponse(error.message);
        } else {
          errorResponse("Please Try Again!");
        }
      });
  }, []);

  return (
    <>
      <header className="manager_profile_header">
        <h4 className="app_heading">Mood Capture Calendar</h4>
      </header>
      <div className="container-fluid">
        <div className="row position-relative">
          <div ref={viewResponseRightWrapperRef} className="col-lg-12">
            <div className="py-2 px-md-5">
              <div className="calender-card-wrapper">
                <Calender getCalData={getEmpCal} />
              </div>
              {/* {getQuelist.question && getQuelist.question.map((value, index) => {
                                return <>
                                    {value.type == 0 ?
                                        <>
                                            <div className='view_response_que_wrapper'>
                                                <h4 className='app_text_16_bold'>{value.title}</h4>
                                                <div className='view_response_answer_wrapper flex-row'>
                                                    <div className='mood_container'>
                                                        <img className='img-fluid' src={value.answer == 'Happy' ? moodHappy : value.answer == 'Sad' ? moodSad : value.answer == 'Very Sad' ? moodAngry : value.answer == 'Very Happy' ? moodExcited :  value.answer == 'Okay' ? moodNeutral : 'No image Found'} alt="mood" />
                                                    </div>
                                                    <h4 className='app_text ms-3 d-flex align-items-center text-capitalize'>{value.answer}</h4>
                                                </div>
                                            </div>
                                            <hr className='app_divider view_response_divider' />
                                        </>
                                        :
                                        value.type == 1 ?
                                            <>
                                                <div className='view_response_que_wrapper'>
                                                    <h4 className='app_text_16_bold'>{value.title}</h4>
                                                    <div className='view_response_answer_wrapper'>
                                                        <h4 className='app_text'>{value.answer}</h4>
                                                    </div>
                                                </div>
                                                <hr className='app_divider view_response_divider' />
                                            </>
                                            :
                                            value.type == 2 ?
                                                <>
                                                    <div className='view_response_que_wrapper'>
                                                        <h4 className='app_text_16_bold'>{value.title}</h4>
                                                        <div className='view_response_answer_wrapper'>
                                                            <div className='row row-cols-1 g-2'>
                                                                {value.answer && value.answer.map((answer, index) => {
                                                                    return <div key={index} className='col'>
                                                                        <h4 className='app_text'><span className='app_text_bold me-2'>{`Option ${index + 1}:`}</span>{answer}</h4>
                                                                    </div>
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr className='app_divider view_response_divider' />
                                                </>
                                                :
                                                <>
                                                    <div className='view_response_que_wrapper'>
                                                        <h4 className='app_text_16_bold'>{value.title}</h4>
                                                        <div className='view_response_answer_wrapper'>
                                                            <p className='app_text'>{value.answer}</p>
                                                        </div>
                                                    </div>
                                                    <hr className='app_divider view_response_divider' />
                                                </>
                                    }
                                </>
                            })} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MoodCaptureCalendar;
