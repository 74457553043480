import React, { useEffect, useRef, useState } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
// import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useLocation } from "react-router-dom";
import Http from "../../security/Http";
import url from "../../../Development.json";
import { errorResponse } from "../../Helpers/response";
import userPlaceholder from "../../../assets/images/users/user-vector.png";
import Calender from "../managerPages/managerCalPages/Calender";
import AppLoader from "../../sub-components/AppLoader";
import sad from "../../../assets/images/moods/Sad.png";
import unhappy from "../../../assets/images/moods/Unhappy.png";
import okay from "../../../assets/images/moods/Neutral.png";
import happy from "../../../assets/images/moods/Happy.png";
import excited from "../../../assets/images/moods/Excited.png";

const PeopleCalendar = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedResponder, setSelectedResponder] = useState("");
  const viewResponseLeftWrapperRef = useRef(null);
  const viewResponseRightWrapperRef = useRef(null);
  const responderBtnCardRef = useRef();
  const location = useLocation();
  const [getEmpList, SetEmpList] = useState([]);
  const [getQuelist, SetQueList] = useState([]);
  const [getEmpCal, SetEmpCal] = useState([]);
  const [employeeId, setEmployeeId] = useState("");
  const [getEmpName, SetEmpName] = useState("");
  const [empSearch, setEmpSearch] = useState("");

  const handleResponseOnClick = (resId, fullname) => {
    setSelectedResponder(resId);
    SetEmpName(fullname);
    setEmployeeId(resId);
    viewResponseLeftWrapperRef.current.classList.add("hide");
    viewResponseRightWrapperRef.current.classList.add("show");
    const obj = {
      id: resId,
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
    };
    Http.callApi(url.get_emp_calender, obj)
      .then((response) => {
        console.log("EMPLOYEE CALENDAR DATA: ", response.data);
        SetEmpCal(response.data);
      })
      .catch((error) => {
        if (error.message) {
          errorResponse(error.message);
        } else {
          errorResponse("Please Try Again!");
        }
      });
  };

  const handleBackToRespondersOnClick = () => {
    viewResponseLeftWrapperRef.current.classList.remove("hide");
    viewResponseRightWrapperRef.current.classList.remove("show");
  };

  const handleEmployeeOnSearch = (ev) => {
    setEmpSearch(ev.target.value);
    let emp_search = ev.target.value;
    let search = { search: emp_search };
    Http.callApi(url.get_employee, search)
      .then((response) => {
        SetEmpList(response.data);
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  useEffect(() => {
    setIsLoading(true);
    Http.callApi(url.get_employee)
      .then((response) => {
        console.log(response.data);
        SetEmpList(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.message) {
          errorResponse(error.message);
        } else {
          errorResponse("Please Try Again!");
        }
      });
  }, []);

  const getLastMonthCalendarData = (date) => {
    const obj = {
      id: employeeId,
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };
    Http.callApi(url.employee_log, obj)
      .then((response) => {
        console.log(response);
        SetEmpCal(response.data);
      })
      .catch((error) => {
        if (error.message) {
          errorResponse(error.message);
        } else {
          errorResponse("Please Try Again!");
        }
      });
  }


  const getNextMonthCalendarData = (date) => {
    const obj = {
      id: employeeId,
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };
    Http.callApi(url.employee_log, obj)
      .then((response) => {
        console.log(response);
        SetEmpCal(response.data);
      })
      .catch((error) => {
        if (error.message) {
          errorResponse(error.message);
        } else {
          errorResponse("Please Try Again!");
        }
      });
  }

  return (
    <>
      <header className="manager_profile_header">
        <h4 className="app_heading">People Calendar</h4>
      </header>
      <div className="container-fluid">
        <div className="row position-relative">
          <div
            ref={viewResponseLeftWrapperRef}
            className="col-lg-4 view_response_left_wrapper calender_sidebar"
          >
            <div className="view_response_left_container">
              <h4 className="app_text_16_semi_bold mb-3">People</h4>
              <div className="app_searchInputWrapper w-100">
                <span className="app_search_icon_wrapper">
                  <SearchOutlinedIcon style={{ fontSize: 20 }} />
                </span>
                <input
                  type="text"
                  onChange={(ev) => handleEmployeeOnSearch(ev)}
                  value={empSearch}
                  placeholder="Search by name"
                />
              </div>
              <div className="view_response_responders_wrapper">
                <div className="row row-cols-1 g-4">
                  {getEmpList.length !== 0 ? (
                    getEmpList.map((item) => {
                      const moodType =
                        item.mood_type === 0
                          ? sad
                          : item.mood_type === 1
                          ? unhappy
                          : item.mood_type === 2
                          ? okay
                          : item.mood_type === 3
                          ? happy
                          : item.mood_type === 4
                          ? excited
                          : "";
                      return (
                        <div className="col" key={item._id}>
                          <button
                            id={`${item._id}Btn`}
                            ref={responderBtnCardRef}
                            onClick={() => {
                              console.log("ITEM AT SELECTION IS:", item);
                              handleResponseOnClick(item._id, item.fullname)
                            }
                            }
                            className={`app_card responder_card ${
                              selectedResponder === item._id ? "active" : ""
                            } `}
                          >
                            <div className="responder_user_container">
                              <div className="user_card_img_container">
                                <img
                                  className="img-fluid"
                                  src={
                                    item.profile_image
                                      ? item.profile_image
                                      : userPlaceholder
                                  }
                                  alt="responder"
                                />
                              </div>
                              <div className="responder_user_detail_container">
                                <h4
                                  className="app_text_bold text-start mb-1"
                                  style={{ textAlign: "center" }}
                                >
                                  {item.firstname} {item.lastname}
                                </h4>
                                <h4 className="app_text">
                                  {item.log_type === 1 ? (
                                    <div>
                                      <span className="badge badge-soft-success">
                                        Checked-in
                                      </span>{" "}
                                      <img
                                        src={moodType}
                                        className="img-fluid"
                                        width="20"
                                      ></img>
                                    </div>
                                  ) : item.log_type === 2 ? (
                                    <span className="badge badge-soft-dark">
                                      RDO
                                    </span>
                                  ) : item.log_type === 3 ? (
                                        <span className="badge badge-soft-dark">
                                      Sick Leave
                                    </span>
                                      ) : item.log_type === 5 ? (
                                        <span className="badge badge-soft-dark">
                                          Don't Feel Like
                                        </span>) : (
                                    <span className="badge badge-soft-secondary">
                                      Not Entered Yet
                                    </span>
                                  )}
                                </h4>
                              </div>
                            </div>
                          </button>
                        </div>
                      );
                    })
                  ) : (
                    <span className="validation_text_invalid">
                      No data found
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div ref={viewResponseRightWrapperRef} className="col-lg-8">
            {!selectedResponder ? (
              <div className="view_response_placeholder">
                <CalendarMonthOutlinedIcon style={{ fontSize: 140 }} />
                <h4
                  className="app_heading_24_bold mt-3"
                  style={{ color: "#E4E6F2" }}
                >
                  Select a person to view their calender
                </h4>
              </div>
            ) : (
              <div className="py-0 px-md-3">
                <h4 className="app_heading_20_bold mb-4">
                    <span>{getEmpName} Calendar</span>
                </h4>
                <div className="calender-card-wrapper">
                    <Calender getCalData={getEmpCal} employee={employeeId} onBackPressed={(date) => getLastMonthCalendarData(date)} onNextPressed={(date) => getNextMonthCalendarData(date)} />
                  </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PeopleCalendar;
