import React from "react";
import Modal from "react-modal";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

// Style object for the modal content
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    backgroundColor: "transparent",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "12px",
    padding: "48px",
    border: "unset",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 9999,
  },
};

const ExerciseVideoPopup = (props) => {
  return (
    <Modal
      closeTimeoutMS={250}
      style={customStyles}
      isOpen={props.isOpen}
      onRequestClose={props.closeModal}
    >
      <img
        onClick={props.closeModal}
        style={{
          position: "absolute",
          right: "4%",
          top: 0,
          width: "32px",
          height: "32px",
        }}
        src={require("../../../assets/images/ic_close.png")}
      />
      <video
        controls
        style={{
          height: "60vh",
          width: "53vw",
          objectFit: "cover",
          borderRadius: "12px",
        }}
      >
        <source src={props.item.demo_video} />
        Your browser does not support the video tag.
      </video>
    </Modal>
  );
};

export default ExerciseVideoPopup;
