
import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Http from '../../security/Http';
import { errorResponse } from "../../Helpers/response";
import url from "../../../Development.json";
import NotificationItem from '../../NotificationItem';
import { useNavigate } from 'react-router';
import NotificationSupportActions from './SupportActions';

const NotificationsView = () => {
    const navigate = useNavigate();
    const [loadingNotifications, setLoadingNotifications] = useState(true);

    const [priorityNotifications, setPriorityNotifications] = useState([]);
    const [recentNotifications, setRecentNotifications] = useState([]);
    const [earlierNotifications, setEarlierNotifications] = useState([]);

    const [modalOpen, setModalOpen] = useState(false);
    const [notificationUser, setNotificationUser] = useState({});

    useEffect(() => {
        getPriorityNotifications();
        getRecentNotifications();
        getEarlierNotifications();
    }, []);

    const getPriorityNotifications = () => {
        Http.callApi(url.get_priority_notifications)
            .then((response) => {
                console.log("PRIORITY NOTIFICATIONS: ", response.data);
                setPriorityNotifications(response.data);
            })
            .catch((error) => {
                if (error.response) {
                    errorResponse(error);
                }
            });
    };

    const getRecentNotifications = () => {
        Http.callApi(url.get_user_notifications)
            .then((response) => {
                console.log("RECENT NOTIFICATIONS: ", response.data);
                setRecentNotifications(response.data);
            })
            .catch((error) => {
                if (error.response) {
                    errorResponse(error);
                }
            });
    };

    const getEarlierNotifications = () => {
        Http.callApi(url.get_earlier_notifications)
            .then((response) => {
                console.log("EARLIER NOTIFICATIONS: ", response.data);
                setEarlierNotifications(response.data);
                setLoadingNotifications(!loadingNotifications);
            })
            .catch((error) => {
                setLoadingNotifications(!loadingNotifications);
                if (error.response) {
                    errorResponse(error);
                }
            });
    };

    const handleNotificationAction = (notification) => {
        console.log("Notification ID: ", notification._id);
        console.log("Notification Type: ", notification.notification_type);
        console.log("NOTIFICATION DATA: ", JSON.stringify(notification));

        Http.callApi(url.mark_notification_read, {
            "notification_id": notification._id,
        })
            .then((response) => {
                console.log("Response", response.data);
                // Response was successful - determine it's type and navigate accordingly
                switch (notification.notification_type) {
                    case 1:
                        navigate('/daily-log');
                        return;
                    case 2:
                        // Show the Contact Details Dialog for the requesting_user
                        setNotificationUser(notification.requested_user);
                        setModalOpen(!modalOpen);
                        return;
                    case 3:
                        return;
                    case 4:
                        navigate('/calendar/people');
                        return;
                    case 5:
                        // Navigate to the /employee/questionnaires route
                        navigate('/employee/questionnaires');
                        return;
                    default:
                        alert("No action defined for this notification type.");
                        return;
                }
            })
            .catch((error) => {
                if (error.response) {
                    errorResponse(error);
                }
            });
    }

    return (
        <>
            <NotificationSupportActions isOpen={modalOpen} onClose={() => { setModalOpen(!modalOpen) }} user={notificationUser} />
            <div className="emp_home_wrapper"
                style={{
                    height: "100%",
                    width: '100vw',
                    background: `linear-gradient(45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 45%, "rgba(81, 83, 245, 1)"} 150%)`,
                }}
            >
                {!loadingNotifications ? (<div className="container d-flex flex-column justify-content-center" style={{ paddingTop: 48, width: '50vw', }}>
                    <h5 style={{ fontFamily: "Inter", fontWeight: '600', paddingBottom: 16 }}>Priority</h5>

                    {/* Iterate through the array of Priority Notifications */}
                    {priorityNotifications.map((notification, index) => (
                        <NotificationItem key={notification.id} notification={notification} onAction={handleNotificationAction} />
                    ))}

                    {/* Check the priorityNotifications length */}
                    {priorityNotifications.length == 0 && (
                        <>
                            <h6 style={{ textAlign: 'center', paddingBottom: 24 }}>No Priority Notifications are available right now</h6>
                        </>
                    )}

                    <h5 style={{ fontFamily: "Inter", fontWeight: '600', paddingBottom: 16 }}>Recent</h5>

                    {/* Iterate through the array of Recent Notifications */}
                    {recentNotifications.map((notification, index) => (
                        <NotificationItem key={notification.id} notification={notification} onAction={handleNotificationAction} />
                    ))}

                    {/* Check the recentNotifications length */}
                    {recentNotifications.length == 0 && (
                        <>
                            <h6 style={{ textAlign: 'center', paddingBottom: 24 }}>No Recent Notifications are available right now</h6>
                        </>
                    )}

                    <h5 style={{ fontFamily: "Inter", fontWeight: '600', paddingBottom: 16 }}>Earlier</h5>

                    {/* Iterate through the array of Earlier Notifications */}
                    {earlierNotifications.map((notification, index) => (
                        <NotificationItem key={notification.id} notification={notification} onAction={handleNotificationAction} />
                    ))}

                    {/* Check the earlierNotifications length */}
                    {earlierNotifications.length == 0 && (
                        <>
                            <h6 style={{ textAlign: 'center', paddingBottom: 24 }}>No Earlier Notifications are available right now</h6>
                        </>
                    )}

                </div>) : (
                    <>
                        <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', height: '90vh' }}>
                            <CircularProgress style={{ color: "#8562fc", marginTop: 12, marginBottom: 12 }} />
                            <h6>Updating Notifications</h6>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default NotificationsView;
