import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import HeaderManagerEmp from "../../../sub-components/managerHeaders/HeaderManagerEmp";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import EmployeeCard from "../../../sub-components/managerEmpSub/EmployeeCard";
import EmpRemoveModal from "../../../sub-components/managerEmpSub/EmpRemoveModal";
import url from "../../../../Development.json";
import Http from "../../../security/Http";
import { errorResponse } from "../../../Helpers/response";
import AppLoader from "../../../sub-components/AppLoader";

const ManagerEmp = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [getEmpList, SetGetEmpList] = useState([]); //Get Employee Data
  const [getEmpItem, SetGetEmpItem] = useState([]);
  const [empSearch, setEmpSearch] = useState("");

  const handleEmployeeOnSearch = (ev) => {
    setEmpSearch(ev.target.value);
    let emp_search = ev.target.value;
    let search = { search: emp_search };
    Http.callApi(url.get_new_employee, search)
      .then((response) => {
        SetGetEmpList(response.data);
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  function updateEmployees() {
    Http.callApi(url.get_new_employee)
      .then((response) => {
        SetGetEmpList(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.message) {
          errorResponse(error.message);
        } else {
          errorResponse("Please Try Again!");
        }
      });
  }

  useEffect(() => {
    setIsLoading(true);
    Http.callApi(url.get_new_employee)
      .then((response) => {
        console.log(response);
        SetGetEmpList(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.message) {
          errorResponse(error.message);
        } else {
          errorResponse("Please Try Again!");
        }
      });
  }, []);

  const empItems = (Emp) => {
    SetGetEmpItem(Emp);
  };
  return (
    <>
      <HeaderManagerEmp length={getEmpList.length} />
      <div className="container">
        <header className="que_active_header">
          <div className="mb-4 mb-lg-0">
            <h4 className="app_heading_24_bold text-nowrap">
              People ({getEmpList.length})
            </h4>
          </div>
          <div className="d-flex justify-content-between justify-content-lg-end w-100">
            <div className="app_searchInputWrapper me-4">
              <span className="app_search_icon_wrapper">
                <SearchOutlinedIcon style={{ fontSize: 20 }} />
              </span>
              <input
                type="text"
                onChange={(ev) => handleEmployeeOnSearch(ev)}
                value={empSearch}
                placeholder="Search People"
              />
            </div>
            <Link
              to="/manager/create-emp"
              className="app_primary_btn create_que_btn"
            >
              <AddCircleOutlineOutlinedIcon />
              <span className="add_btn_with_icon_text">Create a Person</span>
            </Link>
          </div>
        </header>

        <div className="row row-cols-1 gy-4">
          {getEmpList.length !== 0 ? (
            getEmpList.map((Emp) => (
              <EmployeeCard
                key={Emp._id}
                empId={Emp._id}
                item={Emp}
                empFirstname={Emp.firstname}
                empLastname={Emp.lastname}
                empCompanyposition={Emp.company_position}
                empMobile={Emp.mobile}
                empEmail={Emp.email}
                empGender={Emp.gender}
                empImage={Emp.profile_image}
                empItem={empItems}
              />
            ))
          ) : (
            <span className="validation_text_invalid">
              You currently haven't added anyone to your Team
            </span>
          )}
        </div>
      </div>
      <EmpRemoveModal empItem={getEmpItem} onDelete={updateEmployees} />
    </>
  );
};

export default ManagerEmp;
