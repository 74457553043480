import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import EmployeeMoodSelector from "../../sub-components/employeeSub/EmployeeMoodSelector";
import SingleChoiceQuestionModule from "../../sub-components/employeeSub/SingleChoiceQuestionModule";
import MultiChoiceQuestionModule from "../../sub-components/employeeSub/MultiChoiceQuestionModule";
import TextQuestionModule from "../../sub-components/employeeSub/TextQuestionModule";
import QueSubmitConfirmation from "../../sub-components/employeeSub/QueSubmitConfirmation";
import { useLocation } from "react-router-dom";
import { upload } from "@testing-library/user-event/dist/upload";
import url from "../../../Development.json";
import Http from "../../security/Http";
import { SignalCellularNullSharp } from "@mui/icons-material";

const Questionnaire = (props) => {
  const location = useLocation();
  const questions = location.state?.questionnaire;
  const numerOfQuestions = questions.question.length;
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [selectedMood, setSelectedMood] = useState(2);
  const [singleChoiceAnswer, setSingleChoiceAnswer] = useState("");
  const [multiChoiceAnswer, setMultiChoiceAnswer] = useState("");
  const [textAnswer, setTextAnswer] = useState("");
  const [isBtnActive, setIsBtnActive] = useState(false);
  const [formData, setFormData] = useState(null);
  // const [formData, setFormData] = useState({
  //   selectedMood: "",
  //   singleChoiceAnswer: [],
  //   multiChoiceAnswer: [],
  //   textAnswer: "",
  // });

  const handleMoodOnChange = (e) => {
    console.log(e);
    setSelectedMood(e);
  };

  const handleQueSkipBtn = () => {
    if (activeQuestion < numerOfQuestions) {
      setActiveQuestion((prev) => prev + 1);
    }
  };
  const singleChoiceQueFunc = (val) => {
    setSingleChoiceAnswer(val);
  };
  const multiChoiceQueFunc = (val) => {
    setMultiChoiceAnswer(val);
  };
  const textAnswerFunc = (val) => {
    setTextAnswer(val);
  };

  useEffect(() => {
    console.log("ACTIVE QUESTIONNAIRE: ", questions);
    console.log("LATEST QUESTION: ", questions.question[activeQuestion]);
  }, []);

  const uploadAnswer = () => {
    var answer = {};
    if (questions.question[activeQuestion].type === 0 && selectedMood) {
      answer[questions.question[activeQuestion]._id] = [selectedMood];
    } else if (
      questions.question[activeQuestion].type === 1 &&
      singleChoiceAnswer
    ) {
      answer[questions.question[activeQuestion]._id] = [singleChoiceAnswer];
    } else if (
      questions.question[activeQuestion].type === 2 &&
      multiChoiceAnswer.length !== 0
    ) {
      answer[questions.question[activeQuestion]._id] = multiChoiceAnswer;
    } else if (
      questions.question[activeQuestion].type === 3 &&
      textAnswer !== ""
    ) {
      answer[questions.question[activeQuestion]._id] = [textAnswer];
    }

    var answerData = {};
    answerData = { ...formData, ...answer };
    console.log("ANSWER DATA", answerData);
    setFormData(answerData);
    setTimeout(() => {
      setActiveQuestion((prev) => {
        if (prev < numerOfQuestions) {
          setActiveQuestion((prev) => prev + 1);
        }
        return prev;
      });
      if (activeQuestion + 1 === numerOfQuestions) {
        sendAnswer(answerData);
      }
    }, 500);
  };

  const sendAnswer = (answerData) => {
    var submissionData = {
      question_id: questions._id,
      answer: answerData,
    };
    console.log("SUBMISSION DATA", submissionData);

    Http.callApi(url.post_question, submissionData)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (
      !questions ||
      !questions.question ||
      !Array.isArray(questions.question) ||
      questions.question.length === 0 ||
      activeQuestion < 0 ||
      activeQuestion >= questions.question.length
    ) {
      setIsBtnActive(false);
      return;
    }

    const questionType = questions.question[activeQuestion].type;

    if (questionType === 0 && selectedMood !== "Select Mood") {
      setIsBtnActive(true);
    } else if (questionType === 1 && singleChoiceAnswer !== "") {
      setIsBtnActive(true);
    } else if (questionType === 2 && multiChoiceAnswer.length !== 0) {
      setIsBtnActive(true);
    } else if (questionType === 3 && textAnswer !== "") {
      setIsBtnActive(true);
    } else {
      setIsBtnActive(false);
    }
  }, [
    activeQuestion,
    selectedMood,
    singleChoiceAnswer,
    multiChoiceAnswer,
    textAnswer,
  ]);

  const renderQuestionModule = () => {
    if (
      !questions ||
      !questions.question ||
      !Array.isArray(questions.question) ||
      questions.question.length === 0 ||
      activeQuestion < 0 ||
      activeQuestion >= questions.question.length
    ) {
      return <QueSubmitConfirmation />;
    }

    const questionType = questions.question[activeQuestion].type;

    switch (questionType) {
      case 0:
        return (
          <EmployeeMoodSelector
            moodOnChange={handleMoodOnChange}
            selectedMood={selectedMood}
          />
        );
      case 1:
        return (
          <SingleChoiceQuestionModule
            question={questions.question[activeQuestion]}
            onChangeFunc={singleChoiceQueFunc}
          />
        );
      case 2:
        return (
          <MultiChoiceQuestionModule
            question={questions.question[activeQuestion]}
            onChangeFunc={multiChoiceQueFunc}
          />
        );
      case 3:
        return (
          <TextQuestionModule
            question={questions.question[activeQuestion]}
            onChangeFunc={textAnswerFunc}
          />
        );
      default:
        return <QueSubmitConfirmation />;
    }
  };

  return (
    <section className="que_main_wrapper">
      <div className="que_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-9 col-lg-7 col-xl-6 mx-auto d-flex flex-column pb-3 mt-4 justify-content-center">
              {questions.question.length > activeQuestion + 1 ? (
                <div
                  className="que_progress_header"
                  style={{ display: "flex" }}
                >
                  <div className="d-flex text-nowrap">
                    <span className="app_text_bold" style={{ fontWeight: 700 }}>
                      {activeQuestion}
                    </span>
                    <span
                      className="app_text text-secondary ms-1"
                      style={{ fontWeight: 700 }}
                    >
                      <span className="me-1">of</span>
                      {questions.question.length}
                    </span>
                  </div>
                  <div className="que_progress_circle_container">
                    <CircularProgress
                      size="1.2rem"
                      sx={{ color: "#27AE60", marginLeft: 1 }}
                      variant="determinate"
                      value={(100 / numerOfQuestions) * (activeQuestion + 1)}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
              {renderQuestionModule()}
            </div>
          </div>
        </div>
      </div>
      {activeQuestion < questions.question.length && (
        <div className="d-flex flex-column mt-auto ">
          <hr className="app_divider" />
          <div className="que_progress_footer">
            <div className="container">
              <div className="row">
                <div className="col-md-9 col-lg-7 col-xl-6 mx-auto mt-4 d-flex">
                  {activeQuestion > 1 && (
                    <button
                      onClick={() => setActiveQuestion((prev) => prev - 1)}
                      className="app_secondary_btn"
                    >
                      Back
                    </button>
                  )}
                  <div className="d-flex ms-auto">
                    <button
                      type={activeQuestion === 4 ? "submit" : "button"}
                      onClick={() => handleQueSkipBtn()}
                      className="app_blank_btn me-4 app_text_bold text-capitalize"
                    >
                      {activeQuestion === 4 ? "skip & submit" : "skip"}
                    </button>
                    {
                      <button
                        type={
                          activeQuestion + 1 === questions.question.length
                            ? "submit"
                            : "button"
                        }
                        onClick={() => uploadAnswer()}
                        className={`app_primary_btn text-capitalize ${
                          isBtnActive ? "" : "disabled"
                        }`}
                      >
                        {activeQuestion + 1 === questions.question.length
                          ? "submit"
                          : "next"}
                      </button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Questionnaire;
