import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import phoneImg from "../../../assets/images/actions/support_connect.png";
import notifyImg from "../../../assets/images/actions/notify_support_person.png";
import fitnessImg from "../../../assets/images/actions/exercise.png";
import lotusImg from "../../../assets/images/actions/mindfulness.png";
import virtualCommute from "../../../assets/images/actions/virtual_commute.png";
import saladImg from "../../../assets/images/actions/eat_well.png";
import careImg from "../../../assets/images/wellnessIcons/care.png";
import url from "../../../Development.json";
import { errorResponse, successResponse } from "../../Helpers/response";
import Http from "../../security/Http";
import { ToastContainer } from "react-toastify";
import NotifySupportPersonModal from "../../sub-components/NotifySupportPersonModal";

const EmployeeWellness = () => {
  const [pageTitle, setPageTitle] = useState("");
  const [empProfileData, setEmpProfileData] = useState([]);

  useEffect(() => {
    getPageTitle();
    profileData();
  }, []);

  const profileData = () => {
    Http.callApi(url.user_profile)
      .then((response) => {
        console.log(response);
        setEmpProfileData(response.data);
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  const getPageTitle = () => {
    const data = {
      page: "wellbeing",
    };
    Http.callApi(url.get_page_title, data)
      .then((response) => {
        console.log(response.data);
        setPageTitle(response.data.heading);
      })
      .catch((error) => {
        console.log("PAGE TITLE", error);
        if (error.message) {
          errorResponse(error.message);
        } else {
          errorResponse("Please Try Again!");
        }
      });
  };

  return (
    <div
      className="emp_home_wrapper"
      style={{
        height: "90vh",
        background: `linear-gradient(45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 45%, ${
          empProfileData.company
            ? empProfileData.company.company_primary_color
            : "rgba(81, 83, 245, 1)"
        } 150%)`,
      }}
    >
      <ToastContainer />
      <div className="container h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-xl-8 pb-4 text-center">
            <h4 className="app_heading mb-5 mt-5 mt-md-0 text-center justify-content-center">
              {pageTitle ? pageTitle : "Take Control of Your Wellbeing"}
            </h4>
            <div
              className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 align-items-center g-2 g-md-4"
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              <div className="col">
                <Link
                  to="/wellness/eatwell"
                  className="app_card wellness_card_link"
                  style={{ justifyContent: "center", textDecoration: "unset" }}
                >
                  <div
                    className="wellness_icon_container"
                    style={{ justifyContent: "center", textAlign: "center" }}
                  >
                    <img
                      src={saladImg}
                      className="img-fluid mt-3"
                      alt="wellness"
                      style={{ height: 92, width: 92 }}
                    />
                  </div>
                  <h4 className="app_text_16_bold mt-3 mb-3 text-center">
                    Eat Well
                  </h4>
                </Link>
              </div>

              <div className="col" style={{ justifyContent: "center" }}>
                <Link
                  to="/wellness/exercise"
                  className="app_card wellness_card_link"
                  style={{ justifyContent: "center", textDecoration: "unset" }}
                >
                  <div
                    className="wellness_icon_container "
                    style={{ justifyContent: "center", textAlign: "center" }}
                  >
                    <img
                      src={fitnessImg}
                      className="img-fluid mt-3"
                      alt="wellness"
                      style={{ height: 92, width: 92 }}
                    />
                  </div>
                  <h4 className="app_text_14_bold mt-3 mb-3 text-center">
                    Exercise
                  </h4>
                </Link>
              </div>

              <div className="col">
                <Link
                  to="/wellness/mindfullness"
                  className="app_card wellness_card_link"
                  style={{ justifyContent: "center", textDecoration: "unset" }}
                >
                  <div
                    className="wellness_icon_container"
                    style={{ justifyContent: "center", textAlign: "center" }}
                  >
                    <img
                      src={lotusImg}
                      className="img-fluid mt-3"
                      alt="wellness"
                      style={{ height: 92, width: 92 }}
                    />
                  </div>
                  <h4 className="app_text_14_bold mt-3 mb-3 text-center">
                    Mindfulness
                  </h4>
                </Link>
              </div>

              <div className="col">
                <Link
                  to="/wellness/virtual-commute"
                  className="app_card wellness_card_link"
                  style={{ justifyContent: "center", textDecoration: "unset" }}
                >
                  <div
                    className="wellness_icon_container"
                    style={{ justifyContent: "center", textAlign: "center" }}
                  >
                    <img
                      src={virtualCommute}
                      className="img-fluid mt-3"
                      alt="wellness"
                      style={{ height: 92, width: 92 }}
                    />
                  </div>
                  <h4 className="app_text_14_bold mt-3 mb-3 text-center">
                    Virtual Commute
                  </h4>
                </Link>
              </div>

              <div className="col" style={{ justifyContent: "center" }}>
                <Link
                  to="/wellness/support-connect"
                  className="app_card wellness_card_link"
                  style={{ justifyContent: "center", textDecoration: "unset" }}
                >
                  <div
                    className="wellness_icon_container"
                    style={{ justifyContent: "center", textAlign: "center" }}
                  >
                    <img
                      src={phoneImg}
                      className="img-fluid mt-3"
                      alt="wellness"
                      style={{ height: 92, width: 92 }}
                    />
                  </div>
                  <h4 className="app_text_14_bold mt-3 mb-3 text-center">
                    Support Connect
                  </h4>
                </Link>
              </div>

              <div className="col" style={{ justifyContent: "center" }}>
                <a
                  data-bs-toggle="modal"
                  data-bs-target={`#notifySupportPersonModal`}
                  className="app_card wellness_card_link"
                  style={{ justifyContent: "center", textDecoration: "unset" }}
                >
                  <div
                    className="wellness_icon_container"
                    style={{ justifyContent: "center", textAlign: "center" }}
                  >
                    <img
                      src={notifyImg}
                      className="img-fluid mt-3"
                      alt="wellness"
                      style={{ height: 100, width: 100 }}
                    />
                  </div>
                  <h4 className="app_text_14_bold mt-3 text-center">
                    Notify Support Person
                  </h4>
                </a>
              </div>
              <NotifySupportPersonModal />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeWellness;
