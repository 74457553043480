import React, { useEffect } from 'react';
import { format as timeAgo } from 'timeago.js'; // Make sure to install timeago.js or an equivalent


const NotificationEnums = {
    getColor: (notificationType) => {
        switch (notificationType) {
            case 1:
                return '#27AE60';
            case 2:
                return '#5153F5';
            case 3:
                return '#DFA479';
            case 4:
                return '#B9B300';
            case 5:
                return '#757575';
            default:
                return '#27AE60';
        }
    },
    getImage: (notificationType) => {
        switch (notificationType) {
            case 1:
                return require('../assets/images/notificationActions/ic_checkin.png');
            case 2:
                return require('../assets/images/notificationActions/ic_requested_checkin.png');
            case 3:
                return require('../assets/images/notificationActions/ic_support_person_action.png');
            case 4:
                return require('../assets/images/notificationActions/ic_negative_feeling.png');
            case 5:
                return require('../assets/images/notificationActions/ic_question.png');
            default:
                return require('../assets/images/notificationActions/ic_checkin.png');
        }
    },
    getAction: (notificationType) => {
        switch (notificationType) {
            case 1:
                return 'Check-in';
            case 2:
                return 'Contact';
            case 4:
                return 'Review';
            case 5:
                return 'Answer';
            default:
                return 'Check-in';
        }
    }
}

const NotificationItem = ({ notification, onAction }) => {
    const notificationData = notification;
    const notificationColor = NotificationEnums.getColor(notificationData.notification_type);
    const notificationImage = NotificationEnums.getImage(notificationData.notification_type);
    const notificationAction = NotificationEnums.getAction(notificationData.notification_type);

    const handleNotificationClick = () => {
        console.log(`Tapped Action Button: ${notificationData.requestedUser}`);
        onAction(notificationData);

        setTimeout(() => {
            // Implement navigation logic here
            // This will depend on the routing library you're using in React (e.g., react-router)
        }, 1000);
    };

    useEffect(() => {
        console.log("NOTIFICATION DATA: ", notificationData);
    }, []);

    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="notification-item" style={{ marginBottom: '16px', backgroundColor: 'white', width: '100%', borderWidth: 1, borderColor: "#e3e3e3", borderRadius: 12, justifyContent: 'flex-start', display: 'flex', alignItems: 'center', paddingLeft: 24, paddingRight: 24, paddingTop: 12, paddingBottom: 12 }}>
                <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
                    <div style={{
                        width: 48,
                        height: 48,
                        objectFit: 'contain',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        backgroundColor: `${notificationColor}70`,
                        borderRadius: '50%',
                    }}>
                        <img src={notificationImage} alt="Notification" style={{ fill: `${notificationColor}`, width: 38, height: 38 }} />
                    </div>
                    <div style={{ paddingLeft: 12, width: '36vw' }}>
                        <p className="pb-0 mb-0">{notificationData.notification_content}</p>
                        <p className="pb-0 mb-0" style={{ fontSize: 12 }}>{timeAgo(notificationData.createdAt)}</p>
                    </div>
                </div>
                {notification.notification_type !== 3 && (<div className="notification-content" style={{ float: 'right', width: 164, textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex', backgroundColor: `${notificationColor}40`, borderRadius: 12, paddingTop: 8, paddingBottom: 8 }}>

                    <div className="notification-action" style={{ cursor: 'pointer' }} onClick={handleNotificationClick}>
                        <p className="notification-action-text pb-0 mb-0" style={{ color: notificationColor, fontSize: 14 }}>{notificationAction}</p>
                    </div>
                </div>
                )}
            </div>
        </div>
    );
};

export default NotificationItem;
