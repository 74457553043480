import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Http from "../../../security/Http";
import url from "../../../../Development.json";
import { errorResponse } from "../../../Helpers/response";
import sad from "../../../../assets/images/moods/Sad.png";
import unhappy from "../../../../assets/images/moods/Unhappy.png";
import okay from "../../../../assets/images/moods/Neutral.png";
import happy from "../../../../assets/images/moods/Happy.png";
import elated from "../../../../assets/images/moods/Excited.png";

import { DatePicker } from "antd";
import { CircularProgress } from "@mui/material";

const ManagerCal = () => {
  const [type1, setType1] = useState("0%");
  const [type2, setType2] = useState("0%");
  const [type3, setType3] = useState("0%");
  const [type4, setType4] = useState("0%");
  const [type5, setType5] = useState("0%");

  const [statusMessage, setStatusMessage] = useState("Updating Dashboard Statistics");

  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const [selectedDate, setSelectedDate] = useState(null);

  const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };

  function handleDateChange(date) {
    setStatusMessage("Updating Dashboard Statistics");
    setIsLoading(true);

    // Create a date object from the selected date
    const dateData = new Date(date);

    // Get the local date in 'YYYY-MM-DD' format
    const convertedDateData = dateData.toLocaleDateString('en-CA'); // 'en-CA' uses the 'YYYY-MM-DD' format

    setSelectedDate(convertedDateData);
    console.log("SELECTED DATE:", convertedDateData);

    // Get today's date in the local timezone and format it to 'YYYY-MM-DD'
    const today = new Date();
    const todayString = today.toLocaleDateString('en-CA');

    console.log("TODAY'S DATE:", todayString);

    if (convertedDateData === todayString) {
      console.log("Date is Today");
      getTodaysCheckinData();
    } else {
      console.log("Date is not Today");
      setTimeout(() => {
        getPastCheckinData(convertedDateData);
      }, 500);
    }
  }

  useEffect(() => {
    getTodaysCheckinData();
  }, []);

  const getPastCheckinData = (date) => {
    Http.callApi(url.get_dashboard_past_checkins, {
      'date': date,
    })
      .then((response) => {
        if (response.data[0]) {
          setType1(response.data[0].percentage);
        }
        if (response.data[1]) {
          setType2(response.data[1].percentage);
        }
        if (response.data[2]) {
          setType3(response.data[2].percentage);
        }
        if (response.data[3]) {
          setType4(response.data[3].percentage);
        }
        if (response.data[4]) {
          setType5(response.data[4].percentage);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("DATA ERROR:", error);
        setStatusMessage("Statistics are not available for this date. Please try another date.");
        if (error.message) {
          errorResponse(error.message);
        } else {
          errorResponse("Please Try Again!");
        }
      });
  };


  const getTodaysCheckinData = (date) => {
    Http.callApi(url.get_team_overview, {})
      .then((response) => {
        if (response.data[0]) {
          setType1(response.data[0].percentage);
        }
        if (response.data[1]) {
          setType2(response.data[1].percentage);
        }
        if (response.data[2]) {
          setType3(response.data[2].percentage);
        }
        if (response.data[3]) {
          setType4(response.data[3].percentage);
        }
        if (response.data[4]) {
          setType5(response.data[4].percentage);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("DATA ERROR:", error);
        setStatusMessage("An error occurred while updating todays statistics.");
        if (error.message) {
          errorResponse(error.message);
        } else {
          errorResponse("Please Try Again!");
        }
      });
  };

  return (
    <>
      <header className="manager_profile_header">
        <h4 className="app_heading">Check-in Dashboard</h4>
      </header>

      <div
        className="container-fluid"
        style={{
          width: isMobile() ? 'unset' : "60%",
          height: "70%",
          alignItems: "center",
          textAlign: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div className="col justify-content-center mt-4 mb-4">
          <h6 className="app_text_secondary_gray">Filter by Date</h6>
          <div
            className="que_type_dropdown_wrapper"
            style={{
              textAlign: "center",
              width: "350px",
              display: "inline-block",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <DatePicker
              className="app_dropDownBtn py-3"
              selected={selectedDate}
              disabledDate={(current) => {
                return current && current > new Date();
              }}
              allowClear={false}
              onChange={handleDateChange}
              format="DD MMMM yyyy"
            />
          </div>
        </div>
        {!isMobile() ? !isLoading ? (<div className="row justify-content-center">
          <div className="mood_flex_row d-flex align-items-center gap-3 mb-0" style={{ justifyContent: 'center' }}>
            <div classsName="mood_flex_item">
              <h6 className="text-center mb-2">Sad</h6>
              <img src={sad} className="img-fluid mb-2" style={{ width: '16vw' }} alt="" />
              <p className="text-center mb-0">{type1}</p>
            </div>

            <div classsName="mood_flex_item">
              <h6 className="text-center mb-2">Unhappy</h6>
              <img src={unhappy} className="img-fluid mb-2" style={{ width: '16vw' }} alt="" />
              <p className="text-center mb-0">{type2}</p>
            </div>

            <div classsName="mood_flex_item">
              <h6 className="text-center mb-2">Okay</h6>
              <img src={okay} className="img-fluid mb-2" style={{ width: '16vw' }} alt="" />
              <p className="text-center mb-0">{type3}</p>
            </div>

            <div classsName="mood_flex_item">
              <h6 className="text-center mb-2">Happy</h6>
              <img src={happy} className="img-fluid mb-2" style={{ width: '16vw' }} alt="" />
              <p className="text-center mb-0">{type4}</p>
            </div>

            <div classsName="mood_flex_item">
              <h6 className="text-center mb-2">Elated</h6>
              <img src={elated} className="img-fluid mb-2" style={{ width: '16vw' }} alt="" />
              <p className="text-center mb-0">{type5}</p>
            </div>
          </div>
        </div>) : (
          <>
            <div>
              <CircularProgress style={{ color: "#8562fc", marginTop: 12, marginBottom: 12 }} />
              <h6>{statusMessage}</h6>
            </div>
          </>
        ) : (<></>)}

        {isMobile() ? !isLoading ? (<div className="col justify-content-center">
          <div className="align-items-center gap-3 mb-0" style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div classsName="mood_flex_item" style={{ paddingRight: '24px' }}>
                <h6 className="text-center mb-2">Sad</h6>
                <img src={sad} className="img-fluid" style={{ width: '24vw' }} alt="" />
                <p className="text-center mb-0">{type1}</p>
              </div>

              <div classsName="mood_flex_item">
                <h6 className="text-center mb-2">Unhappy</h6>
                <img src={unhappy} className="img-fluid" style={{ width: '24vw' }} alt="" />
                <p className="text-center mb-0">{type2}</p>
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div classsName="mood_flex_item" style={{ paddingRight: '24px' }}>
                <h6 className="text-center">Okay</h6>
                <img src={okay} className="img-fluid mb-2" style={{ width: '24vw' }} alt="" />
              <p className="text-center mb-0">{type3}</p>
            </div>

            <div classsName="mood_flex_item">
              <h6 className="text-center mb-2">Happy</h6>
                <img src={happy} className="img-fluid mb-2" style={{ width: '24vw' }} alt="" />
              <p className="text-center mb-0">{type4}</p>
            </div>
            </div>




            <div classsName="mood_flex_item">
              <h6 className="text-center mb-2">Elated</h6>
              <img src={elated} className="img-fluid mb-2" style={{ width: '24vw' }} alt="" />
              <p className="text-center mb-0">{type5}</p>
            </div>
          </div>
        </div>) : (
          <>
            <div>
              <CircularProgress style={{ color: "#8562fc", marginTop: 12, marginBottom: 12 }} />
              <h6>{statusMessage}</h6>
        </div>
          </>
        ) : (<></>)}
      </div>
    </>
  );
};

export default ManagerCal;
