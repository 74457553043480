import React, { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";

const MultiChoiceQuestionModule = (props) => {
  const [value, setValue] = useState([]);

  const handleChange = (event) => {
    let textVal = event.target.value;
    setValue((prev) => {
      if (prev.indexOf(textVal) > -1) {
        return prev.filter((item) => item !== textVal);
      } else {
        return [...prev, textVal];
      }
    });
  };

  useEffect(() => {
    console.log("ACTIVE LATEST CHOICE: ", props.question);
    props.onChangeFunc(value);
  });

  return (
    <>
      <h4 className="app_text_secondary_gray text-uppercase">Question</h4>
      <h4 className="app_heading_20_bold mt-3 mb-4">{props.question.title}</h4>
      <div className="answer_wrapper">
        <h4 className="app_text_primary mb-4 text-uppercase">Select Answer</h4>
        <div className="row row-cols-1 g-3">
          {props.question.options.map((option) => (
            <div className="col">
              <div className="choice_answer_container">
                <div>
                  <Checkbox
                    checked={value.indexOf(option) > -1}
                    value={option}
                    onChange={handleChange}
                    id="empMultiChoiceOpt1"
                  />
                  <label className="ms-2" htmlFor="empMultiChoiceOpt1">
                    {option}
                  </label>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default MultiChoiceQuestionModule;
