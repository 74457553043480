import React, { useState, useRef, useEffect } from "react";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Link } from "react-router-dom";

const QuestionnaireActiveCard = (props) => {
  let numberOfResponses = props.queResponses ? props.queResponses : 0;
  let queTimeout = props.queTimeout;
  let queTitle = props.queTitle;
  const [day, setDay] = useState(0);
  const [hours, setHours] = useState(0);

  const managerQueCardSubMenuRef = useRef(null);
  const managerQueCardSubmenuBtn = useRef(null);
  const [showManagerQueCardSubMenu, setShowManagerQueCardSubMenu] =
    useState(false);

  const handleManagerQueCardSubMenuOnClick = () => {
    setShowManagerQueCardSubMenu(!showManagerQueCardSubMenu);
  };

  let currentDate = new Date();
  let endDate = new Date(queTimeout);
  function getDifferenceInHours(current_date, end_date) {
    const diffInMs = Math.abs(end_date - current_date);
    let hours = diffInMs / (1000 * 60 * 60);
    let day = Math.floor(hours / 24);
    let ph = Math.floor(hours - day * 24);
    setDay(day);
    setHours(ph);
  }

  useEffect(() => {
    console.log(props.questionId);
    showManagerQueCardSubMenu
      ? managerQueCardSubMenuRef.current.classList.add("show")
      : managerQueCardSubMenuRef.current.classList.remove("show");
  }, [showManagerQueCardSubMenu]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        managerQueCardSubMenuRef.current &&
        !managerQueCardSubMenuRef.current.contains(event.target) &&
        !managerQueCardSubmenuBtn.current.contains(event.target)
      ) {
        setShowManagerQueCardSubMenu(false);
      }
    }
    if (showManagerQueCardSubMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    }
  }, [
    managerQueCardSubMenuRef,
    showManagerQueCardSubMenu,
    managerQueCardSubmenuBtn,
  ]);

  useEffect(() => {
    getDifferenceInHours(currentDate, endDate);
  }, [numberOfResponses]);

  return (
    <div className="col">
      <div className="manager_que_card">
        <div className="que_card_left">
          <h4 className="que_card_title text-capitalize">{queTitle}</h4>
          <div className="d-flex que_card_left_detail_container mt-4 mt-md-0">
            <div className="d-flex align-items-center me-4 mb-3 mb-md-0">
              <ForumOutlinedIcon
                style={{ color: "#9997A6", fontSize: 20, marginRight: "10px" }}
              />
              <span className="me-1">Responses:</span>
              <span className="fw-bold">{numberOfResponses}</span>
            </div>
            <div className="d-flex align-items-center">
              <WatchLaterOutlinedIcon
                style={{ color: "#9997A6", fontSize: 20, marginRight: "10px" }}
              />

              <span className="me-1">Time Remaining:</span>
              <span className="fw-bold">
                {day} days {hours} hours
              </span>
            </div>
          </div>
        </div>
        <div className="que_card_right">
          <Link
            to="/manager/view-response"
            state={{ questionId: props.questionId }}
            className="app_secondary_btn que_card_view_response_btn"
          >
            View Responses
          </Link>
          <div className="position-relative">
            <button
              ref={managerQueCardSubmenuBtn}
              onClick={() => handleManagerQueCardSubMenuOnClick()}
              className="app_blank_btn ms-3"
            >
              <MoreVertOutlinedIcon style={{ fontSize: 32 }} />
            </button>
            <div
              ref={managerQueCardSubMenuRef}
              className="manager_que_card_submenu_container"
            >
              <ul className="manager_que_card_submenu">
                {/* <li>
                  <button
                    type="button"
                    className="manager_que_card_submenu_btn"
                    onClick={() => props.queItems(props)}
                    data-bs-toggle="modal"
                    data-bs-target="#quickViewQueModal"
                  >
                    <RemoveRedEyeOutlinedIcon style={{ marginRight: 15 }} />
                    View Questions
                  </button>
                </li> */}
                <li>
                  <button
                    className="manager_que_card_submenu_btn"
                    onClick={() => props.queItems(props)}
                    data-bs-toggle="modal"
                    data-bs-target="#queRemoveModal"
                  >
                    <DeleteOutlinedIcon style={{ marginRight: 15 }} />
                    Remove Questionnaire
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionnaireActiveCard;
