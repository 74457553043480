import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import AuthManagerVerifyRoute from "./AuthManagerVerifyRoute";
import AuthEmployeeVerifyRoute from "./AuthEmployeeVerifyRoute";
import { EmployeeRoutes } from './AppRoutes'
import { ManagerRoutes } from './AppRoutes'
import LayoutEmployee from "../layouts/LayoutEmployee";
import LayoutManager from '../layouts/LayoutManager';

const AppRouter = () => {

    return (
        //Routes
        <Router>
            <Routes>
                {EmployeeRoutes.map(({ path, auth, element: Component, ...value }, key) => {
                    return (
                        auth && auth === true ?
                            <Route key={key} path="/" element={<AuthEmployeeVerifyRoute isAuthenticated={auth} />}>
                                <Route key={key} path="/" element={<LayoutEmployee />}>
                                    <Route exact key={key} path={path} element={Component} />
                                </Route>
                            </Route>
                            :
                            <Route key={key} path="/" element={<LayoutEmployee />} >
                                <Route exact key={key} path={path} element={Component} />
                            </Route>
                    )
                })
                }
                {ManagerRoutes.map(({ path, auth, element: Component, ...value }, key) => {
                    return (
                        auth === true ?
                            <Route key={key} path="/" element={<AuthManagerVerifyRoute isAuthenticated={auth} />}>
                                <Route key={key} path="/" element={<LayoutManager />}>
                                    <Route exact key={key} path={path} element={Component} />
                                </Route>
                            </Route>
                            :
                            <Route key={key} path="/" element={<LayoutManager />} >
                                <Route exact key={key} path={path} element={Component} />
                            </Route>
                    )
                })
                }
            </Routes>
        </Router >
    )
}

export default AppRouter