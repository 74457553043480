import React, { useState } from 'react'
import HeaderManagerQue from '../../../sub-components/managerHeaders/HeaderManagerQue';
import ManagerQueActive from './ManagerQueActive';
import ManagerQuePrevious from './ManagerQuePrevious';
import ManagerQueDraft from './ManagerQueDraft';

const ManagerQue = () => {
    const [currentQueElem, setCurrentQueElem] = useState('active');
    const handleQueElemOnChange = (ev) => {
        setCurrentQueElem(ev.target.value);
    }
    return (
        <>
            <HeaderManagerQue 
            activeModule={currentQueElem} 
            moduleChangeFunc={handleQueElemOnChange} />
            {currentQueElem === 'active' ? 
            <ManagerQueActive /> : currentQueElem === 'prev' ? <ManagerQuePrevious /> : <ManagerQueDraft />}
        </>
    )
}

export default ManagerQue