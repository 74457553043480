import React, { useEffect, useState } from "react";
import { Result } from "antd";
import { MailOutlined } from "@ant-design/icons";
import PinInput from "react-pin-input";
import { useLocation, useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import url from "../../../Development.json";
import {
  errorResponse,
  successResponse,
  isError,
} from "../../Helpers/response.js";
import Http from "../../security/Http";
import { Link } from "react-router-dom";

const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [enteredOtp, setEnteredOtp] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    data["mobile"] = location.state.mobile;

    Http.callApi(url.submit_new_password, JSON.stringify(data))
      .then((res) => {
        successResponse(res.data.message);
        setTimeout(() => {
          navigate("/login", {
            state: {
              otp: res.data.otp,
              mobile: data.mobile,
              message:
                "Your Password has been successfully updated. You can now login to Mind Pulse",
            },
          });
        }, 1000);
      })
      .catch((error) => {
        errorResponse("Please Try Again!");
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  useEffect(() => {
    isError(errors);
  }, []);

  return (
    <section className="auth_wrapper">
      <div className="container-fluid p-0">
        <div className="row recovery_box_row">
          <div className="col-lg-6 d-flex">
            <div className="account_recovery_box">
              <h4 className="app_heading">Reset your Password</h4>
              <p className="app_text mt-2 mb-4">
                Now that you're verified, please enter your new password below
              </p>
              <div className="login_form_wrapper">
                <form
                  className="loginBox_form"
                  autoComplete="off"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="row g-4 mb-0">
                    <div className="col-12">
                      <div className="app_textInputWrapper">
                        <input
                          id="password"
                          className="app_textInput"
                          type="password"
                          placeholder=" "
                          {...register("password", { required: true })}
                        />
                        <label
                          htmlFor="password"
                          className="appInput_Placeholder"
                        >
                          New Password
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row g-2 mt-1">
                    <div className="col-12">
                      <div className="app_textInputWrapper">
                        <input
                          id="confirmPassword"
                          className="app_textInput"
                          type="password"
                          placeholder=" "
                          {...register("confirmPassword", { required: true })}
                        />
                        <label
                          htmlFor="confirmPassword"
                          className="appInput_Placeholder"
                        >
                          Confirm New Password
                        </label>
                      </div>
                    </div>
                  </div>
                  <button className="app_primary_btn w-100 mt-4">
                    Request a Password Reset
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-6 d-none d-lg-block">
            <div className="login_image_container"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;