import React, { useEffect, useState } from "react";
import userPlaceholder from "../../../../assets/images/users/user-vector.png";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import { Link } from "react-router-dom";
import Http from "../../../security/Http";
import url from "../../../../Development.json";
import { errorResponse } from "../../../Helpers/response";
import { UserOutlined } from "@ant-design/icons";

const ManagerProfile = () => {
  const [mngProfileData, setMngProfileData] = useState([]);

  const profileData = () => {
    Http.callApi(url.user_profile)
      .then((response) => {
        console.log(response.data);
        setMngProfileData(response.data);
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  useEffect(() => {
    profileData();
  }, []);

  return (
    <>
      <header className="manager_profile_header">
        <h4 className="app_heading">Profile</h4>
      </header>
      <div className="container mt-5">
        <div className="row d-flex justify-content-center">
          <div className="col-12 col-md-10 col-lg-8">
            <div className="app_card">
              <div className="d-flex">
                <div className="d-flex">
                  <div className="d-flex align-items-center">
                    <div className="user_img_container">
                      <img
                        src={
                          mngProfileData.profile_image
                            ? mngProfileData.profile_image
                            : userPlaceholder
                        }
                        style={{
                          width: "96px",
                          height: "96px",
                          objectFit: "cover",
                        }}
                        alt="manager"
                        className="user_edit_img"
                      />
                    </div>
                    <h4 className="app_text_20_bold mb-0 ms-3">
                      {mngProfileData.firstname} {mngProfileData.lastname}
                    </h4>
                  </div>
                </div>
                <div className="ms-auto d-flex align-items-center">
                  <Link
                    to="/manager/edit-profile"
                    state={{ profileData: mngProfileData }}
                    className="app_primary_btn create_que_btn"
                  >
                    <BorderColorOutlinedIcon
                      style={{ color: "#F6F6F9", fontSize: 20 }}
                    />
                    <span className="add_btn_with_icon_text">Edit Profile</span>
                  </Link>
                </div>
              </div>
              <hr className="app_divider my-4" />
              <div className="row g-4">
                <div className="col-sm-6">
                  <div className="d-flex">
                    <EmailOutlinedIcon style={{ color: "#5153F5" }} />
                    <span className="app_text_16_bold ms-2">Email</span>
                  </div>
                  <div className="mt-1 mt-sm-3">
                    <span className="app_text">{mngProfileData.email}</span>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="d-flex">
                    <LocalPhoneOutlinedIcon style={{ color: "#5153F5" }} />
                    <span className="app_text_16_bold ms-2">Phone</span>
                  </div>
                  <div className="mt-1 mt-sm-3">
                    <span className="app_text">{mngProfileData.mobile}</span>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="d-flex">
                    <WorkOutlineOutlinedIcon style={{ color: "#5153F5" }} />
                    <span className="app_text_16_bold ms-2">Position</span>
                  </div>
                  <div className="mt-1 mt-sm-3">
                    <span className="app_text">
                      {mngProfileData.usertype === 3
                        ? "Organisation Director"
                        : mngProfileData.company_position}
                    </span>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="d-flex">
                    <UserOutlined style={{ color: "#5153F5" }} />
                    <span className="app_text_16_bold ms-2">
                      Organisation Code
                    </span>
                  </div>
                  <div className="mt-1 mt-sm-3">
                    <span className="app_text">
                      {mngProfileData.manager_code
                        ? mngProfileData.manager_code.toUpperCase()
                        : ""}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManagerProfile;
