import React, { useState, useEffect } from "react";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Link, useNavigate, useLocation } from "react-router-dom";
import url from "../../../Development.json";
import { useForm } from "react-hook-form";
import {
  errorResponse,
  successResponse,
  isError,
} from "../../Helpers/response.js";
import Http from "../../security/Http";
import { ToastContainer } from "react-toastify";
import OneSignal from "react-onesignal";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [deviceId, setDeviceId] = useState("");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState();
  const handleOnShowPassword = (ev) => {
    ev.preventDefault();
    setShowPassword(!showPassword);
  };

  const { state } = useLocation();

  useEffect(() => {
    isError(errors);
  });

  useEffect(() => {
    OneSignal.init({ appId: process.env.REACT_APP_ONESIGNAL_APP_ID }).then(
      () => {
        OneSignal.showSlidedownPrompt().then(() => {
          // do other stuff
        });
      }
    );
  }, []);

  useEffect(() => {
    OneSignal.getUserId().then((userId) => {
      setDeviceId(userId);
    });
  });

  //Employee and Manager Login
  const onSubmit = (data) => {
    data["device"] = 3;
    data["username"] = { 2: data["email"] };
    data["pushToken"] = deviceId ? deviceId : "test";
    data["interface"] = "web";
    Http.callApi(url.manager_login, JSON.stringify(data))
      .then((response) => {
        console.log(response.data);
        const data = response.data.accessToken;
        const type = response.data?.usertype;
        localStorage.setItem("usertype", type);
        if (response.data.isverified === 0) {
          setTimeout(() => {
            navigate("/account/verify", {
              state: {
                mobile: response.data.mobile,
              },
            });
          }, 2000);
        } else {
          if (type === 3) {
            localStorage.setItem("man_accessToken", data);
            setTimeout(() => {
              navigate("/manager");
            }, 500);
          } else {
            localStorage.setItem("emp_accessToken", data);
            setTimeout(() => {
              navigate("/employee");
            }, 500);
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          errorResponse(error);
        }
      });
  };

  return (
    <>
      <ToastContainer />
      <section className="login_wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <div className="login_form_container">
                {state && state.message !== null ? (
                  <div
                    className="col-lg-12 col-xl-12 mb-3"
                    style={{
                      backgroundColor: "green",
                      justifyContent: "center",
                      alignContent: "center",
                      padding: "8px",
                      borderRadius: "8px",
                    }}
                  >
                    <p
                      className="text-center text-white"
                      style={{
                        fontSize: 14,
                        paddingBottom: "0px",
                        marginBottom: "0px",
                      }}
                    >
                      {state.message}
                    </p>
                  </div>
                ) : (
                  <></>
                )}
                <div className="login_image_container d-flex d-lg-none"></div>
                <h4
                  className="app_heading d-flex d-lg-none"
                  style={{
                    textAlign: "center",
                    fontSize: 18,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Login
                </h4>

                <h4 className="app_heading d-none d-lg-block">Login</h4>
                <div className="login_form_wrapper">
                  <form
                    className="loginBox_form"
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="row g-4">
                      <div className="col-12">
                        <div className="app_textInputWrapper">
                          <input
                            type="email"
                            id="email"
                            className="app_textInput"
                            placeholder=" "
                            {...register("email", { required: true })}
                          />
                          <label
                            htmlFor="email"
                            className="appInput_Placeholder"
                          >
                            Email Address
                          </label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="app_textInputWrapper">
                          <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            className="app_textInput"
                            placeholder=" "
                            {...register("password", {
                              required: true,
                              maxLength: {
                                value: 15,
                                message: "maximum length is 15",
                              },
                              minLength: {
                                value: 6,
                                message: "minimum length is 6",
                              },
                            })}
                          />
                          <label
                            htmlFor="appLoginFormPasswordField"
                            className="appInput_Placeholder"
                          >
                            Password
                          </label>
                          <button
                            className="show_password_btn"
                            onClick={(ev) => handleOnShowPassword(ev)}
                          >
                            <VisibilityOutlinedIcon
                              style={{
                                color: showPassword ? "#000" : "#a6a9b3",
                              }}
                            />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex mt-4 justify-content-between align-items-center forgot_psw_row">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Remember me
                        </label>
                      </div>
                      <Link
                        to="/account-recovery"
                        className="app_primary_text_bold app_link"
                      >
                        Forgot Password ?
                      </Link>
                    </div>
                    <button className="app_primary_btn w-100 mt-4">
                      Login
                    </button>
                    <div
                      className="app_text_black mt-4"
                      style={{ textAlign: "center", justifyContent: "center" }}
                    >
                      <p style={{ fontSize: 14 }}>Don't have an account yet?</p>
                      <a
                        href="https://onelink.to/3m5z6a"
                        className="app_primary_text_bold app_link login_page_signup_link"
                      >
                        Download the Mind Pulse App to Register
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 d-none d-lg-flex"
              style={{ justifyContent: "center", display: "flex" }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="login_image_container"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;