import React from 'react'

const MultiChoiceQue = (props) => {
    let queTitle = props.queTitle;
    let answers = props.answers;
    return (
        <div className=''>
            <h4 className='app_text_bold mb-3'>{queTitle}</h4>
            <ul className='que_card_que_list'>
                {
                    answers.map((item, index) => <li key={index} className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id={`quickViewQueCheck${index}`} />
                        <label className="form-check-label ms-3" htmlFor={`quickViewQueCheck${index}`}>
                            {item.answerText}
                        </label>
                    </li>)
                }
            </ul>
        </div>
    )
}

export default MultiChoiceQue