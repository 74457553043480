import React from 'react'
import Lottie from 'react-lottie-player'
import brainJson from "../../assets/lottieJson/lf30_editor_rhkobnb0.json"
const AppLoader = () => {
    return (
        <div className='appLoader_wrapper'>
            <Lottie
                loop
                animationData={brainJson}
                play
                style={{ width: 180, height: 180 }}
            />
        </div>
    )
}

export default AppLoader