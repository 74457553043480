import React from 'react';
import { Navigate, Outlet } from 'react-router';

//Employee Authetication 
const AuthEmployeeVerifyRoute = ({ isAuthenticated }) => (
    isAuthenticated && localStorage.getItem('emp_accessToken') && localStorage.getItem('emp_accessToken') != '' ?
        <Outlet />
        :
        <Navigate to={'/login'} />

);

export default AuthEmployeeVerifyRoute;